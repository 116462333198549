import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
`;

export const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  width: ${({ size }) => {
    switch (size) {
      case "lg":
        return "800";
      default:
        return "480";
    }
  }}px;
  margin: 40px auto;
`;

export const ModalTitle = styled.span``;

export const ModalWrapper = styled.div<{ size: string }>`
  width: ${({ size }) => {
    switch (size) {
      case "lg":
        return "80";
      case "md":
        return "60";
      case "sm":
        return "40";
      default:
        return "60";
    }
  }}vw;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  z-index: 10;
  border-radius: 10px;
`;

export const ModalHeader = styled.div<{
  bgColor: string;
  color: string;
  borderColor: string;
}>`
  min-height: 52px;

  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 15px;
  background: ${({ bgColor }) => bgColor};
  border-radius: 5px 5px 0 0;
  color: ${({ color }) => color};
`;

export const ModalContent = styled.div<{ bgColor: string; color: string }>`
  padding: 15px;
  line-height: 1.8;

  width: 100%;
  display: grid;
  overflow-y: auto;
  box-sizing: border-box;
  height: 60vh;
  color: ${({ color }) => color}!important;
  background: ${({ bgColor }) => bgColor}!important;

  a {
    ${({ color }) => color};
  }
`;

export const ModalFooter = styled.div<{ bgColor: string; borderColor: string }>`
  height: 52px;
  background-color: #fff;
  border-top: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 0 0 5px 5px;
  background: ${({ bgColor }) => bgColor};
`;

export const CloseModalButton = styled.button<{
  bgColor: string;
  color: string;
}>`
  cursor: pointer;
  width: 120px;
  height: 32px;
  border-radius: 5px;
  color: ${({ color }) => color};
  background: ${({ bgColor }) => bgColor};
  border: none;
`;
