import React from "react";

import { useStyletron } from "baseui";

import { useAccessibility } from "@contexts/AccessibilityContext";

import { NInit } from "@namespace/Init";

import { SideBannerButton, SideBannerLink, Title } from "./styles";

interface IBannerProps {
  banner: NInit.IBanner;
}

const SideBanner = ({ banner }: IBannerProps) => {
  const { color, displayText, target, title, url, background } = banner;

  const { fontSizeMultiplier } = useAccessibility();
  const [, theme] = useStyletron();

  const {
    colors: { backgroundLightAccent, white, primary },
  } = theme;

  return (
    <SideBannerLink
      {...{
        color,
        displayText,
        target: target === 20 ? "_blank" : "_self",
        title,
        url,
        rel: "noopener noreferrer",
      }}
    >
      <SideBannerButton
        {...{
          color:
            theme.name.indexOf("contrast") > -1 ? backgroundLightAccent : white,
          bgColor:
            theme.name.indexOf("contrast") === -1 && color ? color : primary,
          url: background?.url,
        }}
      >
        {!background?.url ? (
          <Title
            {...{
              color:
                theme.name.indexOf("contrast") > -1
                  ? backgroundLightAccent
                  : white,
              fontSize: fontSizeMultiplier! * 15,
            }}
          >
            {displayText}
          </Title>
        ) : null}
      </SideBannerButton>
    </SideBannerLink>
  );
};

export default SideBanner;
