import { createTheme, lightThemePrimitives } from 'baseui';

export const primitives = {
	...lightThemePrimitives,
	primary: '#5a752F',
	primaryB: '#384A1D',
	primaryFontFamily: "'Rubik', sans-serif",
	accent: '#FFF'
};

const overrides = {
	name: 'green',
	colors: {
		contentAccent: '#005BFF',
		accent50: '#646464',
		primary50: '#759c36',
		primary100: '#0070A8',
		primary200: '#628033',
		primary300: '#0C5944',
		primary400: '#CD1040',
		accent100: '#FFF000',
		accent200: '#FFF',
		accent300: '#00ff00',
		accent400: '#000000',
		accent500: '#0000ff',
		accent600: '#ffff00',
		breadcrumbsText: '#44542A',
		buttonPrimaryHover: '#425723',
		buttonTertiaryHover: '#f2f2f2',
		contentInversePrimary: '#E2E2E2',
		backgroundInversePrimary: '#005BFF',
		backgroundPrimary: '#F8F8F8',
		backgroundAccent: '#252525',
		backgroundInverseSecondary: '#F0F0F0',
		backgroundInv: '#E1E1E1',
		backgroundSecondary: '#FFF',
		backgroundOverlayDark: '#d9fadd',
		contentTertiary: '#525252',
		backgroundLightAccent: '#FFF',
		contentInverseTertiary: '#44542A',
		borderInverseOpaque: '#646464',
		backgroundOverlayLight: '#B7B7B7',
		borderSelected: '#F3F3F3',
		calendarDayBackgroundSelected: '#5a752F',
		calendarDayForegroundSelected: '#FFF'
	}
};

export default createTheme(primitives, overrides);
