import { CustomLink } from "shared/CustomLink";

import styled, { css } from "styled-components";

const textStyles = css`
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 18px;
`;

const themeStyles = css`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
`;

//grid

export const Wrapper = styled.footer<{ backgroundcolor: string }>`
  font-family: Rubik, sans-serif;
  position: relative;
  padding: 30px;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  overflow-wrap: anywhere;
  display: flex;
  flex-wrap: wrap;
`;

export const Inner = styled.div`
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  gap: 5;
  flex-wrap: wrap;
  justify-content: center;
`;

//main

export const MainWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LogoWrapper = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
`;

export const TitlesWrapper = styled.div`
  width: 260px;
`;

export const SubTitle = styled.h6`
  ${textStyles};
  ${themeStyles};
  margin: 0 0 15px;

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const Title = styled.h4`
  letter-spacing: 1px;
  font-weight: 500;
  margin: 0 0px 60px;
  ${themeStyles};

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const ColumnTitle = styled.h5`
  ${textStyles};
  ${themeStyles};
  text-transform: uppercase;
  margin: 0 0 15px;
`;

export const CMSContainer = styled.div`
  width: 100%;
`;

export const HostingWrapper = styled.div<{ fontSize: number; accent: string }>`
  ${themeStyles};
  padding-bottom: 30px;
  margin-top: 20px;
  position: relative;

  a {
    ${themeStyles};
  }

  &:after {
    content: "";
    position: absolute;
    border-bottom: ${({ color }) => `1px solid ${color}`};
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
  }
`;

export const UEWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;
  gap: 13.33px;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 15px;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const OfficeContentWrapper = styled.p`
  line-height: 18px;
  margin: 0 0px 40px;
  letter-spacing: 0.3px;
  ${themeStyles};

  a {
    ${themeStyles};
  }
`;

//info

export const LinkWrapper = styled.div`
  font-weight: 400;
  line-height: 18px;
  margin: 0 0px 40px;,
`;

export const Link = styled(CustomLink)`
  ${themeStyles};
`;

export const StatisticContent = styled.p`
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 15px;
  ${themeStyles};
`;

// grid

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 1366px) {
    justify-content: center;
  }
`;

export const Column = styled.div`
  width: 180px;
  max-width: 180px;
  margin: 0 5px;

  a {
    word-wrap: break-word;
  }

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const ContentInner = styled.div`
  display: flex;

  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;
