import React from "react";

import { useAccessibility } from "@contexts/AccessibilityContext";
import { useStyletron } from "baseui";

import { MetricLink, Value } from "./styles";

interface MetricValueProps {
  email: string;
  value: string;
}

export const MetricValue = ({ email, value }: MetricValueProps) => {
  const { fontSizeMultiplier } = useAccessibility();

  const [, theme] = useStyletron();

  return (
    <>
      {email ? (
        <MetricLink
          {...{
            url: `mailto:${email}`,
            isDisableConfirm: true,
            fontSize: fontSizeMultiplier! * 10,
            color: theme.colors.contentTertiary,
            title: email,
          }}
        >
          {value}
        </MetricLink>
      ) : (
        <Value
          {...{
            fontSize: fontSizeMultiplier! * 10,
            color: theme.colors.contentTertiary,
          }}
        >
          {value}
        </Value>
      )}
    </>
  );
};
