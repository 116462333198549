export const attachmentStatus = {
  removed: 10,
  current: 20,
  new: 30,
};

export const failWindowHandler = (setAction, setModal, type) => {
  setAction(type);
  setModal(true);
};

export const startDownloadPDF = (setClicked, savePDF) => {
  setClicked(false);
  savePDF();
};

export const getBgColor = (name: string) => {
  const colors = {
    standard: "#000000",
    contrast1: "#333333",
    contrast2: "#333333",
    contrast3: "#333333",
    contrast4: "#333333",
    contrast5: "#0000ff",
    contrast6: "#ffff00",
  };

  return colors[name];
};

export const getThumbnailBgColor = (name: string) => {
  const colors = {
    standard: "#9e9d9d",
    contrast1: "#000",
    contrast2: "#000",
    contrast3: "#000",
    contrast4: "#000",
    contrast5: "#ffff00",
    contrast6: "#0000ff",
  };

  return colors[name];
};

export const getViedoTitleWrapperColor = (name: string) => {
  const colors = {
    standard: "#2e2c2c",
    contrast1: "#333333",
    contrast2: "#333333",
    contrast3: "#333333",
    contrast4: "#00ff00",
    contrast5: "#0000ff",
    contrast6: "#ffff00",
  };

  return colors[name];
};

export const getVideoTitleColor = (name: string) => {
  const colors = {
    standard: "#ffffff",
    contrast1: "#fff000",
    contrast2: "#ffffff",
    contrast3: "#00ff00",
    contrast4: "#000",
    contrast5: "#ffff00",
    contrast6: "#0000ff",
  };

  return colors[name];
};
