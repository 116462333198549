import React from "react";

import { Tile } from "./Tile";

import { NHome } from "@namespace/Home";

import { Container } from "./styles";

type TTilesProps = {
  tiles: NHome.ITile[];
};

export const Tiles = ({ tiles }: TTilesProps) => (
  <Container length={tiles.length}>
    {tiles.map((tile) => (
      <Tile {...{ tile, isTwoElements: tiles.length === 2 }} key={tile.id} />
    ))}
  </Container>
);
