import { createTheme, darkThemePrimitives } from 'baseui';

export const primitives = {
	...darkThemePrimitives,
	primary: '#0000ff',
	primaryB: '#0000ff',
	primaryFontFamily: "'Rubik', sans-serif",
	accent: '#FFF000'
};

const overrides = {
	name: 'contrast5',
	colors: {
		accent50: '#0000ff',
		primary50: '#FFF000',
		primary100: '#0070A8',
		primary200: '#628033',
		primary300: '#0C5944',
		primary400: '#CD1040',
		accent100: '#FFF000',
		accent200: '#FFF',
		accent300: '#00ff00',
		accent400: '#000000',
		accent500: '#0000ff',
		accent600: '#ffff00',
		breadcrumbsText: '#0000ff',
		contentAccent: '#ffff00',
		buttonPrimaryFill: '#ffff00',
		buttonPrimaryText: '#0000ff',
		buttonPrimaryHover: '#00008c',
		buttonTertiaryHover: '#fff536',
		backgroundInversePrimary: '#ffff00',
		backgroundPrimary: '#fff000',
		backgroundSecondary: '#ffff00',
		backgroundAccent: '#0000ff',
		inputFill: '#0000ff',
		borderSelected: '#0000ff',
		contentInversePrimary: '#e0e34b',
		backgroundInverseSecondary: '#ffff00',
		backgroundInv: '#0000ff',
		contentInverseSecondary: '#ffff00',
		backgroundOverlayDark: '#ffff00',
		contentTertiary: '#0000ff',
		contentInverseTertiary: '#0000ff',
		backgroundLightAccent: '#ffff00',
		borderInverseOpaque: '#0000ff',
		backgroundOverlayLight: '#fff000',
		menuFill: '#FFFFFF',
		menuFillHover: '#F6F6F6',
		calendarBackground: '#FFF000',
		calendarForeground: '#0000ff',
		calendarForegroundDisabled: '#9393bf',
		calendarHeaderBackground: '#FFF000',
		calendarHeaderForeground: '#0000ff',
		buttonDisabledFill: 'transparent',
		calendarDayForegroundSelectedHighlighted: '#FFF000',
		calendarDayBackgroundSelected: '#0000ff',
		calendarDayForegroundSelected: '#FFF000'
	}
};

export default createTheme(primitives, overrides);
