import React from "react";

import { useStyletron } from "baseui";

import { useAccessibility } from "@contexts/AccessibilityContext";

import { FormGroup, FormGroupLabel, SelectContainer, Select } from "./styles";

import { OPTIONS } from "./utils";

interface ArticleSelectProps {
  sortType: string | number | (string | number)[] | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ArticleSelect = ({
  sortType,
  handleChange,
}: ArticleSelectProps) => {
  const [, theme] = useStyletron();

  const {
    colors: { primary, backgroundSecondary },
  } = theme;

  const { fontSizeMultiplier } = useAccessibility();

  return (
    <FormGroup
      {...{
        color: primary,
        fontSize: fontSizeMultiplier! * 12,
        bgColor: backgroundSecondary,
      }}
    >
      <FormGroupLabel>sortowanie:</FormGroupLabel>
      <SelectContainer {...{ borderColor: backgroundSecondary }}>
        <Select
          {...{
            value: sortType || 0,
            onChange: handleChange,
          }}
        >
          {OPTIONS.map(({ value, name }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </Select>
      </SelectContainer>
    </FormGroup>
  );
};
