import styled from "styled-components";

export const MenuItems = styled.ul<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  align-items: flex-start;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 1080px) {
    display: ${({ isMobile }) => (!isMobile ? "none" : "flex")};
  }
`;

export const HomeButton = styled.button<{
  active: boolean;
  primary: string;
  backgroundLightAccent: string;
  buttonTertiaryHover: string;
  contentInversePrimary: string;
}>`
  padding: 14px 20px 12px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({
    active,
    backgroundLightAccent,
    buttonTertiaryHover,
  }) => (active ? buttonTertiaryHover : backgroundLightAccent)};
  border: none;
  border-bottom: ${({ active, primary, contentInversePrimary }) =>
    `3px solid ${active ? primary : contentInversePrimary}`};
  letter-spacing: 1px;

  &:hover {
    background-color: ${({ buttonTertiaryHover }) => buttonTertiaryHover};
    cursor: pointer;
  }

  &:focus: {
    background-color: ${({ primary }) => primary};
  }
`;
