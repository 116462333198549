import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useData } from "../../contexts/DataContext";
import { useTheme } from "../../contexts/ThemeContext";

import { isServer } from "../../helpers/ssr";
import { rewriteUrl } from "../../helpers/client-axios";

import ArticlePDF from "../../containers/ArticlePDF/ArticlePDF";
import Static from "../../containers/Static/Static";
import { Block } from "baseui/block";
import { StyledSpinnerNext } from "baseui/spinner";
import { BaseProvider } from "baseui";

type PProps = {
  data: any;
  error: any;
  loading: boolean;
  response: any;
  lang: string;
};

const PdfLayout: React.FC<PProps> = (props) => {
  const { theme } = useTheme();

  const { pathname, search } = useLocation();
  const { lang } = queryString.parse(search);
  const [{ data, loading }] = useData(
    {
      url: `${
        isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
      }/page-content/${
        pathname.length > 1 ? pathname.substring(1) : "strona-glowna"
      }${search}`,
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  if (
    (!data && loading) ||
    (loading && data?.contentType !== 80 && data?.contentType !== 100)
  ) {
    return (
      <Block
        overrides={{
          Block: {
            style: () => ({
              width: "100%",
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }),
          },
        }}
      >
        <StyledSpinnerNext />
      </Block>
    );
  }

  if (data?.contentData) {
    return (
      <React.Fragment>
        <Helmet defaultTitle="BIP" titleTemplate="%s - BIP">
          <html lang={lang ? lang.toString().toLocaleLowerCase() : "pl"} />

          <title>
            {data?.contentData?.metadata?.title
              ? data?.contentData?.metadata?.title
              : null}
          </title>

          <meta
            name="author"
            content={data?.contentData?.metadata?.author || ""}
          />
          <meta
            name="description"
            content={data?.contentData?.metadata?.description || "BIP"}
          />
          <meta
            name="keywords"
            content={
              data?.contentData?.metadata?.keywords
                ? Object.values(data?.contentData?.metadata?.keywords).join(",")
                : "BIP"
            }
          />
        </Helmet>
        <BaseProvider theme={theme!}>
          <ArticlePDF data={data?.contentData} />
        </BaseProvider>
      </React.Fragment>
    );
  } else return <Static data={data} />;
};

export default PdfLayout;
