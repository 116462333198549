import styled from "styled-components";

export const Title = styled.h3<{
  fontSize: number;
  borderColor: string;
}>`
  margin: 0 0 10px;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
  }
`;

export const SubTitle = styled.span``;

export const Container = styled.div`
  padding: 20px 0 10px 15px;
`;

export const ArticleSubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ color }) => color};

  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: self-start;
  }
`;
