import styled from "styled-components";

export const InputWrapper = styled.div<{
  bgColor: string;
  borderColor: string;
  isBorder: boolean;
}>`
  background: transparent;
  width: 100%;
  display: flex;
  height: 35px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgb(136 136 136 / 50%) 1.414px 1.414px 11.28px 0.72px;
  border: ${({ borderColor, isBorder }) =>
    isBorder ? `2px solid ${borderColor}` : "none"};
  fontfamily: "Rubik, sans-serif";
  box-sizing: border-box;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0 0 0 15px;
  border: none;
  background: transparent;
  color: ${({ color }) => color};
  background: ${({ bgColor }) => bgColor};

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
`;

export const ClearIconWrapper = styled.div`
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ bgColor }) => bgColor};

  svg {
    font-size: 14px;
    color: ${({ color }) => color};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SearchButton = styled.button<{
  color: string;
  fontSize: number;
  bgColor: string;
  bgColorHover: string;
}>`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  background: ${({ bgColor }) => bgColor};
  text-transform: uppercase;
  font-weight: 500;
  white-space: nowrap;
  border: none;
  box-sizing: border-box;
  border-left: ${({ color }) => `1px solid ${color}`};

  letter-spacing: 1px;
  display: flex;
  align-items: center;

  a {
    color: ${({ color }) => color};
    padding: 11.5px 5px;
    text-decoration: none;
  }

  &:hover {
    background: ${({ bgColorHover }) => bgColorHover};
    border-left: ${({ bgColor }) => `1px solid ${bgColor}`};
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const SearchIconWrapper = styled.button`
  color: ${({ color }) => color};
  padding: 14px 0;
  background: ${({ bgColor }) => bgColor};
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-left: ${({ bgColor }) => `2px solid ${bgColor}`};

  &:hover {
    cursor: pointer;
    background: ${({ bgColorHover }) => bgColorHover};
  }

  &:hover + ${SearchButton} {
    border-left: ${({ bgColor }) => `1px solid ${bgColor}`};
  }
`;
