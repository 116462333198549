export const failWindowHandler = (setAction, setModal, type) => {
  setAction(type);
  setModal(true);
};

export const startDownloadPDF = (setClicked, savePDF) => {
  setClicked(false);
  savePDF();
};

export const DAYS = {
  1: "Poniedziałek",
  2: "Wtorek",
  3: "Środa",
  4: "Czwartek",
  5: "Piątek",
};
