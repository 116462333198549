import React, { useState, useEffect, useLayoutEffect } from "react";

import { Block } from "baseui/block";
import { H3, Label4, Paragraph4, Label3, LabelMedium } from "baseui/typography";

import ReactHtmlParser from "react-html-parser";

// @ts-ignore
import { Link, useLocation } from "react-router-dom";
import { useAccessibility } from "../../contexts/AccessibilityContext";
import { useStyletron } from "baseui";
import queryString from "query-string";
import { isServer } from "../../helpers/ssr";

type AProps = {
  data: any;
};

const ArticlePDF: React.FC<AProps> = (props) => {
  const data = props.data?.item;
  const translation = props.data?.translation;

  const { search } = useLocation();
  const { lang } = queryString.parse(search);

  const [articleVersions, setArticleVersions] = useState<any[]>([]);
  const [articleVersionsFetched, setArticleVersionsFetched] = useState(false);

  const { fontSizeMultiplier } = useAccessibility();
  const [, theme] = useStyletron();

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, [true]);

  useEffect(() => {
    if (data && data?.versions) setArticleVersions(data?.versions);
  }, [data]);

  useEffect(() => {
    if (articleVersions.length && !articleVersionsFetched) {
      setArticleVersions(
        articleVersions.map((aV: any) => ({ ...aV, selected: false }))
      );
      setArticleVersionsFetched(true);
    }
  }, [articleVersions, articleVersionsFetched]);

  const deepEqual = (x: any, y: any): boolean => {
    const ok = Object.keys;
    const tx = typeof x;
    const ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x).length === ok(y).length &&
          ok(x).every((key) => deepEqual(x[key], y[key]))
      : x === y;
  };

  return (
    <Block
      overrides={{
        Block: {
          style: () => ({
            margin: "0 0 20px",
          }),
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: () => ({
              padding: "30px 50px 40px",
            }),
          },
        }}
      >
        <div id="articleContent">
          <Block
            overrides={{
              Block: {
                style: () => ({
                  borderBottom: "1px solid " + theme.colors.backgroundInv,
                }),
              },
            }}
          >
            <H3
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.primary,
                    margin: "0px 0px 10px",
                    fontSize: fontSizeMultiplier! * 16 + "px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    fontWeight: 500,
                  }),
                },
              }}
            >
              {data?.title}
            </H3>
            <Label4
              overrides={{
                Block: {
                  style: () => ({
                    color: theme.colors.accent50,
                    margin: "0px 20px 20px 0",
                    fontSize: fontSizeMultiplier! * 14 + "px",
                    lineHeight: "12px",
                    letterSpacing: "0.25px",
                    fontWeight: 400,
                    display: "inline",
                  }),
                },
              }}
            >
              {data?.publishedDate}
            </Label4>
          </Block>
          <Block
            overrides={{
              Block: {
                style: () => ({
                  padding: "20px 0 0",
                }),
              },
            }}
          >
            <Paragraph4
              as="div"
              overrides={{
                Block: {
                  style: () => ({
                    margin: "0px",
                    fontSize: fontSizeMultiplier! * 14 + "px",
                    lineHeight: "18px",
                    color: theme.colors.accent50,
                  }),
                },
              }}
            >
              {data?.content && ReactHtmlParser(data?.content)}
            </Paragraph4>
          </Block>

          {data?.attachments && data?.attachments.length > 0 && (
            <Block>
              <Label3
                overrides={{
                  Block: {
                    style: () => ({
                      marginTop: "30px",
                      color: theme.colors.accent50,
                      padding: "0px 0px 8px 0",
                      fontSize: fontSizeMultiplier! * 14 + "px",
                      lineHeight: "20px",
                      letterSpacing: "1px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      borderBottom: "1px solid " + theme.colors.backgroundInv,
                    }),
                  },
                }}
              >
                {translation?.listNames?.attachments}
              </Label3>

              {data?.attachments.map((attachment: any, index: any) => {
                return (
                  <Block
                    key={index}
                    as={(props) => (
                      <a href={attachment.url} {...props}>
                        {props.children}
                      </a>
                    )}
                    overrides={{
                      Block: {
                        props: {
                          title: attachment.title,
                        },
                        style: () => ({
                          display: "-webkit-box",
                          WebkitBoxOrient: "horizontal",
                          justifyContent: "flex-start",
                          textDecoration: "none",
                          alignItems: "center",
                          borderBottom:
                            "1px solid " + theme.colors.backgroundInv,
                          padding: "10px 0",
                        }),
                      },
                    }}
                  >
                    <img
                      src={
                        (isServer() ? "/" : "") +
                        require("../../assets/icons/attachment.svg")
                      }
                      alt="Link"
                      height="20px"
                      width="20px"
                    />
                    <LabelMedium
                      overrides={{
                        Block: {
                          style: () => ({
                            color: theme.colors.primary,
                            margin: "0px 0px 0px 20px",
                            textDecoration: "underline",
                            fontSize: fontSizeMultiplier! * 14 + "px",
                            letterSpacing: ".1px",
                            minWidth: "100px",
                            fontWeight: 400,
                          }),
                        },
                      }}
                    >
                      {attachment.displayText}
                    </LabelMedium>
                    <LabelMedium
                      overrides={{
                        Block: {
                          style: () => ({
                            color: theme.colors.accent50,
                            margin: "0px 0px 0px 60px",
                            fontSize: fontSizeMultiplier! * 14 + "px",
                            letterSpacing: ".1px",
                            fontWeight: 400,
                          }),
                        },
                      }}
                    >
                      {attachment.size}
                    </LabelMedium>
                  </Block>
                );
              })}
            </Block>
          )}

          {data?.links && data?.links.length > 0 && (
            <Block>
              <Label3
                overrides={{
                  Block: {
                    style: () => ({
                      marginTop: "30px",
                      color: theme.colors.accent50,
                      padding: "0px 0px 8px 0",
                      fontSize: fontSizeMultiplier! * 14 + "px",
                      lineHeight: "20px",
                      letterSpacing: "1px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      borderBottom: "1px solid " + theme.colors.backgroundInv,
                    }),
                  },
                }}
              >
                {translation?.listNames?.links}
              </Label3>

              {data?.links.map((link: any, index: any) => {
                return (
                  <Block
                    key={index}
                    as={(props) =>
                      link.slug ? (
                        <Link
                          to={`/${link.slug + (lang ? `?lang=${lang}` : "")}`}
                          target={link.target === 20 ? "_blank" : ""}
                          {...props}
                        >
                          {props.children}
                        </Link>
                      ) : (
                        <a
                          href={link.url}
                          target={link.target === 20 ? "_blank" : ""}
                          {...props}
                        >
                          {props.children}
                        </a>
                      )
                    }
                    overrides={{
                      Block: {
                        props: {
                          title: link.title,
                        },
                        style: () => ({
                          display: "-webkit-box",
                          WebkitBoxOrient: "horizontal",
                          justifyContent: "flex-start",
                          textDecoration: "none",
                          alignItems: "center",
                          borderBottom:
                            "1px solid " + theme.colors.backgroundInv,
                          padding: "10px 0",
                        }),
                      },
                    }}
                  >
                    <img
                      src={
                        (isServer() ? "/" : "") +
                        require("../../assets/icons/link.svg")
                      }
                      alt="Link"
                      height="20px"
                      width="20px"
                    />
                    <LabelMedium
                      overrides={{
                        Block: {
                          style: () => ({
                            color: theme.colors.primary,
                            margin: "0px 0px 0px 20px",
                            textDecoration: "underline",
                            fontSize: fontSizeMultiplier! * 14 + "px",
                            letterSpacing: ".1px",
                            minWidth: "100px",
                            fontWeight: 400,
                          }),
                        },
                      }}
                    >
                      {link.displayText}
                    </LabelMedium>
                  </Block>
                );
              })}
            </Block>
          )}

          {data?.rss && data?.rss.length > 0 && (
            <Block>
              <Label3
                overrides={{
                  Block: {
                    style: () => ({
                      marginTop: "30px",
                      color: theme.colors.accent50,
                      padding: "0px 0px 8px 0",
                      fontSize: fontSizeMultiplier! * 14 + "px",
                      lineHeight: "20px",
                      letterSpacing: "1px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      borderBottom: "1px solid " + theme.colors.backgroundInv,
                    }),
                  },
                }}
              >
                {translation?.listNames?.rss}
              </Label3>

              {data?.rss.map((rssItem: any, index: any) => {
                return (
                  <Block
                    key={index}
                    as={(props) => (
                      <a
                        href={rssItem.url + (lang ? `?lang=${lang}` : "")}
                        target={rssItem.target === 20 ? "_blank" : ""}
                        {...props}
                      >
                        {props.children}
                      </a>
                    )}
                    overrides={{
                      Block: {
                        props: {
                          title: rssItem.title,
                        },
                        style: () => ({
                          display: "-webkit-box",
                          WebkitBoxOrient: "horizontal",
                          justifyContent: "flex-start",
                          textDecoration: "none",
                          alignItems: "center",
                          borderBottom:
                            "1px solid " + theme.colors.backgroundInv,
                          padding: "10px 0",
                        }),
                      },
                    }}
                  >
                    <img
                      src={
                        (isServer() ? "/" : "") +
                        require("../../assets/icons/rssChannel.svg")
                      }
                      alt="Link"
                      height="20px"
                      width="20px"
                    />
                    <LabelMedium
                      overrides={{
                        Block: {
                          style: () => ({
                            color: theme.colors.primary,
                            margin: "0px 0px 0px 20px",
                            textDecoration: "underline",
                            fontSize: fontSizeMultiplier! * 14 + "px",
                            letterSpacing: ".1px",
                            minWidth: "100px",
                            fontWeight: 400,
                          }),
                        },
                      }}
                    >
                      {rssItem.displayText}
                    </LabelMedium>
                  </Block>
                );
              })}
            </Block>
          )}

          <div
            className="metrics-print"
            style={{ width: "100%" } as React.CSSProperties}
          >
            <div
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                padding: "10px 30px",
                border: "1px solid " + theme.colors.backgroundInv,
                color: theme.colors.accent50,
              }}
            >
              {data?.metrics.map((metric: any, index: any) => {
                return (
                  <div
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      flexWrap: "nowrap",
                      borderBottom:
                        index !== data?.metrics.length - 1
                          ? "1px solid " + theme.colors.backgroundInv
                          : "none",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        padding: "10px 0px",
                        width: "33%",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          fontSize: fontSizeMultiplier! * 14 + "px",
                          lineHeight: "12px",
                          fontWeight: 500,
                          color: theme.colors.accent50,
                          letterSpacing: ".25px",
                        }}
                      >
                        {metric.key}
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "10px 0px",
                        width: "33%",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          fontSize: fontSizeMultiplier! * 14 + "px",
                          lineHeight: "12px",
                          fontWeight: 400,
                          color: theme.colors.accent50,
                          letterSpacing: ".25px",
                        }}
                      >
                        {metric.value}
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "10px 0px",
                        width: "33%",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          fontSize: fontSizeMultiplier! * 14 + "px",
                          lineHeight: "12px",
                          fontWeight: 400,
                          letterSpacing: ".25px",
                          overflow: "hidden",
                          color: theme.colors.accent50,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {metric.date}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Block>
    </Block>
  );
};

export default ArticlePDF;
