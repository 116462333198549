import React from "react";

import ReactHtmlParser from "react-html-parser";

import {
  Column,
  ColumnTitle,
  OfficeContentWrapper,
} from "@components/Footer/styles";

export const Office = ({ title, content, fontSizeMultiplier, accent }) => (
  <Column>
    <ColumnTitle
      {...{
        fontSize: fontSizeMultiplier! * 13,
        color: accent,
      }}
    >
      {title}
    </ColumnTitle>
    {content ? (
      <OfficeContentWrapper
        {...{
          fontSize: fontSizeMultiplier! * 9,
          color: accent,
        }}
      >
        {ReactHtmlParser(content)}
      </OfficeContentWrapper>
    ) : null}
  </Column>
);
