import { CustomLink } from "shared/CustomLink";

import { MEDIA_QUERIES } from "@utils/constants";

import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Title = styled.h3<{ color: string; fontSize: number }>`
  margin: 0 0 20px;
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-weight: 500;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 18px;
  letter-spacing: 1.5px;
  text-decoration: none;
  display: block;
`;

export const Bookmarks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;

  @media (max-width: ${MEDIA_QUERIES.md}) {
    width: 100%;
  }
`;

export const BookmarkLink = styled(CustomLink)<{
  color: string;
  fontSize: number;
}>`
  color: ${({ color }) => color};
  text-decoration: underline;
  text-transform: uppercase;
  display: inline;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  letter-spacing: 0.75px;
  margin-right: 40px;
  font-weight: 500;

  &:last-child {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
