import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { isServer } from '../helpers/ssr';

type AccessibilityContextProps = {
  fontSizeMultiplier: number | null,
  decrementFontSize(): void,
  incrementFontSize(): void,
  setStandardFontSize(): void
};

const AccessibilityContext = createContext<Partial<AccessibilityContextProps>>({});

const FONT_SIZE_MULTIPLIERS = [0.8, 0.9, 1, 1.1, 1.2];
const STANDARD_FONT_SIZE_MULTIPLIER_INDEX = FONT_SIZE_MULTIPLIERS.indexOf(1);

export function AccessibilityProvider(props: any) {
  const [cookies, setCookie] = useCookies(['fontSizeMultiplierIndex']);

  const [fontSizeMultiplierIndex, setFontSizeMultiplierIndex] = useState(
    cookies.fontSizeMultiplierIndex ? parseInt(cookies.fontSizeMultiplierIndex) : STANDARD_FONT_SIZE_MULTIPLIER_INDEX
    // Try to get font size mutliplier's index from cookies, if failed then set it to the standard font size multiplier's index
  );
  const [fontSizeMultiplier, setFontSizeMultiplier] = useState(FONT_SIZE_MULTIPLIERS[fontSizeMultiplierIndex]);
  
  useEffect(() => {
    if (!isServer()) {
      setCookie('fontSizeMultiplierIndex', fontSizeMultiplierIndex.toString(), { path: '/' });
    }

    setFontSizeMultiplier(FONT_SIZE_MULTIPLIERS[fontSizeMultiplierIndex]);
  }, [fontSizeMultiplierIndex])

  function decrementFontSize() {
    if (fontSizeMultiplierIndex > 0) {
      setFontSizeMultiplierIndex(fontSizeMultiplierIndex - 1);
    }
  }

  function incrementFontSize() {
    if (fontSizeMultiplierIndex < FONT_SIZE_MULTIPLIERS.length - 1) {
      setFontSizeMultiplierIndex(fontSizeMultiplierIndex + 1);
    }
  }

  function setStandardFontSize() {
    setFontSizeMultiplierIndex(STANDARD_FONT_SIZE_MULTIPLIER_INDEX);
  }
  
  return (
    <AccessibilityContext.Provider
      value={{
        fontSizeMultiplier,
        decrementFontSize,
        incrementFontSize,
        setStandardFontSize
      }}
      {...props}
    />
  )
}

export function useAccessibility() {
  return useContext(AccessibilityContext);
}
