import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 60vw;
`;

export const MyModal = styled(Modal)`
  max-width: 60vw;
`;

export const Header = styled(ModalHeader)<{
  bgColor: string;
  color: string;
  fontSize: number;
}>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
`;

export const Body = styled(ModalBody)<{
  bgColor: string;
  color: string;
  fontSize: number;
}>`
  padding: 10px;
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  margin: 0;
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
`;

export const Footer = styled(ModalFooter)<{ bgColor: string; color: string }>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  margin: 0;
  padding: 10px;
`;

export const Button = styled(ModalButton)<{
  bgColor: string;
  color: string;
  fontSize: number;
}>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  border: none;
  font-size: ${({ fontSize }) => `${fontSize}px!important`};

  &:focus {
    background-color: ${({ bgColor }) => bgColor};
  }
`;
