import React, { useEffect, useRef, useState } from "react";

import ReactHtmlParser from "react-html-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStyletron } from "baseui";
import { useAccessibility } from "@contexts/AccessibilityContext";

import InfoModal from "@components/Modals/info-modal/index";

import { faUser } from "@fortawesome/free-solid-svg-icons";

import { Tooltip } from "@containers/Tooltip";

import * as S from "../styles";

export const ManagerCard = ({ item, index, translation }) => {
  const [, theme] = useStyletron();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [isShowButton, setIsShowButton] = useState(false);

  const {
    colors: { primary, backgroundSecondary },
  } = theme;

  const ref = useRef<HTMLElement>(null);

  const { fontSizeMultiplier } = useAccessibility();

  const handleOpenModal = (currentText) => {
    setIsOpen(true);
    setText(currentText);
  };

  useEffect(() => {
    const element = ref.current!;
    setIsShowButton(element.scrollHeight > element.clientHeight);
  }, []);

  return (
    <>
      <S.ManagerCard>
        <S.ManagerInfo bgColor={backgroundSecondary}>
          <S.ImageWrapper
            {...{
              isBorder: item.officeContactPhoto,
              color: primary,
            }}
          >
            {item.officeContactPhoto?.path ? (
              <S.Image
                {...{
                  src: item.officeContactPhoto.path,
                  alt: item.jobPosition,
                }}
              />
            ) : (
              <FontAwesomeIcon
                {...{
                  icon: faUser,
                  size: "8x",
                  style: { color: primary },
                }}
              />
            )}
          </S.ImageWrapper>
          <S.FullNameText
            {...{
              color: primary,
              fontSize: fontSizeMultiplier! * 16,
            }}
          >
            <Tooltip
              {...{
                id: `fullName-${index}`,
                text: item?.fullName,
                textColor: theme.name.includes("contrast")
                  ? backgroundSecondary
                  : "#fff",
                backgroundColor: primary,
                borderColor: theme.name.includes("contrast")
                  ? backgroundSecondary
                  : "#000",
                fontSize: fontSizeMultiplier! * 11,
              }}
            >
              {item?.fullName}
            </Tooltip>
          </S.FullNameText>
          <S.JobPositionText
            {...{
              color: primary,
              fontSize: fontSizeMultiplier! * 16,
            }}
          >
            <Tooltip
              {...{
                id: `jobPosition-${index}`,
                text: item?.jobPosition,
                textColor: theme.name.includes("contrast")
                  ? backgroundSecondary
                  : "#fff",
                backgroundColor: primary,
                borderColor: theme.name.includes("contrast")
                  ? backgroundSecondary
                  : "#000",
                fontSize: fontSizeMultiplier! * 11,
              }}
            >
              {item?.jobPosition}
            </Tooltip>
          </S.JobPositionText>
        </S.ManagerInfo>
        <S.ManagerDetails
          {...{
            color: primary,
            bgColor: backgroundSecondary,
          }}
        >
          <S.Description
            {...{
              ref,
              fontSize: fontSizeMultiplier! * 16,
            }}
          >
            {ReactHtmlParser(item?.description.replace(/<[p>]+>/g, ""))}
          </S.Description>
          {isShowButton && (
            <S.Button
              {...{
                bgColor: primary,
                color: backgroundSecondary,
                title: translation?.moreInfo?.title,
                fontSize: fontSizeMultiplier! * 11,
                onClick: () =>
                  handleOpenModal(
                    ReactHtmlParser(item?.description.replace(/<[p>]+>/g, ""))
                  ),
              }}
            >
              {translation?.moreInfo?.displayText}
            </S.Button>
          )}
        </S.ManagerDetails>
      </S.ManagerCard>
      <InfoModal
        {...{
          isOpen,
          setIsOpen,
          text,
          title: item?.fullName,
          closeButtonText: translation?.closeButton?.displayText,
          closeButtonTitle: translation?.closeButton?.title,
        }}
      />
    </>
  );
};
