import React from "react";

import { BreadcrumbItem } from "./BreadcrumbItem/BreadcrumbItem";

import { NNavigation } from "@namespace/Navigation";

import { BreadcrumbList, Wrapper } from "./styles";

interface IBreadcrumbsProps {
  breadcrumbs: NNavigation.TBreadCrumbs[];
}

export const Breadcrumbs = ({ breadcrumbs }: IBreadcrumbsProps) => (
  <Wrapper>
    <BreadcrumbList>
      {breadcrumbs.map((item, index, arr) => (
        <BreadcrumbItem
          key={index}
          {...{ item, isFirst: index === 0, isLast: index + 1 === arr.length }}
        />
      ))}
    </BreadcrumbList>
  </Wrapper>
);
