import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import { useStyletron } from "baseui";

import { NArticle } from "@namespace/Article";

import {
  getBgColor,
  getThumbnailBgColor,
  getVideoTitleColor,
  getViedoTitleWrapperColor,
} from "./utils";

import {
  MultimediaInner,
  MultimediaWrapper,
  Playlist,
  ThumbnailButton,
  Thumbnail,
  VideoTitle,
  VideoTitleWrapper,
} from "./styles";

interface IMultimediaProps {
  multimedia: NArticle.IMultimedia[];
}

export const Multimedia = ({ multimedia }: IMultimediaProps) => {
  const [url, setUrl] = useState("");
  const [, theme] = useStyletron();

  useEffect(() => {
    if (multimedia.length) {
      setUrl(multimedia[0].url);
    }
  }, []);

  return (
    <MultimediaWrapper
      {...{
        id: "multimediaWrapper",
        bgColor: getBgColor(theme.name),
      }}
    >
      <MultimediaInner>
        <ReactPlayer
          {...{
            url,
            width: "100%",
            height: "100%",
            controls: true,
          }}
        />
      </MultimediaInner>
      <Playlist>
        {multimedia
          .sort((a, b) => a.type - b.type || a.order - b.order)
          .map(({ displayText, url, thumbnailPath }, index) => (
            <Thumbnail>
              <ThumbnailButton
                key={index}
                {...{
                  thumbnailPath,
                  bgColor: getThumbnailBgColor(theme.name),
                  onClick: () => setUrl(url),
                }}
              >
                <VideoTitleWrapper
                  bgColor={getViedoTitleWrapperColor(theme.name)}
                >
                  <VideoTitle color={getVideoTitleColor(theme.name)}>
                    {displayText}
                  </VideoTitle>
                </VideoTitleWrapper>
              </ThumbnailButton>
            </Thumbnail>
          ))}
      </Playlist>
    </MultimediaWrapper>
  );
};
