import React from 'react';
import { useStyletron } from 'baseui';

import { NAppLayout } from '@namespace/AppLayout';

import { getBorderAndColor, getBackground } from '../utils';

export const SkipLink: React.SFC<NAppLayout.ISkipLink> = ({ label, onClick, theme }) => {
	const [ css ] = useStyletron();

	return (
		<button
			{...{ onClick }}
			className={css({
				left: '-999px',
				position: 'absolute',
				zIndex: -999,
				':focus': {
					color: getBorderAndColor(theme),
					backgroundColor: getBackground(theme),
					left: 'auto',
					top: 'auto',
					width: '30%',
					height: 'auto',
					overflow: 'auto',
					margin: '10px 35%',
					padding: '5px',
					borderRadius: '15px',
					border: `4px solid ${getBorderAndColor(theme)}`,
					textAlign: 'center',
					fontSize: '1.2em',
					zIndex: 999
				}
			})}
		>
			{label}
		</button>
	);
};
