import styled from "styled-components";

import { StyledSpinnerNext } from "baseui/spinner";
import { MEDIA_QUERIES } from "@utils/constants";

export const VideoTitle = styled.span<{ color: string }>`
  width: 80%;
  color: ${({ color }) => color};
  word-break: break-word;
`;

export const VideoTitleWrapper = styled.div`
  display: block;
  position: absolute;
  width: 80%;
  font-weight: 400;
  text-align: center;
  bottom: 15px;
  background: ${({ bgColor }) => bgColor};
`;

export const Title = styled.h3`
  color: ${({ color }) => color};
  margin: 0 0 10px;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 18px;
  letter-spacing: 1px;
  font-weight: 500;
`;

export const DateWrapper = styled.div`
  color: ${({ color }) => color};
  margin: 0 20px 20px 0;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 12px;
  letter-spacing: 0.25px;
  display: inline-block;
`;

export const Spinner = styled(StyledSpinnerNext)`
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
`;

export const Wrapper = styled.div<{ bgColor: string; isLoading: boolean }>`
  background-color: ${({ bgColor }) => bgColor};
  margin: 0 0 20px;
  border-radius: 5px;
  box-shadow: 1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2);
  opacity: ${({ isLoading }) => (isLoading ? 0.3 : 1)};
`;

export const Inner = styled.div`
  padding: 30px 50px 40px;

  @media (max-width: ${MEDIA_QUERIES.md}) {
    padding: 10px 20px 13px;
  }
`;

export const ContentWrapper = styled.div<{ fontSize: number; color: string }>`
  margin: 0;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  line-height: 18px;
`;

export const ArticleButton = styled.button<{
  fontSize: number;
  color: string;
  hoverColor: string;
}>`
  margin-right: 35px;
  padding: 15px 0;
  text-transform: uppercase;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  font-weight: 400;
  line-height: 11px;
  background-color: transparent;
  border: none;
  display: flex;
  gap: 12px;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: ${({ hoverColor }) => hoverColor};
  }
`;
