import React, { useEffect, useCallback, ReactElement } from "react";

import ReactHtmlParser from "react-html-parser";
import { useTheme } from "@contexts/ThemeContext";
import { useSpring, animated } from "react-spring";

import { createPortal } from "react-dom";

import { MODAL_COLORS } from "./utils";

import * as S from "./styles";

type TCustomModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  content: string;
  title?: string;
  size?: "sm" | "md" | "lg";
};

export const CustomModal = ({
  isOpen,
  content,
  title,
  size = "md",
  setIsOpen,
}: TCustomModalProps): ReactElement => {
  const { theme } = useTheme();

  const animation = useSpring({
    config: {
      duration: 500,
    },

    transform: isOpen ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    },
    [setIsOpen, isOpen]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {isOpen
        ? createPortal(
            <S.Background onClick={() => setIsOpen(false)}>
              <animated.div style={animation}>
                <S.ModalWrapper
                  {...{
                    size,
                    "data-keyboard": "false",
                    "data-backdrop": "static",
                  }}
                >
                  <S.ModalHeader
                    {...{
                      bgColor: theme?.name
                        ? MODAL_COLORS["bgColor"][theme.name]
                        : "#fff",
                      color: theme?.name
                        ? MODAL_COLORS["text"][theme.name]
                        : "#000",
                      borderColor: theme?.name
                        ? MODAL_COLORS["border"][theme.name]
                        : "#fff",
                    }}
                  >
                    <S.ModalTitle>{title}</S.ModalTitle>
                    <S.CloseModalButton
                      aria-label="Close modal"
                      {...{
                        onClick: () => setIsOpen(false),
                        bgColor: theme?.name
                          ? MODAL_COLORS["buttonBacground"][theme.name]
                          : "#000",
                        color: theme?.name
                          ? MODAL_COLORS["buttonText"][theme.name]
                          : "#fff",
                      }}
                    >
                      Zamknij
                    </S.CloseModalButton>
                  </S.ModalHeader>
                  <S.ModalContent
                    {...{
                      bgColor: theme?.name
                        ? MODAL_COLORS["bgColor"][theme.name]
                        : "#fff",
                      color: theme?.name
                        ? MODAL_COLORS["content"][theme.name]
                        : "#000",
                    }}
                  >
                    {ReactHtmlParser(content)}
                  </S.ModalContent>
                  <S.ModalFooter
                    {...{
                      bgColor: theme?.name
                        ? MODAL_COLORS["bgColor"][theme.name]
                        : "#fff",
                      borderColor: theme?.name
                        ? MODAL_COLORS["border"][theme?.name]
                        : "#fff",
                    }}
                  ></S.ModalFooter>
                </S.ModalWrapper>
              </animated.div>
            </S.Background>,
            document.body
          )
        : null}
    </>
  );
};
