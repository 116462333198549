import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./contexts/DataContext";

import { Provider as StyletronProvider } from "styletron-react";
import { Client as StyletronEngine } from "styletron-engine-atomic";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";

import LRU from "lru-cache";

const cache = new LRU<string, string>();

if (!process.env.NODE_ENV || process.env.NODE_ENV === "production") {
  // @ts-ignore
  const engine = new StyletronEngine({
    hydrate: document.getElementsByClassName("_styletron_hydrate_") as any,
  });

  // @ts-ignore
  cache.load(window.__DATA_CONTEXT_VALUE__);

  ReactDOM.hydrate(
    <BrowserRouter>
      <HelmetProvider>
        <DataProvider cache={cache}>
          <StyletronProvider value={engine}>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </StyletronProvider>
        </DataProvider>
      </HelmetProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );

  // @ts-ignore
  delete window.__DATA_CONTEXT_VALUE__;
} else {
  const engine = new StyletronEngine();
  ReactDOM.render(
    <BrowserRouter>
      <HelmetProvider>
        <DataProvider cache={cache}>
          <StyletronProvider value={engine}>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </StyletronProvider>
        </DataProvider>
      </HelmetProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
