import React from "react";
import { useLocation } from "react-router-dom";

import { useStyletron } from "baseui";
import queryString from "query-string";

import { Tooltip } from "@containers/Tooltip";

import { useAccessibility } from "@contexts/AccessibilityContext";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { processSlug } from "@utils/process-slug";

import {
  FullName,
  JobPosition,
  Wrapper,
  Inner,
  ImageWrapper,
  JobPositionWrapper,
  FullNameWrapper,
  MoreInfoLink,
} from "./styles";

export const UserInfo = ({ officeManager }) => {
  const [, theme] = useStyletron();
  const { search } = useLocation();

  const { displayText, slug, target, title, url } = officeManager?.moreInfo;

  const { lang } = queryString.parse(search);
  const { fontSizeMultiplier } = useAccessibility();

  const {
    colors: { backgroundSecondary, primary, primaryB },
  } = theme;

  const tooltipTextColor = theme.name.includes("contrast")
    ? backgroundSecondary
    : "#fff";

  const tooltipBorderColor = theme.name.includes("contrast")
    ? backgroundSecondary
    : "#000";

  return (
    <Wrapper bgColor={backgroundSecondary}>
      <Inner>
        <ImageWrapper
          {...{
            isOfficeManager: !!officeManager?.photoPath,
            borderColor: primaryB,
          }}
        >
          {officeManager?.photoPath ? (
            <img
              src={officeManager?.photoPath}
              alt={officeManager?.fullName}
              width={230}
              height={230}
            />
          ) : (
            <FontAwesomeIcon icon={faUser} size="10x" color={primaryB} />
          )}
        </ImageWrapper>
        <FullNameWrapper>
          <FullName color={primaryB}>
            <Tooltip
              {...{
                id: "officeManager-fullName",
                text: officeManager?.fullName,
                textColor: tooltipTextColor,
                backgroundColor: primary,
                borderColor: tooltipBorderColor,
              }}
            >
              {officeManager?.fullName}
            </Tooltip>
          </FullName>
          <MoreInfoLink
            {...{
              slug: processSlug(slug, lang),
              title,
              target: target === 20 ? "_blank" : "",
              color: primary,
              fontSize: fontSizeMultiplier! * 11,
              url,
            }}
          >
            {displayText}
          </MoreInfoLink>
        </FullNameWrapper>
        <JobPositionWrapper>
          <JobPosition color={primaryB}>
            <Tooltip
              {...{
                id: "officeManager-jobPosition",
                text: officeManager?.jobPosition,
                textColor: tooltipTextColor,
                backgroundColor: primary,
                borderColor: tooltipBorderColor,
              }}
            >
              {officeManager?.jobPosition}
            </Tooltip>
          </JobPosition>
        </JobPositionWrapper>
      </Inner>
    </Wrapper>
  );
};
