export const calculate = (headers: {}, size: any) =>
  Math.ceil(headers["x-total-count"] / (size as number));

export const numberPages = (headers: {}, size: any) =>
  calculate(headers, size) ? calculate(headers, size) : 1;

export const getPageItemContrast1 = (name: string) => {
  const colors = {
    standard: "#ffffff",
    contrast1: "#FFF000",
    contrast2: "#000000",
    contrast3: "#00ff00",
    contrast4: "#333333",
    contrast5: "#ffff00",
    contrast6: "#0000ff",
  };

  return colors[name];
};

export const getPageItemContrast2 = (name: string) => {
  const colors = {
    standard: "#000000",
    contrast1: "#000000",
    contrast2: "#ffffff",
    contrast3: "#000000",
    contrast4: "#00ff00",
    contrast5: "#0000ff",
    contrast6: "#ffff00",
  };

  return colors[name];
};
