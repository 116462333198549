import React, { createContext, useState } from "react";

interface ScrollPositionContextProps {
  scrollPosition: number;
  articleId: number;
  scrollLinkPosition: number;
  articleLinkId: string;
  changeScrollPosition: (
    newPosition: number,
    name: "article" | "articleLink"
  ) => void;
  changeArticleId: (
    id: number | string,
    name: "article" | "articleLink"
  ) => void;
}

export const ScrollPositionContext = createContext<ScrollPositionContextProps>({
  scrollPosition: 0,
  articleId: 0,
  scrollLinkPosition: 0,
  articleLinkId: "",
  changeScrollPosition: () => {},
  changeArticleId: () => {},
});

export const ScrollPositionProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [articleId, setArticleId] = useState(0);

  const [scrollLinkPosition, setScrollLinkPosition] = useState(0);
  const [articleLinkId, setArticleLinkId] = useState("");

  const changeScrollPosition = (
    newPosition: number,
    name: "article" | "articleLink"
  ) => {
    if (name === "article") {
      setScrollPosition(newPosition);
    } else {
      setScrollLinkPosition(newPosition);
    }
  };

  const changeArticleId = (
    id: number | string,
    name: "article" | "articleLink"
  ) => {
    if (name === "article" && typeof id === "number") {
      setArticleId(id);
    }

    if (typeof id === "string") {
      setArticleLinkId(id);
    }
  };

  return (
    <ScrollPositionContext.Provider
      value={{
        scrollPosition,
        articleId,
        scrollLinkPosition,
        articleLinkId,
        changeScrollPosition,
        changeArticleId,
      }}
    >
      {children}
    </ScrollPositionContext.Provider>
  );
};
