const MIN_ADDRESS_PARTS = 3;
export function rewriteUrl(location: any): string {
  if (!location) {
    return `${process.env.REACT_APP_API_URL}`;
  }

  if (location.hostname === 'localhost') {
    return `${process.env.REACT_APP_API_URL}`;
  }

  if (location.hostname.indexOf('localhost') !== -1) {
    return `${location.protocol}//${location.hostname}:${process.env.REACT_APP_SERVER_API_PORT}${process.env.REACT_APP_SERVER_API_URL}`
  }

  let hostname = '';
  if (location.hostname && location.hostname.length > 0 && location.hostname.split('.').length >= MIN_ADDRESS_PARTS) {
    const parts = location.hostname.split('.');
    parts[0] += '-api';

    hostname = parts.join('.');
  }

  const url = `${location.protocol}//${hostname}:${process.env.REACT_APP_SERVER_API_PORT}${process.env.REACT_APP_SERVER_API_URL}`;

  return url;
}

export function serverRewriteHostname(hostname: string): string {
  if (!hostname) {
    return '';
  }

  if (hostname && hostname.length > 0 && hostname.split('.').length >= MIN_ADDRESS_PARTS) {
    const parts = hostname.split('.');
    parts[0] += '-api';

    hostname = parts.join('.');
  }

  return hostname;
}