import React, { createContext, useContext, useRef, RefObject } from "react";
import { Options, makeUseAxios } from "axios-hooks";
import Axios, { AxiosRequestConfig } from "axios";

import { getCacheKey } from "../helpers/axios";
import { isServer } from "../helpers/ssr";

type DataContextProps = {
  cache: any;
  requestsToPrefetch?: AxiosRequestConfig[];
  initialCallsRef?: RefObject<string[]>;
};

const DataContext = createContext<Partial<DataContextProps>>({});

export function DataProvider({ cache, requestsToPrefetch, ...restProps }: any) {
  const initialCallsRef = useRef([]);

  return (
    <DataContext.Provider
      value={{
        requestsToPrefetch,
        cache,
        initialCallsRef,
      }}
      {...restProps}
    />
  );
}

export function useData(config: AxiosRequestConfig, options?: Options): any {
  const { requestsToPrefetch, cache, initialCallsRef } =
    useContext(DataContext);
  // Hook is used server-side
  if (isServer()) {
    config = {
      ...config,
      cancelToken: Axios.CancelToken.source().token,
    };

    const cacheKey = getCacheKey(config);

    if (cache.has(cacheKey)) {
      const response = cache.get(cacheKey);

      if (response.error) {
        cache.del(cacheKey);
      }

      return [
        {
          loading: false,
          data: response.data,
          error: response.error,
          response,
        },
      ];
    } else {
      requestsToPrefetch && requestsToPrefetch.push(config);

      return [{ loading: true }];
    }
  }

  let { useCache, ...restOptions } = options || {};

  if (
    initialCallsRef?.current &&
    !initialCallsRef?.current?.includes(getCacheKey(config))
  ) {
    // If the call is initial force using cache to fetch response
    useCache = true;

    initialCallsRef.current.push(getCacheKey(config));
  }

  return makeUseAxios({ cache })(config, {
    useCache,
    ...restOptions,
  });
}
