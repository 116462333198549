import React from "react";

import { useLocation } from "react-router-dom";

import { useStyletron } from "baseui";

import queryString from "query-string";

import { useAccessibility } from "@contexts/AccessibilityContext";

import { Tooltip } from "@containers/Tooltip";

import { processSlug } from "@utils/process-slug";

import {
  Content,
  DisplayText,
  Inner,
  MoreInfoLink,
  Title,
  Value,
  ValueLink,
  Wrapper,
} from "./styles";

interface InformationProps {
  information?: any;
}

export const Information = ({ information }: InformationProps) => {
  const [, theme] = useStyletron();
  const { search } = useLocation();

  const { lang } = queryString.parse(search);

  const { fontSizeMultiplier } = useAccessibility();

  const {
    colors: { backgroundSecondary, primary, primaryB },
  } = theme;

  const { displayText, slug, url, target, title } = information?.moreInfo;

  return (
    <Wrapper color={primaryB}>
      <Inner background={backgroundSecondary}>
        <div>
          <Title fontSize={fontSizeMultiplier! * 18}>
            {information?.title}
          </Title>
          {(information?.content || []).map(
            ({ type, title, value, displayText }) => (
              <Content type={type} key={type}>
                <DisplayText
                  {...{
                    fontSize: fontSizeMultiplier! * 12,
                    isDisplayText: !!displayText,
                  }}
                >
                  {displayText}
                </DisplayText>
                <Value fontSize={fontSizeMultiplier! * 11}>
                  {type === 60 || type === 90 ? (
                    <ValueLink
                      {...{
                        color: primary,
                        url: type === 60 ? `mailto:${value}` : value,
                        title: value,
                        isDisableConfirm: type === 60,
                      }}
                    >
                      {value}
                    </ValueLink>
                  ) : (
                    <Tooltip
                      {...{
                        id: `${title || "default"}-${type}`,
                        text: value,
                        textColor: theme.name.includes("contrast")
                          ? theme.colors.backgroundSecondary
                          : "#fff",
                        backgroundColor: theme.colors.primary,
                        borderColor: theme.name.includes("contrast")
                          ? theme.colors.backgroundSecondary
                          : "#000",
                      }}
                    >
                      {value}
                    </Tooltip>
                  )}
                </Value>
              </Content>
            )
          )}
        </div>
        {information?.moreInfo ? (
          <MoreInfoLink
            {...{
              slug: processSlug(slug, lang),
              target: target === 20 ? "_blank" : "",
              title,
              url,
              fontSize: fontSizeMultiplier! * 11,
              color: primary,
            }}
          >
            {displayText}
          </MoreInfoLink>
        ) : null}
      </Inner>
    </Wrapper>
  );
};
