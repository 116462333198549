import styled, { css } from "styled-components";

import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { LabelSmall } from "baseui/typography";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

export const PageItemWrapper = styled(Block)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const PageStyles = css`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 12px;
  border-radius: 3px;
  padding: 5px 2px;
  width: 26px;
  text-align: center;
  border: ${({ color }) => `1px solid ${color}`};
`;

export const PageItem = styled(Button)<{
  fontSize: number;
  color: string;
  bgColor: string;
}>`
  margin: 0 2px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  ${PageStyles}

  &:hover {
    color: ${({ bgColor }) => bgColor};
    background-color: ${({ color }) => {
      return color;
    }};
  }

  @media screen and (max-width: 480px) {
    margin: 1px;
  }
`;

export const PageSizeButton = styled(Button)<{
  fontSize: number;
  color: string;
  bgColor: string;
}>`
  ${PageStyles}
  margin-left: 5px;

  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};

  &:hover {
    color: ${({ bgColor }) => bgColor};
    background-color: ${({ color }) => color};
  }

  @media screen and (max-width: 480px) {
    margin-left: 2px;
  }
`;

export const PageSizeWrapper = styled(Block)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LabelStyle = css`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 10px;
`;

export const Label = styled(LabelSmall)<{ color: string; fontSize: number }>`
  ${LabelStyle}
`;

export const PageSizeLabel = styled(LabelSmall)<{
  color: string;
  fontSize: number;
}>`
  ${LabelStyle}
`;

export const ArrowWrapperLeft = styled(Block)`
  margin-left: 4px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    margin: 0px;
  }
`;

export const ArrowWrapperRight = styled(Block)`
  margin-right: 4px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    margin: 0px;
  }
`;

export const PageSizeButtonWrapper = styled.div``;
