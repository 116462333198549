import React, { ReactNode } from "react";

import { ArticleSubTitleWrapper, Container, SubTitle } from "./styles";

interface ListProps {
  title: string;
  pagination: ReactNode;
  select: ReactNode;
  items: ReactNode;
}

export const List = ({ pagination, select, title, items }: ListProps) => (
  <>
    <Container>
      <ArticleSubTitleWrapper>
        <SubTitle>{title}</SubTitle>
        {select}
      </ArticleSubTitleWrapper>
      {items}
    </Container>
    {pagination}
  </>
);
