import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "styled-components";

export const TopButton = styled.button`
  position: fixed;
  bottom: 80px;
  right: 0;
  min-width: 150px;
  min-height: 40px;
  padding: 1rem;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor}!important;
  border: ${({ textColor }) => `1px solid ${textColor}`};
  box-shadow: 4px 4px 13.5px rgba(37, 37, 37, 0.4);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize};
  gap: 10px;
  margin-right: 40px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
    padding: 0.5rem;
    min-width: 120px;
    margin-right: 20px;
    bottom: 20px;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;
