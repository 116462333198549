import styled, { css } from "styled-components";

export const ManagerDetails = styled.div<{
  bgColor: string;
  color: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  padding: 20px;
  box-sizing: border-box;
  height: 330px;
  color: ${({ color }) => color};
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

  @media screen and (max-width: 1366px) {
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;

export const Description = styled.div<{ fontSize: number }>`
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-break: after-white-space;
  text-overflow: ellipsis;
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
`;

const TextStyles = css`
  font-weight: 500;
  width: 70%;
  text-align: center;
`;

export const JobPositionText = styled.span<{ color: string; fontSize: number }>`
  color: ${({ color }) => color};
  ${TextStyles};
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
`;

export const FullNameText = styled.span<{ color: string; fontSize: number }>`
  color: ${({ color }) => color};
  margin: 10px 0;
  ${TextStyles};
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
`;

export const ManagersWrapper = styled.div`
  width: 100%;
`;

export const ManagerCard = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  box-shadow: rgb(136 136 136 / 20%) 1.414px 1.414px 11.28px 0.72px;
`;

export const ManagerInfo = styled.div<{
  bgColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 330px;
  flex-direction: column;
  box-sizing: border-box;
  width: 50%;

  @media screen and (max-width: 1366px) {
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div<{
  isBorder: boolean;
  color: string;
}>`
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ color }) => color};
  border: ${({ isBorder, color }) =>
    isBorder ? "none" : `1px solid ${color}`};
  width: 230px;
  height: 230px;
`;

export const Image = styled.img`
  width: 230px;
  height: 230px;
`;

export const Button = styled.button<{ fontSize: number }>`
  margin-top: 20px;
  width: 50%;
  margin: 20px auto 0 auto;
  height: 30px;
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 2px;
  border: none;
  cursor: pointer;
`;
