import * as React from "react";

const SvgComponent = (props) => {
  const { tileBgColor, primary, backgroundSecondary } = props;

  return (
    <svg
      id="e8ab07fb-aa66-4eb8-9149-b5abc1630568"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 799.7 607.2"
      style={{
        enableBackground: "new 0 0 799.7 607.2",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <title id="title">404 Strona nie istnieje</title>
      <style>
        {`.st0{fill:${tileBgColor}}.st1{fill:${backgroundSecondary}}.st2{fill:${primary}}.st3{fill:#2f2e41}.st4{fill:#ffb8b8}.st5{fill:#3f3d56}`}
      </style>
      <path className="st0" d="M.3.4h643.9v412.4H.3z" />
      <path className="st1" d="M18.7 52.1h607v336.2h-607z" />
      <path className="st2" d="M0 0h643.9v27.4H0z" />
      <circle className="st1" cx={20.3} cy={14} r={5.1} />
      <circle className="st1" cx={39.6} cy={14} r={5.1} />
      <circle className="st1" cx={58.8} cy={14} r={5.1} />
      <path className="st0" d="M73.8 87h156v266.5h-156z" />
      <path className="st2" d="M256.7 87h130v73.3h-130z" />
      <path
        className="st0"
        d="M256.7 180.7h130v78.9h-130zM256.7 280.1h130v73.3h-130zM414.6 87h156v116.1h-156z"
      />
      <path className="st2" d="M414.6 237.3h156v116.1h-156z" />
      <path
        className="st3"
        d="M736.6 206.7c-10.3-17.5-30.6-18.4-30.6-18.4s-19.8-2.6-32.6 24.1c-11.9 24.9-28.2 48.9-2.6 54.8l4.6-14.5 2.9 15.6c3.6.3 7.3.3 11 .2 27.4-.9 53.5.3 52.7-9.7-1.2-13.1 4.5-35.2-5.4-52.1z"
      />
      <path
        className="st4"
        d="m639.1 300.3-9 23-45 42s-27 20-15 26 23-22 23-22l48-29 10-33-12-7zM654.1 555.3l9 23h13l-11-27zM693.1 565.3l9 23h13l-11-27z"
      />
      <path
        className="st3"
        d="m692.1 569.3 17-4-22-89 7.3-68.5h.2s12.5-48.5 2.5-61.5-60-8-60-8-19 19-18 62 0 80 7 97 27 62 27 62l16-2-17-74-2.7-64.3c2.2 25.8 6.5 74.7 8.7 78.3 3 5 34 72 34 72z"
      />
      <path
        className="st4"
        d="m743.1 321.3 7 25 31 56s27 24 16 27-28-22-28-22l-35-49-9-36 18-1z"
      />
      <circle className="st4" cx={707.1} cy={225.3} r={25} />
      <path
        className="st4"
        d="m694.1 238.3-6 19-10 22 37-15s-2-16 0-20l-21-6z"
      />
      <path
        className="st2"
        d="m737.1 278.3-23-19-26 11 1-16.2-27.7 8.9-4.3 1.4-6 31s-4 3-8 20c-2.3 10-5.7 19.7-10 29 0 0 43-17 64 6 0 0 3-2 2-5s9-18 9-18 0 3 4-3 7-7 7-7l18-39.1z"
      />
      <path
        className="st2"
        d="m663.1 267.3-4.4-3.5s-3.6-1.5-6.6 3.5-18 36-18 36l18 9 11-45zM723.1 277.3l14 1s8 4 8 10 2 38 2 38l-22 7-9-28 7-28z"
      />
      <path
        className="st3"
        d="m660.2 574.8-17 7s-20 1-17 11 33 3 33 3 27 1 27-6-4-15-8-15h-18z"
      />
      <path
        className="st3"
        d="m698.2 583.8-17 7s-20 1-17 11 33 3 33 3 27 1 27-6-4-15-8-15h-18zM732.1 205 712 194.4l-27.8 4.3-5.7 25.6 14.3-.5 4-9.5v9.3l6.6-.3 3.8-14.9 2.4 15.9 23.4-.5z"
      />
      <path
        className="st5"
        d="M597.6 83.5c-57-48.1-142.2-40.9-190.3 16.1s-40.9 142.2 16.1 190.3c53.3 45 132.1 42 181.8-7l165.6 139.7c5.2 4.4 13.1 3.8 17.5-1.5 4.4-5.2 3.8-13.1-1.5-17.5L621.3 263.9c39.9-57.2 29.6-135.4-23.7-180.4zm-10.7 167c-36 42.7-99.8 48.1-142.5 12.1s-48.1-99.8-12.1-142.5S532.1 72 574.8 108s48.1 99.8 12.1 142.5z"
      />
      <path
        className="st5"
        d="M444.4 262.6c-40.2-34-47.7-93.1-17.2-136-1.9 1.9-3.8 4-5.6 6.1-36 42.7-30.6 106.5 12.1 142.5s106.5 30.6 142.5-12.1c1.8-2.1 3.5-4.3 5.1-6.6-37.1 37.4-96.7 40-136.9 6.1z"
        style={{
          opacity: 0.3,
        }}
      />
    </svg>
  );
};

export default SvgComponent;
