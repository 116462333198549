import styled from "styled-components";

import { MEDIA_QUERIES } from "@utils/constants";
import { Button } from "baseui/button";

export const MultimediaWrapper = styled.div<{ bgColor: string }>`
  display: flex;
  gap: 15px;
  margin-top: 15px;
  padding: 15px;
  background: ${({ bgColor }) => bgColor};
  flex-direction: row;
  height: 420px;

  @media (max-width: ${MEDIA_QUERIES.md}) {
    flex-direction: column-reverse;

    height: 520px;
  }
`;

export const MultimediaInner = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: rgb(136 136 136 / 20%) 1.414px 1.414px 11.28px 0.72px;
  width: 100%;
  height: 100%;
`;

export const Playlist = styled.div`
  width: 50%;
  height: 100%;
  overflow-y: auto;
  cursor: pointer;

  @media (max-width: ${MEDIA_QUERIES.md}) {
    width: 100%;
  }
`;

export const Thumbnail = styled.div`
  height: 130px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${MEDIA_QUERIES.md}) {
    height: 100%;
  }
`;

export const ThumbnailButton = styled.button<{
  bgColor: string;
  thumbnailPath: string;
}>`
  display: flex;
  flexdirection: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ bgColor }) => bgColor};
  background-image: ${({ thumbnailPath }) => `url(${thumbnailPath})`};
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  justify-content: center;
`;

export const VideoTitleWrapper = styled.div`
  display: block;
  position: absolute;
  width: 80%;
  font-weight: 400;
  text-align: center;
  bottom: 15px;
  background: ${({ bgColor }) => bgColor};
`;

export const VideoTitle = styled.span<{ color: string }>`
  width: 80%;
  color: ${({ color }) => color};
  word-break: break-word;
`;
