export const minLengthValidator = (value?: string, errorMessage?: string) =>
  (value && value.length < 3) || value === undefined || value === ""
    ? errorMessage
    : undefined;

export const calculate = (headers: {}, size: any) =>
  Math.ceil(headers["x-total-count"] / (size as number));

export const numberPages = (headers: {}, size: any) =>
  calculate(headers, size) ? calculate(headers, size) : 1;

export const SOURCE_OPTION_CURRENT_BIP_TEXT_VALUE = "10";
export const SOURCE_OPTION_ARCHIVE_BIP_TEXT_VALUE = "20";

export const SEARCH_INTO_OPTION_TITLE_AND_CONTENT_ATTACHMENT_VALUE = "0";
export const SEARCH_INTO_OPTION_NAME_AND_CONTENT_ATTACHMENT_VALUE = "1";

export const sortByType = {
  descending: "0",
  ascending: "1",
};
