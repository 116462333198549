import React, { useEffect, useState } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { BaseProvider, useStyletron } from "baseui";

import { Grid, Cell } from "baseui/layout-grid";
import { StyledSpinnerNext } from "baseui/spinner";
import { Block } from "baseui/block";

import TopBar from "../TopBar/TopBar";
import Header from "./../Header/Header";
import Footer from "../Footer/Footer";
import SideBanner from "../SideBanner/SideBanner";
import Page from "@containers/Page/Page";
import Page404 from "@containers/Page404/Page404";

import { Breadcrumbs } from "@containers/Breadcrumbs/Breadcrumbs";
import { VerticalMenu } from "@components/Header/VerticalMenu";
import { ScrollButton } from "@containers/ScrollButton";

import { useTheme } from "@contexts/ThemeContext";
import { useData } from "@contexts/DataContext";
import { isServer } from "@helpers/ssr";
import { rewriteUrl } from "@helpers/client-axios";
import { THEME_SET } from "@themes/index";

import { Cookie } from "@containers/Cookie";

import { SkipLink } from "./Skip-link";

import { focusedOnElement, SKIP_LINKS_ARRAY } from "./utils";

import { Wrapper, NavigationWrapper, PageWrapper } from "./styles";

const AppLayout: React.FC = () => {
  const { theme, themeKey, setThemeKey } = useTheme();
  const history = useHistory();
  const [css] = useStyletron();
  const [windowWidth, setWindowWidth] = useState(0);
  const [skipLinks, setSkipLinks] = useState(SKIP_LINKS_ARRAY);

  const { pathname, search } = useLocation();
  const { parse, stringify } = queryString;
  const { lang } = parse(search);

  useEffect(() => {
    const currentLinks = SKIP_LINKS_ARRAY.filter((items) =>
      windowWidth <= 1080 ? items.isMobile : items.isDesktop
    );

    setSkipLinks(currentLinks);
  }, [windowWidth]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleFocus = (id) => {
    focusedOnElement(id);
  };

  const [{ data, loading, error }] = useData(
    {
      url: `${
        isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
      }/apps/init-data${lang ? `?lang=${lang}` : ""}`,
      method: "GET",
    },
    {
      useCache: true,
    }
  );

  const [{ data: pageData, error: pageError, loading: pageLoading, response }] =
    useData(
      {
        url: `${
          isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
        }/page-content/${
          pathname.length > 1 ? pathname.substring(1) : "strona-glowna"
        }${search}`,
        method: "GET",
      },
      {
        useCache: false,
      }
    );

  useEffect(() => {
    if (!isServer()) {
      const layoutThemeKey = data?.layout?.themeKey || "";
      if (Object.keys(THEME_SET).includes(layoutThemeKey)) {
        setThemeKey!(layoutThemeKey);
      } else {
        setThemeKey!("standard");
      }
    }
  }, [data, setThemeKey]);

  useEffect(() => {
    if (!lang) {
      history.push({
        pathname: pathname,
        search: stringify({
          lang: lang ?? "PL",
        }),
      });
    }
  }, []);

  useEffect(() => {
    if (window) {
      setWindowWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Block
      overrides={{
        Block: {
          style: {
            filter:
              data?.layout?.layoutStyle === "mourning" &&
              ["standard", "green", "darkgreen", "red"].includes(themeKey || "")
                ? "grayscale(100%)"
                : "none",
          },
        },
      }}
    >
      <BaseProvider theme={theme!}>
        {skipLinks.map(({ label, id }) => (
          <SkipLink
            key={id}
            {...{
              label,
              theme: theme?.name,
              onClick: () => handleFocus(id),
            }}
          />
        ))}
        <Block backgroundColor={"backgroundPrimary"}>
          {loading && (
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    top: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }),
                },
              }}
            >
              <StyledSpinnerNext />
            </Block>
          )}
          {data && !loading && (
            <>
              {data?.topHeader && (
                <TopBar
                  data={data?.topHeader}
                  isDemo={data.layout.isDemoVersion}
                  layoutThemeKey={data?.layout?.themeKey || "standard"}
                  init={data}
                />
              )}
              {error! && (
                <div className={css({ margin: "15px 0" })}>
                  <Grid>
                    <Cell span={[4, 8, 12]}>
                      <Page404
                        data={error}
                        errorTranslation={data?.layout?.errorPageTranslation}
                      />
                    </Cell>
                  </Grid>
                </div>
              )}
              {!error && data?.header && (
                <Header
                  data={data?.header}
                  horizontalMenu={data?.horizontalMenu}
                  verticalMenu={data?.verticalMenu}
                  banners={data?.banners}
                  appSettings={data.appSettings}
                />
              )}
              {!error && (
                <div className={css({ margin: "15px 0" })}>
                  {!pageError ? (
                    <Breadcrumbs
                      breadcrumbs={pageData?.contentData?.breadcrumbs || []}
                    />
                  ) : null}
                  <Wrapper>
                    <NavigationWrapper>
                      <VerticalMenu
                        {...{
                          firstItemId: "vertical-menu-first-item",
                          items: data?.verticalMenu?.items || [],
                          level: 1,
                          isOpen: true,
                          breadcrumbs: pageData?.contentData?.breadcrumbs || [],
                          translation: data?.verticalMenu?.translation,
                        }}
                      />
                      {(data?.banners || []).map((banner) => (
                        <SideBanner key={banner.id} {...{ banner }} />
                      ))}
                    </NavigationWrapper>
                    <PageWrapper id="main">
                      <Route
                        path="/"
                        component={() => (
                          <Page
                            lang={lang?.toString() || "pl"}
                            initData={data}
                            data={pageData}
                            error={pageError}
                            loading={pageLoading}
                            response={response}
                          />
                        )}
                      />
                    </PageWrapper>
                  </Wrapper>
                </div>
              )}
              <Footer data={data?.footer} ueProject={data?.ueProject} />
              <ScrollButton
                {...{
                  text: data?.layout?.backToTop.displayText ?? "",
                }}
              />
              <Cookie {...{ content: data?.cookie?.description }} />
            </>
          )}
        </Block>
      </BaseProvider>
    </Block>
  );
};

export default AppLayout;
