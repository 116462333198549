import React from "react";

import { Block } from "baseui/block";
import { useStyletron } from "baseui";

type SProps = {
  data: any;
};

const Static: React.FC<SProps> = (props) => {
  const { data } = props;
  const [css, theme] = useStyletron();

  return (
    <Block
      overrides={{
        Block: {
          style: ({ $theme }) => ({
            backgroundColor: theme.colors.backgroundSecondary,
            margin: "0 0 20px",
            borderRadius: "5px",
            boxShadow:
              "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
          }),
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              padding: "30px 50px 40px",
            }),
          },
        }}
      >
        <pre className={css({ whiteSpace: "pre-wrap" })}>
          {JSON.stringify(data, null, 2)}
        </pre>
      </Block>
    </Block>
  );
};

export default Static;
