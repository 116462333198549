import styled from "styled-components";

export const MenuItems = styled.ul<{ isOpen: boolean; level: number }>`
  list-style: none;
  padding: 0;
  background: #fff;
  margin: 0;
  height: ${({ isOpen }) => (isOpen ? "auto" : 0)};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  box-shadow: ${({ level }) =>
    level === 1
      ? "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)"
      : "none"};
  width: 100%;
`;
