import React, { useContext, useEffect, useLayoutEffect } from "react";

import { useStyletron } from "baseui";

import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import queryString from "query-string";

import { useAccessibility } from "@contexts/AccessibilityContext";
import { ScrollPositionContext } from "@contexts/ScrollProvider";

import { processSlug } from "@utils/process-slug";

import { Pagination } from "@containers/Pagination";

import {
  Wrapper,
  Article,
  PublishedDate,
  Bookmark,
  Label,
  ContentWrapper,
  Link,
} from "./styles";

type ELProps = {
  bookmarks?: any;
  data: any;
  response: any;
};

export const EntriesList: React.FC<ELProps> = (props) => {
  const bookmarks = props.bookmarks.items;
  const { items, translation } = props.data;
  const { search } = useLocation();

  const { lang } = queryString.parse(search);
  const { headers } = props.response;

  const { scrollPosition, articleId, changeScrollPosition, changeArticleId } =
    useContext(ScrollPositionContext);

  const { fontSizeMultiplier } = useAccessibility();
  const [, theme] = useStyletron();

  const {
    colors: {
      accent50,
      backgroundSecondary,
      backgroundOverlayLight,
      contentInversePrimary,
      primary,
      primaryB,
    },
  } = theme;

  const handleScroll = () => {
    changeScrollPosition(window.scrollY, "article");
  };

  const getBookmarkBgColor = (bookmark) => {
    const currentBookMark = bookmarks?.find(
      ({ displayText }) => displayText === bookmark
    );

    if (!theme.name.includes("contrast") && currentBookMark) {
      return currentBookMark.color;
    }

    return theme.colors.backgroundOverlayLight;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    if (scrollPosition > 0) {
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }
  }, []);

  return (
    <Wrapper>
      {items.map(
        ({ id, publishedDate, bookmarkNames, title, content, moreInfo }) => (
          <Article
            key={id}
            {...{
              id,
              backgroundColor: backgroundSecondary,
              borderColor: articleId === id ? primary : contentInversePrimary,
            }}
          >
            <PublishedDate
              {...{
                backgroundColor: backgroundOverlayLight,
                color: primaryB,
                fontSize: fontSizeMultiplier! * 11,
              }}
            >
              {publishedDate}
            </PublishedDate>
            {bookmarkNames.map((bookmark) => (
              <Bookmark
                key={bookmark}
                {...{
                  backgroundColor: getBookmarkBgColor(bookmark),
                  color: primaryB,
                  fontSize: fontSizeMultiplier! * 11,
                }}
              >
                {bookmark}
              </Bookmark>
            ))}
            <Label
              {...{
                color: primaryB,
                fontSize: fontSizeMultiplier! * 15,
              }}
            >
              {title}
            </Label>
            <ContentWrapper
              {...{
                color: accent50,
                fontSize: fontSizeMultiplier! * 11,
              }}
            >
              {content
                ? ReactHtmlParser(content.replace(/<\/?[^>]+(>|$)/g, ""))
                : null}
              <div
                onClick={() => {
                  changeArticleId(id, "article");
                }}
              >
                {moreInfo ? (
                  <Link
                    {...{
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      color: primary,
                      title: moreInfo?.title,
                      slug: processSlug(moreInfo.slug, lang),
                      target: moreInfo?.target === 20 ? "_blank" : "_self",
                      url: moreInfo?.url,
                    }}
                  >
                    {moreInfo.displayText}
                  </Link>
                ) : null}
              </div>
            </ContentWrapper>
          </Article>
        )
      )}
      <Pagination
        {...{
          translation,
          headers,
        }}
      />
    </Wrapper>
  );
};
