import { createTheme, darkThemePrimitives } from 'baseui';

export const primitives = {
	...darkThemePrimitives,
	primary: '#FFF',
	primaryB: '#FFF',
	primaryFontFamily: "'Rubik', sans-serif",
	accent: '#ffffff'
};

const overrides = {
	name: 'contrast2',
	colors: {
		accent50: '#FFF',
		primary50: '#FFF',
		primary100: '#0070A8',
		primary200: '#628033',
		primary300: '#0C5944',
		primary400: '#CD1040',
		accent100: '#FFF000',
		accent200: '#FFF',
		accent300: '#00ff00',
		accent400: '#000000',
		accent500: '#0000ff',
		accent600: '#ffff00',
		breadcrumbsText: '#FFF',
		contentAccent: '#000000',
		buttonPrimaryFill: '#000000',
		buttonPrimaryText: '#FFF',
		buttonPrimaryHover: '#b8b8b8',
		buttonTertiaryHover: '#141414',
		buttonTertiaryFill: '#000000',
		backgroundInversePrimary: '#000000',
		backgroundPrimary: '#333',
		backgroundSecondary: '#000000',
		backgroundAccent: '#000000',
		inputFill: '#FFF',
		borderSelected: '#FFF',
		contentInversePrimary: '#000000',
		backgroundInverseSecondary: '#000000',
		backgroundInv: '#FFF',
		contentInverseSecondary: '#000000',
		backgroundOverlayDark: '#000000',
		contentTertiary: '#FFF',
		contentInverseTertiary: '#FFF',
		backgroundLightAccent: '#000000',
		borderInverseOpaque: '#FFF',
		backgroundOverlayLight: '#333',
		menuFill: '#FFFFFF',
		menuFillHover: '#F6F6F6',
		calendarBackground: '#000000',
		calendarForeground: '#FFF',
		calendarForegroundDisabled: '#8a8a8a',
		calendarHeaderBackground: '#000000',
		calendarHeaderForeground: '#FFF',
		buttonDisabledFill: 'transparent',
		calendarDayForegroundSelectedHighlighted: '#000000',
		calendarDayBackgroundSelected: '#FFF',
		calendarDayForegroundSelected: '#000000'
	}
};

export default createTheme(primitives, overrides);
