// @ts-ignore;
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  Fragment,
  useRef,
} from "react";
import { Link, useLocation } from "react-router-dom";
import useAxios from "axios-hooks";
import ReactHtmlParser from "react-html-parser";
import HtmlDiff from "htmldiff-js";
import queryString from "query-string";
import download from "downloadjs";
import { useReactToPrint } from "react-to-print";

import { Multimedia } from "@containers/Multimedia/Multimedia";
import { Attachments } from "@containers/Attachments/Attachments";
import { References } from "@containers/References/References";
import FailureWindow from "@components/Modals/FailureWindow";
import { MetricValue } from "@containers/MetricValue/MetricValue";

import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button, KIND, SIZE } from "baseui/button";
import { Modal, SIZE as SIZEM } from "baseui/modal";
import { H3, Label4, Paragraph4, Label3, LabelMedium } from "baseui/typography";
import { Tag } from "baseui/tag";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { StyledSpinnerNext } from "baseui/spinner";
import { useAccessibility } from "@contexts/AccessibilityContext";
import { isServer } from "@helpers/ssr";
import { rewriteUrl } from "@helpers/client-axios";

import { NArticle } from "@namespace/Article";
import { COLORS } from "@utils/constants";

import { attachmentStatus, failWindowHandler, startDownloadPDF } from "./utils";

import {
  ArticleButton,
  ContentWrapper,
  DateWrapper,
  Inner,
  Spinner,
  Title,
  Wrapper,
} from "./styles";

const Article: React.FC<NArticle.IProps> = ({ data: dataProps, type }) => {
  const data = dataProps?.item;
  const translation = dataProps?.translation;

  const { search, pathname } = useLocation();
  const { lang } = queryString.parse(search);

  const articleContentRef = useRef(null);
  const articleContentQuickLookModalRef = useRef(null);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isModalClicked, setIsModalClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [actionType, setActionType] = useState(0);
  const [isQuickLookModalOpened, setIsQuickLookModalOpened] = useState(false);

  const [articleVersions, setArticleVersions] = useState<any[]>([]);
  const [articleVersionsFetched, setArticleVersionsFetched] = useState(false);
  const [articleVersionsSelected, setArticleVersionsSelected] = useState(0);

  const { fontSizeMultiplier } = useAccessibility();
  const [, theme] = useStyletron();

  const {
    colors: { primary },
  } = theme;

  const handlePrintArticle = useReactToPrint({
    content: () => articleContentRef.current,
    pageStyle:
      "@page { size:  auto; margin: 30mm 25mm 20mm 25mm;} #multimediaWrapper { display: none} #articleContent { padding: 30mm, width: 100vw!important; font-family: 'Rubik', sans-serif; word-break: break-word;} h3 { font-size: 28px; font-weight: 700; } #articleContent > div:first-child { border-bottom: 1px solid rgb(225, 225, 225); padding-bottom: 15px } #articleContent > div:first-child > * { width: 100%; margin: 0px 30px 10px 0px } #articleContent > div:last-child p { padding: 10px 0px 5px 0px !important; }#articleContent .metrics-print { display: flex !important; }",
  });

  const handlePrintArticleContentQuickLookModal = useReactToPrint({
    content: () => articleContentQuickLookModalRef.current,
    pageStyle:
      "#articleContentQuickLookModal { padding: 130px, width: 100vw !important   } h3 { font-size: 28px; font-weight: 700 } #articleContentQuickLookModal > div:first-child { border-bottom: 1px solid rgb(225, 225, 225); padding-bottom: 15px } #articleContentQuickLookModal > div:first-child > * { width: 100%; margin: 0px 30px 10px 0px } #articleContentQuickLookModal > div:last-child p { padding: 10px 0px 5px 0px !important; } ",
  });

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, [true]);

  useEffect(() => {
    if (data && data?.versions) setArticleVersions(data?.versions);
  }, [data]);

  useEffect(() => {
    if (articleVersions.length && !articleVersionsFetched) {
      setArticleVersions(
        articleVersions.map((aV: any) => ({ ...aV, selected: false }))
      );
      setArticleVersionsFetched(true);
    }
  }, [articleVersions, articleVersionsFetched]);

  useEffect(() => {
    setArticleVersionsSelected(
      articleVersions?.filter((aV) => aV.selected).length
    );
  }, [articleVersions]);

  useEffect(() => {
    if (isModalOpened && articleVersionsSelected === 2)
      doArticleVersionsDiffQuery();
  }, [articleVersionsSelected, isModalOpened]);

  const deepEqual = (x: any, y: any): boolean => {
    const ok = Object.keys;
    const tx = typeof x;
    const ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x).length === ok(y).length &&
          ok(x).every((key) => deepEqual(x[key], y[key]))
      : x === y;
  };

  const [{ data: articleVersionsDiffQueryData }, doArticleVersionsDiffQuery] =
    useAxios(
      {
        url: `${
          isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
        }/page-content/articles/${data?.id}/versions?firstVersionId=${
          articleVersions?.filter((aV) => aV.selected)[0]?.id
        }&secondVersionId=${
          articleVersions?.filter((aV) => aV.selected)[1]?.id
        }`,
        method: "GET",
      },
      {
        manual: true,
      }
    );

  const [{ data: articleVersionQueryData }, doArticleVersionQuery] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const makeArticleVersionQuery = (versionId: any) => {
    doArticleVersionQuery({
      url: `${
        isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
      }/page-content/articles/${data?.id}/versions/${versionId}`,
    }).then(() => setIsQuickLookModalOpened(true));
  };

  const [{ data: pdfData, error: error }, doGeneratePDFArticleQuery] = useAxios(
    {
      url: `${
        isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
      }/page-content${pathname}/pdf${search}`,
      method: "POST",
      responseType: "blob",
    },
    {
      manual: true,
    }
  );

  const saveToPDF = async () => {
    setIsLoading(true);
    const { data: pdfData, headers: pdfHeaders } =
      await doGeneratePDFArticleQuery();
    if (pdfData && pdfHeaders) {
      const result = download(
        pdfData,
        "download.pdf",
        pdfHeaders["content-type"]
      );
      if (!result) {
        failWindowHandler(setActionType, setIsFailureModalOpen, 10);
      }
    }
    setIsLoading(false);
  };

  if (!isModalClicked && error && isFailureModalOpen === false) {
    failWindowHandler(setActionType, setIsFailureModalOpen, 10);
    setIsLoading(false);
  }

  const loader = isLoading ? (
    <StyledSpinnerNext
      style={{
        margin: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: "1",
      }}
    />
  ) : null;

  const getArticleContent = (): string => {
    const div = document.createElement("div");
    div.innerHTML = data?.content;
    const articleContentsHTMLCollections =
      div.getElementsByClassName("article-content");

    const articleContentHTMLElement = articleContentsHTMLCollections.length
      ? articleContentsHTMLCollections[
          articleContentsHTMLCollections.length - 1
        ]
      : null;

    const length = articleContentHTMLElement?.children.length || 0;

    let articleContentChildren = "";

    if (articleContentHTMLElement) {
      for (let i = 0; i <= length; i++) {
        if (articleContentHTMLElement?.children[i]?.outerHTML) {
          articleContentChildren +=
            articleContentHTMLElement?.children[i]?.outerHTML;
        }
      }
    }

    return articleContentChildren;
  };

  const processAttachments = (table1, table2) => {
    const getKey = (item) =>
      item.documentRepositoryId || item.documentRepositoryFileId;

    const table1Keys = new Set(table1.map(getKey));
    const table2Keys = new Set(table2.map(getKey));

    const newTable1 = table1
      .filter((item) => !table2Keys.has(getKey(item)))
      .map((item) => ({ ...item, status: attachmentStatus.removed }));

    const newTable2 = table2.map((item) => {
      if (table1Keys.has(getKey(item))) {
        return { ...item, status: attachmentStatus.current };
      } else {
        return { ...item, status: attachmentStatus.new };
      }
    });

    return [...newTable1, ...newTable2];
  };

  const currentArrachments = processAttachments(
    articleVersionsDiffQueryData?.item?.first?.attachments || [],
    articleVersionsDiffQueryData?.item?.second?.attachments || []
  );

  return (
    <Fragment>
      {isLoading ? <Spinner /> : null}
      <FailureWindow
        {...{
          isFailureModalOpen,
          setIsFailureModalOpen,
          actionType,
          setIsModalClicked,
        }}
      />
      <Wrapper
        {...{
          backgroundColor: theme.colors.backgroundSecondary,
          isLoading,
        }}
      >
        <Inner>
          <div id="articleContent" ref={articleContentRef}>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    borderBottom: "1px solid " + theme.colors.backgroundInv,
                  }),
                },
              }}
            >
              <Title
                {...{
                  color: primary,
                  fontSize: fontSizeMultiplier! * 16,
                }}
              >
                {data?.title}
              </Title>
              <DateWrapper
                {...{
                  color: theme.colors.accent50,
                  fontSize: fontSizeMultiplier! * 11,
                }}
              >
                {data?.publishedDate}
              </DateWrapper>
            </Block>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    padding: "20px 0 0",
                  }),
                },
              }}
            >
              <ContentWrapper
                {...{
                  fontSize: fontSizeMultiplier! * 12,
                  color: theme.colors.accent50,
                }}
              >
                <div className="article-content">
                  {getArticleContent()
                    ? ReactHtmlParser(getArticleContent())
                    : data?.content && ReactHtmlParser(data?.content)}
                </div>
              </ContentWrapper>
            </Block>
            {data?.rss && data?.rss.length > 0 && (
              <Block>
                <Label3
                  overrides={{
                    Block: {
                      style: () => ({
                        marginTop: "30px",
                        color: theme.colors.accent50,
                        padding: "0px 0px 8px 0",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "20px",
                        letterSpacing: "1px",
                        fontWeight: 500,
                        textTransform: "uppercase",
                        borderBottom: "1px solid " + theme.colors.backgroundInv,
                      }),
                    },
                  }}
                >
                  {translation?.listNames?.rss}
                </Label3>

                {data?.rss.map((rssItem, index) => {
                  return (
                    <Block
                      key={index}
                      as={(props) => (
                        <a
                          href={rssItem.url + (lang ? `?lang=${lang}` : "")}
                          target={rssItem.target === 20 ? "_blank" : ""}
                          {...props}
                        >
                          {props.children}
                        </a>
                      )}
                      overrides={{
                        Block: {
                          props: {
                            title: rssItem.title,
                          },
                          style: () => ({
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            textDecoration: "none",
                            alignItems: "center",
                            borderBottom:
                              "1px solid " + theme.colors.backgroundInv,
                            padding: "10px 0",
                          }),
                        },
                      }}
                    >
                      <img
                        src={
                          (isServer() ? "/" : "") +
                          require("../../assets/icons/rssChannel.svg")
                        }
                        alt="Link"
                        height="20px"
                        width="20px"
                      />
                      <LabelMedium
                        overrides={{
                          Block: {
                            style: () => ({
                              color: theme.colors.primary,
                              margin: "0px 0px 0px 20px",
                              textDecoration: "underline",
                              fontSize: fontSizeMultiplier! * 11 + "px",
                              letterSpacing: ".1px",
                              minWidth: "100px",
                              fontWeight: 400,
                            }),
                          },
                        }}
                      >
                        {rssItem.displayText}
                      </LabelMedium>
                    </Block>
                  );
                })}
              </Block>
            )}
            {data.multimedia.length ? (
              <Multimedia {...{ multimedia: data.multimedia || [] }} />
            ) : null}
            {!!data?.attachments?.length ? (
              <Attachments
                {...{
                  attachments: data?.attachments,
                  translation,
                }}
              />
            ) : null}
            {!!data?.links?.length ? (
              <References
                {...{ links: data.links, title: translation?.listNames?.links }}
              />
            ) : null}
            {/* only for printing */}
            <div
              className="metrics-print"
              style={
                {
                  display: "none",
                  width: "100%",
                  marginTop: 30,
                } as React.CSSProperties
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: theme.colors.backgroundSecondary,
                  padding: "10px 30px",
                  border: "1px solid " + theme.colors.backgroundInv,
                  color: theme.colors.accent50,
                  width: "100%",
                }}
              >
                {data?.metrics.map((metric: any, index: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        borderBottom:
                          index !== data?.metrics.length - 1
                            ? "1px solid " + theme.colors.backgroundInv
                            : "none",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          padding: "10px 0px",
                          width: "33%",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            fontSize: fontSizeMultiplier! * 14 + "px",
                            lineHeight: "12px",
                            fontWeight: 500,
                            color: "rgb(0, 0, 0)",
                            letterSpacing: ".25px",
                          }}
                        >
                          {metric.key}
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "10px 0px",
                          width: "33%",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            fontSize: fontSizeMultiplier! * 14 + "px",
                            lineHeight: "12px",
                            fontWeight: 400,
                            color: "rgb(0, 0, 0)",
                            letterSpacing: ".25px",
                          }}
                        >
                          {metric.value}
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "10px 0px",
                          width: "33%",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            fontSize: fontSizeMultiplier! * 14 + "px",
                            lineHeight: "12px",
                            fontWeight: 400,
                            letterSpacing: ".25px",
                            overflow: "hidden",
                            color: "rgb(0, 0, 0)",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {metric.date}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Inner>
        {/* Article Footer */}
        <Block
          justifyContent={[
            "flex-start",
            "flex-start",
            "flex-start",
            type === 130 ? "flex-end" : "space-between",
          ]}
          overrides={{
            Block: {
              style: () => ({
                display: "flex",
                flexDirection: "row",
                padding: "0px 50px 0px",
                backgroundColor: theme.colors.backgroundOverlayDark,
                flexWrap: "wrap",
              }),
            },
          }}
        >
          {/* buttons */}
          {type !== 130 && (
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }),
                },
              }}
            >
              <ArticleButton
                {...{
                  color: theme.colors.breadcrumbsText,
                  hoverColor:
                    theme.name !== "standard"
                      ? theme.colors.primary
                      : COLORS.ARTICLE_STANDARD,
                  fontSize: fontSizeMultiplier! * 11,
                  title: translation?.footer?.printButton?.title,
                  onClick: isServer()
                    ? () => {
                        failWindowHandler(
                          setActionType,
                          setIsFailureModalOpen,
                          20
                        );
                      }
                    : () => handlePrintArticle(),
                }}
              >
                <img
                  height="16px"
                  alt={translation?.footer?.printButton?.displayText}
                  src={
                    (isServer() ? "/" : "") +
                    require(`../../assets/icons/print-${theme.name}.svg`)
                  }
                />
                {translation?.footer?.printButton?.displayText}
              </ArticleButton>
              <ArticleButton
                {...{
                  color: theme.colors.breadcrumbsText,
                  hoverColor:
                    theme.name !== "standard"
                      ? theme.colors.primary
                      : COLORS.ARTICLE_STANDARD,
                  fontSize: fontSizeMultiplier! * 11,
                  title: translation?.footer?.saveToPdfButton?.title,
                  onClick: () => startDownloadPDF(setIsModalClicked, saveToPDF),
                }}
              >
                <img
                  height="16px"
                  alt={translation?.footer?.saveToPdfButton?.displayText}
                  src={
                    (isServer() ? "/" : "") +
                    require(`../../assets/icons/pdf-file-${theme.name}.svg`)
                  }
                />
                {translation?.footer?.saveToPdfButton?.displayText}
              </ArticleButton>
            </Block>
          )}
          {/* metric popover */}
          <Block
            justifyContent={[
              "flex-start",
              "flex-start",
              "flex-start",
              "flex-end",
            ]}
            overrides={{
              Block: {
                style: () => ({
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                }),
              },
            }}
          >
            {data?.versions?.length > 1 && (
              <StatefulPopover
                content={() => (
                  <Block
                    minWidth={["0px", "300px", "300px", "300px"]}
                    overrides={{
                      Block: {
                        style: () => ({
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: theme.colors.backgroundSecondary,
                          padding: "0px",
                          boxShadow:
                            "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                          color: theme.colors.accent50,
                        }),
                      },
                    }}
                  >
                    <Block
                      overrides={{
                        Block: {
                          style: () => ({
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            backgroundColor:
                              theme.colors.backgroundInverseSecondary,
                            padding: "0 30px",
                          }),
                        },
                      }}
                    >
                      <Block
                        overrides={{
                          Block: {
                            style: () => ({
                              padding: "10px 0px",
                              width: "30%",
                              boxSizing: "border-box",
                            }),
                          },
                        }}
                      >
                        <Label4
                          overrides={{
                            Block: {
                              style: () => ({
                                fontSize: fontSizeMultiplier! * 10 + "px",
                                color: theme.colors.contentTertiary,
                                lineHeight: "12px",
                                fontWeight: 500,
                                letterSpacing: ".25px",
                              }),
                            },
                          }}
                        >
                          {translation?.footer?.previousVersionsTableHeaders[0]}
                        </Label4>
                      </Block>
                      <Block
                        overrides={{
                          Block: {
                            style: () => ({
                              padding: "10px 0px",
                              width: "40%",
                              boxSizing: "border-box",
                            }),
                          },
                        }}
                      >
                        <Label4
                          overrides={{
                            Block: {
                              style: () => ({
                                fontSize: fontSizeMultiplier! * 10 + "px",
                                color: theme.colors.contentTertiary,
                                lineHeight: "12px",
                                fontWeight: 500,
                                letterSpacing: ".25px",
                              }),
                            },
                          }}
                        >
                          {translation?.footer?.previousVersionsTableHeaders[1]}
                        </Label4>
                      </Block>
                      <Block
                        overrides={{
                          Block: {
                            style: () => ({
                              padding: "10px 0px",
                              width: "15%",
                              boxSizing: "border-box",
                            }),
                          },
                        }}
                      >
                        <Label4
                          overrides={{
                            Block: {
                              style: () => ({
                                fontSize: fontSizeMultiplier! * 10 + "px",
                                color: theme.colors.contentTertiary,
                                lineHeight: "12px",
                                fontWeight: 500,
                                letterSpacing: ".25px",
                                textAlign: "center",
                              }),
                            },
                          }}
                        >
                          {translation?.footer?.previousVersionsTableHeaders[2]}
                        </Label4>
                      </Block>
                      <Block
                        overrides={{
                          Block: {
                            style: () => ({
                              padding: "10px 0px",
                              width: "15%",
                              boxSizing: "border-box",
                            }),
                          },
                        }}
                      >
                        <Label4
                          overrides={{
                            Block: {
                              style: () => ({
                                fontSize: fontSizeMultiplier! * 10 + "px",
                                color: theme.colors.contentTertiary,
                                lineHeight: "12px",
                                fontWeight: 500,
                                letterSpacing: ".25px",
                                textAlign: "center",
                              }),
                            },
                          }}
                        >
                          {translation?.footer?.previousVersionsTableHeaders[3]}
                        </Label4>
                      </Block>
                    </Block>
                    {data?.versions.map((version, index) => {
                      return (
                        <Block
                          key={index}
                          overrides={{
                            Block: {
                              style: () => ({
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                borderBottom:
                                  "1px solid " + theme.colors.backgroundInv,
                                margin: "0 30px",
                              }),
                            },
                          }}
                        >
                          <Block
                            overrides={{
                              Block: {
                                style: () => ({
                                  padding: "10px 0px",
                                  width: "30%",
                                  boxSizing: "border-box",
                                }),
                              },
                            }}
                          >
                            <Label4
                              overrides={{
                                Block: {
                                  style: () => ({
                                    fontSize: fontSizeMultiplier! * 10 + "px",
                                    color: theme.colors.contentTertiary,
                                    lineHeight: "12px",
                                    fontWeight: 400,
                                    letterSpacing: ".25px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    paddingRight: "5px",
                                  }),
                                },
                              }}
                            >
                              {version.publishDate}
                            </Label4>
                          </Block>
                          <Block
                            overrides={{
                              Block: {
                                style: () => ({
                                  padding: "10px 0px",
                                  width: "40%",
                                  boxSizing: "border-box",
                                }),
                              },
                            }}
                          >
                            <Label4
                              overrides={{
                                Block: {
                                  style: () => ({
                                    fontSize: fontSizeMultiplier! * 10 + "px",
                                    color: theme.colors.contentTertiary,
                                    lineHeight: "12px",
                                    fontWeight: 400,
                                    letterSpacing: ".25px",
                                  }),
                                },
                              }}
                            >
                              {version.publishBy}
                            </Label4>
                          </Block>
                          <Block
                            overrides={{
                              Block: {
                                style: () => ({
                                  padding: "10px 0px",
                                  width: "15%",
                                  boxSizing: "border-box",
                                  textAlign: "center",
                                }),
                              },
                            }}
                          >
                            <Button
                              onClick={() =>
                                makeArticleVersionQuery(version.id)
                              }
                              kind={KIND.tertiary}
                              overrides={{
                                BaseButton: {
                                  style: () => ({
                                    padding: "0!important",
                                  }),
                                },
                              }}
                            >
                              <img
                                height="15px"
                                src={
                                  (isServer() ? "/" : "") +
                                  require("../../assets/icons/zoom.svg")
                                }
                                alt={"zoom"}
                              />
                            </Button>
                          </Block>
                          <Block
                            overrides={{
                              Block: {
                                style: () => ({
                                  padding: "10px 0px",
                                  width: "15%",
                                  boxSizing: "border-box",
                                }),
                              },
                            }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                setArticleVersions(
                                  articleVersions.map((aV: any) =>
                                    aV.id === version.id
                                      ? {
                                          ...aV,
                                          selected: e.currentTarget.checked,
                                        }
                                      : aV
                                  )
                                )
                              }
                              checked={
                                articleVersions?.filter(
                                  (aV) => aV.id === version.id
                                )[0]?.selected
                              }
                              labelPlacement={LABEL_PLACEMENT.right}
                              disabled={
                                !articleVersions?.filter(
                                  (aV) => aV.id === version.id
                                )[0]?.selected && articleVersionsSelected === 2
                              }
                              overrides={{
                                Root: {
                                  style: () => ({
                                    width: "17px",
                                    margin: "auto",
                                  }),
                                },
                                Label: {
                                  style: () => ({
                                    lineHeight: "12px",
                                  }),
                                },
                                Checkmark: {
                                  style: ({ $checked }) => ({
                                    borderColor: "#005BFF",
                                    borderWidth: "2px",
                                    borderRadius: "3px",
                                    width: "12px",
                                    height: "12px",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    backgroundColor:
                                      theme.colors.backgroundSecondary,
                                    backgroundImage: $checked
                                      ? "url(data:image/svg+xml,%0A%20%20%20%20%3Csvg%20width%3D%2217%22%20height%3D%2213%22%20viewBox%3D%220%200%2017%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M6.50002%2012.6L0.400024%206.60002L2.60002%204.40002L6.50002%208.40002L13.9%200.900024L16.1%203.10002L6.50002%2012.6Z%22%20fill%3D%22%23005BFF%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E%0A%20%20)"
                                      : "",
                                  }),
                                },
                              }}
                            ></Checkbox>
                          </Block>
                        </Block>
                      );
                    })}
                    <Block
                      overrides={{
                        Block: {
                          style: () => ({
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            padding: "10px 30px",
                            justifyContent: "flex-end",
                          }),
                        },
                      }}
                    >
                      <Button
                        onClick={() => {
                          doArticleVersionsDiffQuery().then(() =>
                            setIsModalOpened(true)
                          );
                        }}
                        disabled={articleVersionsSelected !== 2}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              border: "1px solid " + $theme.colors.primary,
                              fontWeight: 500,
                              fontSize: fontSizeMultiplier! * 11 + "px",
                              letterSpacing: ".25px",
                              textTransform: "uppercase",
                              padding: "8px 20px 7px",
                              lineHeight: "12px",
                              borderRadius: "4px",
                            }),
                          },
                        }}
                      >
                        {translation?.footer?.previousVersionsTableHeaders[3]}
                      </Button>
                    </Block>
                  </Block>
                )}
                placement={PLACEMENT.bottomRight}
                autoFocus={false}
                focusLock={false}
                returnFocus={true}
                overrides={{
                  Body: {
                    style: () => ({
                      left:
                        !isServer() && window.innerWidth > 900 ? "177px" : "1%",
                      right:
                        !isServer() && window.innerWidth > 900 ? "unset" : "5%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      minWidth:
                        !isServer() && window.innerWidth > 900
                          ? "300px"
                          : "0px",
                      width:
                        !isServer() && window.innerWidth > 900
                          ? "422px"
                          : "auto",
                    }),
                  },
                  Inner: {
                    style: () => ({
                      padding: "0px",
                    }),
                  },
                }}
              >
                <Button
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                  startEnhancer={() => (
                    <img
                      height="16px"
                      alt={
                        translation?.footer?.previousVersionsButton?.displayText
                      }
                      src={
                        (isServer() ? "/" : "") +
                        require(`../../assets/icons/reverse-${theme.name}.svg`)
                      }
                    />
                  )}
                  overrides={{
                    BaseButton: {
                      props: {
                        title:
                          translation?.footer?.previousVersionsButton?.title,
                      },
                      style: () => ({
                        color: theme.colors.breadcrumbsText,
                        padding: "15px 0px",
                        margin: "0px 35px 0px 0px",
                        textTransform: "uppercase",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: 400,
                        lineHeight: "11px",
                        ":hover": {
                          backgroundColor: "transparent",
                          color:
                            theme.name !== "standard"
                              ? theme.colors.primary
                              : COLORS.ARTICLE_STANDARD,
                        },
                        ":focus": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                        ":active": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                        ":visited": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                      }),
                    },
                  }}
                >
                  {translation?.footer?.previousVersionsButton?.displayText}
                </Button>
              </StatefulPopover>
            )}
            {data?.metrics && (
              <StatefulPopover
                content={() => (
                  <Block
                    minWidth={["0px", "0px", "350px", "350px"]}
                    overrides={{
                      Block: {
                        style: () => ({
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: theme.colors.backgroundSecondary,
                          padding: "10px 30px",
                          boxShadow:
                            "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                          color: theme.colors.accent50,
                        }),
                      },
                    }}
                  >
                    {data?.metrics.map((metric: any, index: any) => {
                      return (
                        <Block
                          key={index}
                          overrides={{
                            Block: {
                              style: () => ({
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                borderBottom:
                                  index !== data?.metrics.length - 1
                                    ? "1px solid " + theme.colors.backgroundInv
                                    : "none",
                              }),
                            },
                          }}
                        >
                          <Block
                            overrides={{
                              Block: {
                                style: () => ({
                                  padding: "10px 0px",
                                  width: "33%",
                                  boxSizing: "border-box",
                                }),
                              },
                            }}
                          >
                            <Label4
                              overrides={{
                                Block: {
                                  style: () => ({
                                    fontSize: fontSizeMultiplier! * 10 + "px",
                                    color: theme.colors.contentTertiary,
                                    lineHeight: "12px",
                                    fontWeight: 500,
                                    letterSpacing: ".25px",
                                  }),
                                },
                              }}
                            >
                              {metric.key}
                            </Label4>
                          </Block>
                          <Block
                            overrides={{
                              Block: {
                                style: () => ({
                                  padding: "10px 0px",
                                  width: "33%",
                                  boxSizing: "border-box",
                                }),
                              },
                            }}
                          >
                            <MetricValue
                              {...{
                                email: metric.email,
                                value: metric.value,
                              }}
                            />
                          </Block>
                          <Block
                            overrides={{
                              Block: {
                                style: () => ({
                                  padding: "10px 0px",
                                  width: "33%",
                                  boxSizing: "border-box",
                                }),
                              },
                            }}
                          >
                            <Label4
                              overrides={{
                                Block: {
                                  style: () => ({
                                    fontSize: fontSizeMultiplier! * 10 + "px",
                                    color: theme.colors.contentTertiary,
                                    lineHeight: "12px",
                                    fontWeight: 400,
                                    letterSpacing: ".25px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }),
                                },
                              }}
                            >
                              {metric.date}
                            </Label4>
                          </Block>
                        </Block>
                      );
                    })}
                  </Block>
                )}
                placement={PLACEMENT.bottomRight}
                focusLock
                returnFocus
                autoFocus
                overrides={{
                  Body: {
                    style: () => ({
                      left:
                        !isServer() && window.innerWidth > 900 ? "50px" : "1%",
                      right:
                        !isServer() && window.innerWidth > 900 ? "unset" : "3%",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }),
                  },
                }}
              >
                <Button
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                  startEnhancer={() => (
                    <img
                      height="16px"
                      alt={translation?.footer?.metricButton?.displayText}
                      src={
                        (isServer() ? "/" : "") +
                        require(`../../assets/icons/info-${theme.name}.svg`)
                      }
                    />
                  )}
                  overrides={{
                    BaseButton: {
                      props: {
                        title: translation?.footer?.metricButton?.title,
                      },
                      style: () => ({
                        color: theme.colors.breadcrumbsText,
                        padding: "15px 0px",
                        margin: "0px 0px 0px 0px",
                        textTransform: "uppercase",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: 400,
                        lineHeight: "11px",
                        ":hover": {
                          backgroundColor: "transparent",
                          color:
                            theme.name !== "standard"
                              ? theme.colors.primary
                              : COLORS.ARTICLE_STANDARD,
                        },
                        ":focus": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                        ":active": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                        ":visited": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                      }),
                    },
                  }}
                >
                  {translation?.footer?.metricButton?.displayText}
                </Button>
              </StatefulPopover>
            )}
          </Block>
        </Block>

        <Modal
          size={
            !isServer() && window.innerWidth > 900 ? SIZEM.default : SIZEM.full
          }
          onClose={() => setIsModalOpened(false)}
          isOpen={isModalOpened}
          overrides={{
            Backdrop: {
              style: () => ({
                backgroundColor: "rgba(255,255,255,.7)",
              }),
            },
            Dialog: {
              style: () => ({
                backgroundColor: theme.colors.backgroundSecondary,
                minWidth: "55vw",
                margin: "0px",
                borderRadius: "5px",
                boxShadow:
                  "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  padding: "30px 50px 0px",
                }),
              },
            }}
          >
            <H3
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.primary,
                    margin: "0px 0px 10px",
                    fontSize: fontSizeMultiplier! * 16 + "px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }),
                },
              }}
            >
              {translation?.comparision?.title}
            </H3>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    color: theme.colors.accent50,
                    borderBottom: "1px solid " + theme.colors.backgroundInv,
                    paddingBottom: "20px",
                  }),
                },
              }}
            >
              <Block
                width={["100%", "100%", "50%", "50%"]}
                overrides={{
                  Block: {
                    style: () => ({
                      boxSizing: "border-box",
                    }),
                  },
                }}
              >
                {articleVersions
                  .filter((aV: { selected: any }) => aV.selected)
                  .map((version: any) => {
                    return (
                      <Block
                        overrides={{
                          Block: {
                            style: () => ({
                              display: "block",
                              margin: "10px 0",
                            }),
                          },
                        }}
                      >
                        <Checkbox
                          disabled
                          checked={
                            articleVersions?.filter(
                              (aV) => aV.id === version.id
                            )[0]?.selected
                          }
                          labelPlacement={LABEL_PLACEMENT.right}
                          overrides={{
                            Label: {
                              style: () => ({
                                lineHeight: "12px",
                                height: "12px",
                              }),
                            },
                            Checkmark: {
                              style: ({ $checked }) => ({
                                borderColor: "#005BFF",
                                borderWidth: "2px",
                                borderRadius: "3px",
                                width: "12px",
                                height: "12px",
                                marginTop: "0px",
                                marginBottom: "0px",
                                backgroundColor:
                                  theme.colors.backgroundSecondary,
                                backgroundImage: $checked
                                  ? "url(data:image/svg+xml,%0A%20%20%20%20%3Csvg%20width%3D%2217%22%20height%3D%2213%22%20viewBox%3D%220%200%2017%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M6.50002%2012.6L0.400024%206.60002L2.60002%204.40002L6.50002%208.40002L13.9%200.900024L16.1%203.10002L6.50002%2012.6Z%22%20fill%3D%22%23005BFF%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E%0A%20%20)"
                                  : "",
                              }),
                            },
                          }}
                        >
                          <Label4
                            overrides={{
                              Block: {
                                style: () => ({
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px",
                                  fontSize: fontSizeMultiplier! * 10 + "px",
                                  color: theme.colors.contentTertiary,
                                  lineHeight: "12px",
                                  fontWeight: 500,
                                  letterSpacing: ".25px",
                                  width: "110px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }),
                              },
                            }}
                          >
                            {version.publishDate}
                          </Label4>
                          <Label4
                            overrides={{
                              Block: {
                                style: () => ({
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px",
                                  fontSize: fontSizeMultiplier! * 10 + "px",
                                  color: theme.colors.contentTertiary,
                                  lineHeight: "12px",
                                  fontWeight: 400,
                                  letterSpacing: ".25px",
                                  verticalAlign: "top",
                                }),
                              },
                            }}
                          >
                            {version.publishBy}
                          </Label4>
                        </Checkbox>
                      </Block>
                    );
                  })}
              </Block>
              <Block
                width={["100%", "100%", "50%", "50%"]}
                overrides={{
                  Block: {
                    style: () => ({
                      boxSizing: "border-box",
                    }),
                  },
                }}
              >
                <Block
                  overrides={{
                    Block: {
                      style: () => ({
                        display: "block",
                        margin: "10px 0",
                      }),
                    },
                  }}
                >
                  <Label4
                    display={["block", "block", "inline-block", "inline-block"]}
                    overrides={{
                      Block: {
                        style: () => ({
                          margin: "0px 45px 10px 0px",
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          color: theme.colors.contentTertiary,
                          lineHeight: "12px",
                          fontWeight: 500,
                          letterSpacing: ".25px",
                        }),
                      },
                    }}
                  >
                    {translation?.comparision?.legend}:
                  </Label4>
                  <Tag
                    closeable={false}
                    overrides={{
                      Root: {
                        style: () => ({
                          backgroundColor: "#d1f1d6",
                          borderRadius: "0px",
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          lineHeight: "10px",
                          fontWeight: 400,
                          height: "auto",
                          margin: "0px 30px 0px 0px",
                          textAlign: "left",
                          padding: "5px",
                          minWidth: "70px",
                          color: "#707070",
                        }),
                      },
                    }}
                  >
                    {translation?.comparision?.add}
                  </Tag>
                  <Tag
                    closeable={false}
                    overrides={{
                      Root: {
                        style: () => ({
                          backgroundColor: "#fad2ce",
                          borderRadius: "0px",
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          lineHeight: "10px",
                          fontWeight: 400,
                          height: "auto",
                          margin: "0px 30px 0px 0px",
                          textAlign: "left",
                          padding: "5px",
                          minWidth: "70px",
                          color: theme.colors.borderInverseOpaque,
                          textDecoration: "line-through",
                        }),
                      },
                    }}
                  >
                    {translation?.comparision?.remove}
                  </Tag>
                </Block>
              </Block>
            </Block>
          </Block>
          <Block
            overrides={{
              Block: {
                style: () => ({
                  padding: "20px 50px 30px",
                }),
              },
            }}
          >
            <div id="articleContentDiffModal">
              <H3
                overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.primary,
                      margin: "0px 0px 20px",
                      fontSize: fontSizeMultiplier! * 16 + "px",
                      lineHeight: "18px",
                      letterSpacing: "1px",
                      fontWeight: 500,
                    }),
                  },
                }}
              >
                {data?.title}
              </H3>
              <Block
                overrides={{
                  Block: {
                    style: () => ({
                      padding: "0px",
                    }),
                  },
                }}
              >
                <Paragraph4
                  as="div"
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "20px 0px 0px",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "18px",
                        color: theme.colors.accent50,
                      }),
                    },
                  }}
                >
                  {articleVersionsDiffQueryData &&
                    ReactHtmlParser(
                      HtmlDiff.execute(
                        articleVersionsDiffQueryData?.item?.first?.content ||
                          "",
                        articleVersionsDiffQueryData?.item?.second?.content ||
                          ""
                      )
                    )}
                </Paragraph4>
              </Block>
            </div>
            <Block>
              {currentArrachments.length ? (
                <Block>
                  <Label3
                    overrides={{
                      Block: {
                        style: () => ({
                          marginTop: "30px",
                          color: theme.colors.accent50,
                          padding: "0px 0px 8px 0",
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          lineHeight: "20px",
                          letterSpacing: "1px",
                          fontWeight: 500,
                          textTransform: "uppercase",
                          borderBottom:
                            "1px solid " + theme.colors.backgroundInv,
                        }),
                      },
                    }}
                  >
                    {translation?.listNames?.attachments}
                  </Label3>
                  {currentArrachments.map((attachment) => (
                    <Block
                      key={attachment.id}
                      as={(props) => (
                        <a href={attachment.url} {...props}>
                          {props.children}
                        </a>
                      )}
                      overrides={{
                        Block: {
                          props: {
                            title: attachment.title,
                          },
                          style: () => ({
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            textDecoration: "none",
                            alignItems: "center",
                            borderBottom:
                              "1px solid " + theme.colors.backgroundInv,
                            padding: "10px 0",
                          }),
                        },
                      }}
                    >
                      <img
                        src={require("../../assets/icons/attachment.svg")}
                        height="20px"
                        width="20px"
                        alt={"Załącznik"}
                      />
                      <LabelMedium
                        overrides={{
                          Block: {
                            style: () => ({
                              color: theme.colors.primary,
                              margin: "0px 0px 0px 20px",
                              textDecoration: "underline",
                              fontSize: fontSizeMultiplier! * 11 + "px",
                              letterSpacing: ".1px",
                              minWidth: "100px",
                              fontWeight: 400,
                            }),
                          },
                        }}
                      >
                        {attachment.displayText}
                      </LabelMedium>
                      <LabelMedium
                        overrides={{
                          Block: {
                            style: () => ({
                              color: theme.colors.accent50,
                              margin: "0px 0px 0px 60px",
                              fontSize: fontSizeMultiplier! * 11 + "px",
                              letterSpacing: ".1px",
                              fontWeight: 400,
                            }),
                          },
                        }}
                      >
                        {attachment.size}
                      </LabelMedium>

                      {attachment.status === 10 ? (
                        <Tag
                          closeable={false}
                          overrides={{
                            Root: {
                              style: () => ({
                                backgroundColor: "#fad2ce",
                                borderRadius: "0px",
                                fontSize: fontSizeMultiplier! * 10 + "px",
                                lineHeight: "10px",
                                fontWeight: 400,
                                height: "auto",
                                margin: "0px 0px 0px 15px",
                                textAlign: "left",
                                padding: "5px",
                                minWidth: "70px",
                                color: theme.colors.borderInverseOpaque,
                                textDecoration: "line-through",
                              }),
                            },
                          }}
                        >
                          {translation?.comparision?.remove}
                        </Tag>
                      ) : null}
                      {attachment.status === 30 ? (
                        <Tag
                          closeable={false}
                          overrides={{
                            Root: {
                              style: () => ({
                                backgroundColor: "#d1f1d6",
                                borderRadius: "0px",
                                fontSize: fontSizeMultiplier! * 10 + "px",
                                lineHeight: "10px",
                                fontWeight: 400,
                                height: "auto",
                                margin: "0px 0px 0px 15px",
                                textAlign: "left",
                                padding: "5px",
                                minWidth: "70px",
                                color: "#707070",
                              }),
                            },
                          }}
                        >
                          {translation?.comparision?.add}
                        </Tag>
                      ) : null}
                    </Block>
                  ))}
                </Block>
              ) : null}
            </Block>
          </Block>
        </Modal>

        <Modal
          size={
            !isServer() && window.innerWidth > 900 ? SIZEM.default : SIZEM.full
          }
          onClose={() => setIsQuickLookModalOpened(false)}
          isOpen={isQuickLookModalOpened}
          overrides={{
            Backdrop: {
              style: () => ({
                backgroundColor: "rgba(255,255,255,.7)",
              }),
            },
            Dialog: {
              style: () => ({
                backgroundColor: theme.colors.backgroundSecondary,
                minWidth: "55vw",
                margin: "0px",
                borderRadius: "5px",
                boxShadow:
                  "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  padding: "30px 50px 0px",
                }),
              },
            }}
          >
            <H3
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.primary,
                    margin: "0px 0px 10px",
                    fontSize: fontSizeMultiplier! * 16 + "px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }),
                },
              }}
            >
              Podgląd wersji
            </H3>
          </Block>

          <Block
            overrides={{
              Block: {
                style: () => ({
                  padding: "20px 50px 30px",
                }),
              },
            }}
          >
            <div
              id="articleContentQuickLookModal"
              ref={articleContentQuickLookModalRef}
            >
              <Block
                overrides={{
                  Block: {
                    style: () => ({
                      borderBottom: "1px solid " + theme.colors.backgroundInv,
                    }),
                  },
                }}
              >
                <H3
                  overrides={{
                    Block: {
                      style: ({ $theme }) => ({
                        color: $theme.colors.primary,
                        margin: "0px 0px 10px",
                        fontSize: fontSizeMultiplier! * 16 + "px",
                        lineHeight: "18px",
                        letterSpacing: "1px",
                        fontWeight: 500,
                      }),
                    },
                  }}
                >
                  {articleVersionQueryData?.contentData?.item?.title}
                </H3>
                <Label4
                  overrides={{
                    Block: {
                      style: () => ({
                        color: theme.colors.accent50,
                        margin: "0px 20px 20px 0",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "12px",
                        letterSpacing: "0.25px",
                        fontWeight: 400,
                        display: "inline-block",
                      }),
                    },
                  }}
                >
                  {articleVersionQueryData?.contentData?.item?.publishedDate}
                </Label4>
              </Block>
              <Block
                overrides={{
                  Block: {
                    style: () => ({
                      padding: "0px",
                    }),
                  },
                }}
              >
                <Paragraph4
                  as="div"
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "20px 0px 0px",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "18px",
                        color: theme.colors.accent50,
                      }),
                    },
                  }}
                >
                  {articleVersionQueryData?.contentData?.item?.content &&
                    ReactHtmlParser(
                      articleVersionQueryData?.contentData?.item?.content
                    )}
                </Paragraph4>
              </Block>
            </div>
            <Block>
              {articleVersionQueryData?.contentData?.item?.attachments &&
                articleVersionQueryData?.contentData?.item?.attachments.length >
                  0 && (
                  <Block>
                    <Label3
                      overrides={{
                        Block: {
                          style: () => ({
                            marginTop: "30px",
                            color: theme.colors.accent50,
                            padding: "0px 0px 8px 0",
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "20px",
                            letterSpacing: "1px",
                            fontWeight: 500,
                            textTransform: "uppercase",
                            borderBottom:
                              "1px solid " + theme.colors.backgroundInv,
                          }),
                        },
                      }}
                    >
                      {translation?.listNames?.attachments}
                    </Label3>

                    {articleVersionQueryData?.contentData?.item?.attachments.map(
                      (attachment: any, index: any) => {
                        return (
                          <Block
                            key={index}
                            as={(props) => (
                              <a href={attachment.url} {...props}>
                                {props.children}
                              </a>
                            )}
                            overrides={{
                              Block: {
                                props: {
                                  title: attachment.title,
                                },
                                style: () => ({
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  textDecoration: "none",
                                  alignItems: "center",
                                  borderBottom:
                                    "1px solid " + theme.colors.backgroundInv,
                                  padding: "10px 0",
                                }),
                              },
                            }}
                          >
                            <img
                              src={
                                (isServer() ? "/" : "") +
                                require("../../assets/icons/attachment.svg")
                              }
                              alt="Link"
                              height="20px"
                              width="20px"
                            />
                            <LabelMedium
                              overrides={{
                                Block: {
                                  style: () => ({
                                    color: theme.colors.primary,
                                    margin: "0px 0px 0px 20px",
                                    textDecoration: "underline",
                                    fontSize: fontSizeMultiplier! * 11 + "px",
                                    letterSpacing: ".1px",
                                    minWidth: "100px",
                                    fontWeight: 400,
                                  }),
                                },
                              }}
                            >
                              {attachment.displayText}
                            </LabelMedium>
                            <LabelMedium
                              overrides={{
                                Block: {
                                  style: () => ({
                                    color: theme.colors.accent50,
                                    margin: "0px 0px 0px 60px",
                                    fontSize: fontSizeMultiplier! * 11 + "px",
                                    letterSpacing: ".1px",
                                    fontWeight: 400,
                                  }),
                                },
                              }}
                            >
                              {attachment.size}
                            </LabelMedium>
                          </Block>
                        );
                      }
                    )}
                  </Block>
                )}

              {articleVersionQueryData?.contentData?.item?.links &&
                articleVersionQueryData?.contentData?.item?.links.length >
                  0 && (
                  <Block>
                    <Label3
                      overrides={{
                        Block: {
                          style: () => ({
                            marginTop: "30px",
                            color: theme.colors.accent50,
                            padding: "0px 0px 8px 0",
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "20px",
                            letterSpacing: "1px",
                            fontWeight: 500,
                            textTransform: "uppercase",
                            borderBottom:
                              "1px solid " + theme.colors.backgroundInv,
                          }),
                        },
                      }}
                    >
                      {translation?.listNames?.links}
                    </Label3>

                    {articleVersionQueryData?.contentData?.item?.links.map(
                      (link: any, index: any) => {
                        return (
                          <Block
                            key={index}
                            as={(props) => (
                              <Link
                                to={`/${
                                  link.slug + (lang ? `?lang=${lang}` : "")
                                }`}
                                {...props}
                              >
                                {props.children}
                              </Link>
                            )}
                            overrides={{
                              Block: {
                                props: {
                                  title: link.title,
                                },
                                style: () => ({
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  textDecoration: "none",
                                  alignItems: "center",
                                  borderBottom:
                                    "1px solid " + theme.colors.backgroundInv,
                                  padding: "10px 0",
                                }),
                              },
                            }}
                          >
                            <img
                              src={
                                (isServer() ? "/" : "") +
                                require("../../assets/icons/link.svg")
                              }
                              alt="Link"
                              height="20px"
                              width="20px"
                            />
                            <LabelMedium
                              overrides={{
                                Block: {
                                  style: () => ({
                                    color: theme.colors.primary,
                                    margin: "0px 0px 0px 20px",
                                    textDecoration: "underline",
                                    fontSize: fontSizeMultiplier! * 11 + "px",
                                    letterSpacing: ".1px",
                                    minWidth: "100px",
                                    fontWeight: 400,
                                  }),
                                },
                              }}
                            >
                              {link.displayText}
                            </LabelMedium>
                          </Block>
                        );
                      }
                    )}
                  </Block>
                )}
            </Block>
          </Block>
          <Block
            justifyContent={[
              "flex-start",
              "flex-start",
              "flex-start",
              "space-between",
            ]}
            overrides={{
              Block: {
                style: () => ({
                  display: "flex",
                  flexDirection: "row",
                  padding: "0px 50px 0px",
                  flexWrap: "wrap",
                  backgroundColor: theme.colors.backgroundOverlayDark,
                }),
              },
            }}
          >
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }),
                },
              }}
            >
              <Button
                onClick={
                  isServer()
                    ? () => {
                        failWindowHandler(
                          setActionType,
                          setIsFailureModalOpen,
                          20
                        );
                      }
                    : () => handlePrintArticleContentQuickLookModal()
                }
                kind={KIND.tertiary}
                size={SIZE.compact}
                startEnhancer={() => (
                  <img
                    height="16px"
                    alt={translation?.footer?.printButton?.displayText}
                    src={
                      (isServer() ? "/" : "") +
                      require("../../assets/icons/print.svg")
                    }
                  />
                )}
                overrides={{
                  BaseButton: {
                    props: {
                      title: translation?.footer?.printButton?.title,
                    },
                    style: () => ({
                      color: theme.colors.primary,
                      margin: "0px 35px 0px 0px",
                      padding: "15px 0px",
                      textTransform: "uppercase",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: 400,
                      lineHeight: "11px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color:
                          theme.name !== "standard"
                            ? theme.colors.primary
                            : COLORS.ARTICLE_STANDARD,
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":active": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":visited": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                    }),
                  },
                }}
              >
                {translation?.footer?.printButton?.displayText}
              </Button>
              <Button
                onClick={() => startDownloadPDF(setIsModalClicked, saveToPDF)}
                kind={KIND.tertiary}
                size={SIZE.compact}
                startEnhancer={() => (
                  <img
                    height="16px"
                    alt={translation?.footer?.saveToPdfButton?.displayText}
                    src={
                      (isServer() ? "/" : "") +
                      require(`../../assets/icons/pdf-file-${theme.name}.svg`)
                    }
                  />
                )}
                overrides={{
                  BaseButton: {
                    props: {
                      title: translation?.footer?.saveToPdfButton?.title,
                    },
                    style: () => ({
                      color: theme.colors.primary,
                      margin: "0px 35px 0px 0px",
                      padding: "15px 0px",
                      textTransform: "uppercase",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: 400,
                      lineHeight: "11px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color:
                          theme.name !== "standard"
                            ? theme.colors.primary
                            : COLORS.ARTICLE_STANDARD,
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":active": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":visited": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                    }),
                  },
                }}
              >
                {translation?.footer?.saveToPdfButton?.displayText}
              </Button>
            </Block>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }),
                },
              }}
            >
              {articleVersionQueryData?.contentData?.item?.metrics && (
                <StatefulPopover
                  content={() => (
                    <Block
                      minWidth={["0px", "0px", "350px", "350px"]}
                      overrides={{
                        Block: {
                          style: () => ({
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: theme.colors.backgroundSecondary,
                            padding: "10px 30px",
                            boxShadow:
                              "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                            color: theme.colors.accent50,
                          }),
                        },
                      }}
                    >
                      {articleVersionQueryData?.contentData?.item?.metrics.map(
                        (metric: any, index: any) => {
                          return (
                            <Block
                              key={index}
                              overrides={{
                                Block: {
                                  style: () => ({
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                    borderBottom:
                                      index !==
                                      articleVersionQueryData?.contentData?.item
                                        ?.metrics.length -
                                        1
                                        ? "1px solid " +
                                          theme.colors.backgroundInv
                                        : "none",
                                  }),
                                },
                              }}
                            >
                              <Block
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      padding: "10px 0px",
                                      width: "33%",
                                      boxSizing: "border-box",
                                    }),
                                  },
                                }}
                              >
                                <Label4
                                  overrides={{
                                    Block: {
                                      style: () => ({
                                        fontSize:
                                          fontSizeMultiplier! * 10 + "px",
                                        color: theme.colors.contentTertiary,
                                        lineHeight: "12px",
                                        fontWeight: 500,
                                        letterSpacing: ".25px",
                                      }),
                                    },
                                  }}
                                >
                                  {metric.key}
                                </Label4>
                              </Block>
                              <Block
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      padding: "10px 0px",
                                      width: "33%",
                                      boxSizing: "border-box",
                                      display: "flex",
                                      alignItems: "start",
                                    }),
                                  },
                                }}
                              >
                                <MetricValue
                                  {...{
                                    email: metric.email,
                                    value: metric.value,
                                  }}
                                />
                              </Block>
                              <Block
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      padding: "10px 0px",
                                      width: "33%",
                                      boxSizing: "border-box",
                                    }),
                                  },
                                }}
                              >
                                <Label4
                                  overrides={{
                                    Block: {
                                      style: () => ({
                                        fontSize:
                                          fontSizeMultiplier! * 10 + "px",
                                        color: theme.colors.contentTertiary,
                                        lineHeight: "12px",
                                        fontWeight: 400,
                                        letterSpacing: ".25px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }),
                                    },
                                  }}
                                >
                                  {metric.date}
                                </Label4>
                              </Block>
                            </Block>
                          );
                        }
                      )}
                    </Block>
                  )}
                  placement={PLACEMENT.bottomRight}
                  focusLock
                  returnFocus
                  autoFocus
                  overrides={{
                    Body: {
                      style: () => ({
                        left:
                          !isServer() && window.innerWidth > 900
                            ? "50px"
                            : "1%",
                        right:
                          !isServer() && window.innerWidth > 900
                            ? "unset"
                            : "5%",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }),
                    },
                  }}
                >
                  <Button
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                    startEnhancer={() => (
                      <img
                        height="16px"
                        alt={translation?.footer?.metricButton?.displayText}
                        src={
                          (isServer() ? "/" : "") +
                          require("../../assets/icons/info.svg")
                        }
                      />
                    )}
                    overrides={{
                      BaseButton: {
                        props: {
                          title: translation?.footer?.metricButton?.title,
                        },
                        style: () => ({
                          color: theme.colors.primary,
                          padding: "15px 0px",
                          margin: "0px 0px 0px 0px",
                          textTransform: "uppercase",
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          fontFamily: "Rubik, sans-serif",
                          fontWeight: 400,
                          lineHeight: "11px",
                          ":hover": {
                            backgroundColor: "transparent",
                            color:
                              theme.name !== "standard"
                                ? theme.colors.primary
                                : COLORS.ARTICLE_STANDARD,
                          },
                          ":focus": {
                            backgroundColor: "transparent",
                            color: theme.colors.primary,
                          },
                          ":active": {
                            backgroundColor: "transparent",
                            color: theme.colors.primary,
                          },
                          ":visited": {
                            backgroundColor: "transparent",
                            color: theme.colors.primary,
                          },
                        }),
                      },
                    }}
                  >
                    {translation?.footer?.metricButton?.displayText}
                  </Button>
                </StatefulPopover>
              )}
            </Block>
          </Block>
        </Modal>
      </Wrapper>
    </Fragment>
  );
};

export default Article;
