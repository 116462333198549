import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  font-family: Rubik, sans-serif;
`;

export const DescriptionStyle = css`
  color ${({ color }) => color};
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const JobPosition = styled.span<{ color: string }>`
  ${DescriptionStyle};
  margin-top: 15px;
`;

export const FullName = styled.span`
  ${DescriptionStyle};
  font-weight: 500;
`;

export const Details = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  height: auto;
`;
