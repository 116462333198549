import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};

  margin: 0 0 20px;
  border-radius: 5px;
  box-shadow: 1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2);

  padding: 30px 50px;

  @media screen and (max-width: 600px) {
    padding: 30px 25px;
  }
`;

export const Title = styled.h3<{
  fontSize: number;
  borderColor: string;
}>`
  margin: 0 0 10px;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
  }
`;
