import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";

import queryString from "query-string";
import { useStyletron } from "baseui";
import { isServer } from "@helpers/ssr";

import { HorizontalMenuItem } from "./NavItem";

import { useAccessibility } from "@contexts/AccessibilityContext";
import { ScrollPositionContext } from "@contexts/ScrollProvider";

import { NMenu } from "@namespace/Menu";

import { historyPush } from "../utils";

import { HomeButton, MenuItems } from "./styles";

type THorizontalMenuProps = {
  horizontalMenu: NMenu.THorizontalMenu;
  isMobile: boolean;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const HorizontalMenu = ({
  horizontalMenu,
  isMobile,
  setIsMenuOpen,
}: THorizontalMenuProps) => {
  const [, theme] = useStyletron();

  const { push } = useHistory();

  const { fontSizeMultiplier } = useAccessibility();
  const { search, pathname } = useLocation();
  const { lang } = queryString.parse(search);

  const { changeScrollPosition, changeArticleId } = useContext(
    ScrollPositionContext
  );

  const {
    colors: {
      primary,
      backgroundLightAccent,
      buttonTertiaryHover,
      contentInversePrimary,
    },
    name,
  } = theme;

  return (
    <MenuItems {...{ isMobile }}>
      {!isMobile ? (
        <li>
          <HomeButton
            {...{
              active: pathname === "/",
              primary,
              backgroundLightAccent,
              buttonTertiaryHover,
              contentInversePrimary,
              title: horizontalMenu.translation?.mainPageButton?.title,
              onClick: () => {
                historyPush(push, "/", lang);
                changeScrollPosition(0, "article");
                changeArticleId(0, "article");
              },
              id: "horizontal-menu",
            }}
          >
            <img
              height="20px"
              width="20px"
              alt={horizontalMenu?.translation?.mainPageButton?.title}
              src={
                (isServer() ? "/" : "") +
                require(`../../../assets/icons/home-${name}.svg`)
              }
            />
          </HomeButton>
        </li>
      ) : null}
      {horizontalMenu.items.map((item, index, arr) => (
        <HorizontalMenuItem
          key={`${item.title}}-${index}`}
          {...{
            item,
            primary,
            backgroundLightAccent,
            buttonTertiaryHover,
            contentInversePrimary,
            fontSizeMultiplier,
            lang,
            translation: horizontalMenu.translation,
            isMobile,
            setIsMenuOpen,
            count: arr.length,
            index,
          }}
        />
      ))}
    </MenuItems>
  );
};
