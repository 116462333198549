import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import queryString from "query-string";

import { isServer } from "@helpers/ssr";
import { useStyletron } from "baseui";
import { useAccessibility } from "@contexts/AccessibilityContext";

import { NHome } from "@namespace/Home";

import { hexToRgb, replaceColors } from "@utils/svg-convert";
import { processSlug } from "@utils/process-slug";

import { SvgWrapper, Text, TileLink, Image, ImageWrapper } from "./styles";

type TTileProps = {
  tile: NHome.ITile;
  isTwoElements: boolean;
};

export const Tile = ({ tile, isTwoElements }: TTileProps) => {
  const [, theme] = useStyletron();

  const { search } = useLocation();

  const { lang } = queryString.parse(search);

  const {
    colors: { primary, primaryB, backgroundSecondary, backgroundPrimary },
    name,
  } = theme;

  const isContrast = name !== "standard";

  const { slug, url, target, icon, background, title } = tile;

  const [currentSvg, setCurrentSvg] = useState<string | null>(null);

  const { fontSizeMultiplier } = useAccessibility();

  const getSvg = useCallback(() => {
    const src =
      (isServer() ? "/" : "") +
      require(`../../../assets/icons/${tile.icon}.svg`);

    fetch(src)
      .then((res) => res.text())
      .then((res) => {
        if (res) {
          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(res, "image/svg+xml");
          const groups = svgDoc?.querySelectorAll("g");

          groups.forEach((group) => {
            let uuid = uuidv4();
            group.setAttribute("id", uuid);
          });

          const newSvg = new XMLSerializer().serializeToString(svgDoc);
          setCurrentSvg(newSvg);
        }
      });
  }, []);

  useEffect(() => {
    if (icon) {
      getSvg();
    }
  }, [icon, getSvg]);

  return (
    <TileLink
      {...{
        slug: processSlug(slug, lang),
        url,
        target: target === 20 ? "_blank" : "",
        isTwoElements,
        backgroundSecondary,
        title,
      }}
    >
      <Text {...{ color: primaryB, isTwoElements, fontSizeMultiplier }}>
        {tile.displayText}
      </Text>
      {icon ? (
        <>
          {currentSvg ? (
            <SvgWrapper
              {...{ isTwoElements }}
              dangerouslySetInnerHTML={{
                __html: replaceColors(currentSvg, hexToRgb(primary)),
              }}
            />
          ) : null}
        </>
      ) : (
        <ImageWrapper bgColor={isContrast ? "#fff" : "transparent"}>
          <Image
            alt={background?.title}
            src={background?.url!}
            width={isTwoElements ? "104" : "55px"}
            height={isTwoElements ? "78px" : "41px"}
          />
        </ImageWrapper>
      )}
    </TileLink>
  );
};
