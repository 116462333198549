import styled from "styled-components";

import { Link } from "react-router-dom";

import { MEDIA_QUERIES } from "@utils/constants";

export const Container = styled.div<{ length: number }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  gap: ${({ length }) => (length === 2 ? "20px" : "10px")};
  width: calc(36% - 20px);

  @media (max-width: ${MEDIA_QUERIES.lg}) {
    width: 100%;
    gap: 20px;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
`;
