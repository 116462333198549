import StandardTheme from "./StandardTheme";
import GreenTheme from "./GreenTheme";
import DarkGreenTheme from "./DarkGreenTheme";
import RedTheme from "./RedTheme";
import Contrast1Theme from "./Contrast1Theme";
import Contrast2Theme from "./Contrast2Theme";
import Contrast3Theme from "./Contrast3Theme";
import Contrast4Theme from "./Contrast4Theme";
import Contrast5Theme from "./Contrast5Theme";
import Contrast6Theme from "./Contrast6Theme";

import { Theme } from "baseui/theme";

interface IThemeSet {
  [key: string]: Theme
}

export const THEME_SET: IThemeSet = {
  'standard': StandardTheme,
  'green': GreenTheme,
  'darkgreen': DarkGreenTheme,
  'red': RedTheme,
  'contrast': Contrast1Theme,
  'contrast2': Contrast2Theme,
  'contrast3': Contrast3Theme,
  'contrast4': Contrast4Theme,
  'contrast5': Contrast5Theme,
  'contrast6': Contrast6Theme
}