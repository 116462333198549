import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation } from "react-router";

import queryString from "query-string";
import { useStyletron } from "baseui";
import { Spinner } from "baseui/spinner";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { VerticalMenu } from "..";

import { ScrollPositionContext } from "@contexts/ScrollProvider";
import { useAccessibility } from "@contexts/AccessibilityContext";
import { useData } from "@contexts/DataContext";

import { isServer } from "@helpers/ssr";
import { rewriteUrl } from "@helpers/client-axios";

import { NMenu } from "@namespace/Menu";

import { IconWrapper, Inner, MenuItem, MenuLinkItem } from "./styles";
import { processSlug } from "@utils/process-slug";

type TNavItemProps = {
  firstItemId?: "vertical-menu-first-item" | "vertical-menu-mobile-first-item";
  index: number;
  item: NMenu.IVerticalMenuItem;
  currentLevel: number;
  breadcrumbs: NMenu.IBreadcrumb[];
  translation: NMenu.ITranslation;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavItem = ({
  firstItemId,
  index,
  item,
  currentLevel,
  breadcrumbs,
  translation,
  setIsMenuOpen,
}: TNavItemProps): JSX.Element => {
  const [children, setChildren] = useState<NMenu.IVerticalMenuItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(item.keepExpanded || false);
  const [isFocused, setIsFocused] = useState(false);

  const { hasChildren, displayText, title, target, url, slug } = item;

  const { changeScrollPosition, changeArticleId } = useContext(
    ScrollPositionContext
  );

  const { search, pathname } = useLocation();

  const { lang } = queryString.parse(search);

  const [, queryChildren] = useData(
    {
      url: `${
        isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
      }/menus/20${lang ? `?lang=${lang}` : ""}`,
      method: "GET",
    },
    {
      manual: true,
      useCache: false,
    }
  );

  const isInitialOpen = breadcrumbs.some((item) => item.slug === slug);

  const [, theme] = useStyletron();

  const { fontSizeMultiplier } = useAccessibility();

  const {
    colors: {
      primary,
      backgroundLightAccent,
      buttonTertiaryHover,
      contentInversePrimary,
    },
  } = theme;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    if (!loading) {
      setIsOpen((prev) => !prev);
    }
  };

  const navigate = (): void => {
    changeScrollPosition(0, "articleLink");
    changeArticleId("", "articleLink");

    setIsMenuOpen && setIsMenuOpen(false);
  };

  const handleKey = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (
      (!!item.elementId && isOpen && hasChildren && !children.length) ||
      isFocused
    ) {
      setLoading(true);
      queryChildren({
        params: { "parentIds[0]": item.elementId },
      }).then((res) => {
        const items = res?.data ?? [];

        setChildren(items);
      });

      setLoading(false);
      setIsFocused(false);
    }
  }, [item.elementId, isOpen, queryChildren, isFocused]);

  useEffect(() => {
    if (isInitialOpen) {
      setIsOpen(true);
    }
  }, [isInitialOpen]);

  return (
    <>
      <MenuItem
        {...{
          active: pathname === `/${slug}`,
          currentLevel,
          primary,
          backgroundLightAccent,
          buttonTertiaryHover,
          contentInversePrimary,
          onClick: navigate,
          tabIndex: 0,
          role: "tab",
          id:
            currentLevel === 1 && index === 0
              ? firstItemId || item.elementId
              : item.elementId,
          onKeyDown: (e) => handleKey(e),
        }}
      >
        <Inner
          {...{
            contentInversePrimary,
            currentLevel,
          }}
        >
          <MenuLinkItem
            {...{
              title,
              currentLevel,
              fontSizeMultiplier,
              primary,
              backgroundLightAccent,
              buttonTertiaryHover,
              contentInversePrimary,
              url,
              slug: processSlug(slug, lang),
              target: target === 20 ? "_blank" : "_self",
            }}
          >
            {displayText}
          </MenuLinkItem>
          {hasChildren ? (
            <IconWrapper
              {...{
                contentInversePrimary,
                onClick: handleClick,
                // tabIndex: 0,
                title:
                  isOpen && !!children.length
                    ? translation.collapse
                    : translation.expand,
              }}
            >
              {loading ? (
                <Spinner size="sm" aria-hidden="true" color={primary} />
              ) : (
                <FontAwesomeIcon
                  icon={
                    isOpen && !!children.length ? faChevronUp : faChevronDown
                  }
                  color={primary}
                />
              )}
            </IconWrapper>
          ) : null}
        </Inner>
        <VerticalMenu
          {...{
            items: children,
            level: currentLevel + 1,
            isOpen,
            breadcrumbs,
            translation,
            setIsMenuOpen,
          }}
        />
      </MenuItem>
    </>
  );
};
