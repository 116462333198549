import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useStyletron } from "baseui";
import { Drawer } from "baseui/drawer";

import ReactHtmlParser from "react-html-parser";
import queryString from "query-string";

import SideBanner from "../SideBanner/SideBanner";
import { HorizontalMenu } from "./HorizontalMenu";
import { VerticalMenu } from "./VerticalMenu";
import { SearchInput } from "./SearchInput";
import { CustomLink } from "shared/CustomLink";

import { useAccessibility } from "../../contexts/AccessibilityContext";
import { isServer } from "../../helpers/ssr";

import { NInit } from "@namespace/Init";

import {
  Banner,
  Bip,
  BipImage,
  Hamburger,
  HamburgerImage,
  HomeButton,
  ImageSubTitle,
  ImageTitle,
  Inner,
  BrandArea,
  Logo,
  LogoInner,
  LogoWrapper,
  MobileSearchInputWrapper,
  RightHeaderlogo,
  RightHeaderWrapper,
  Row,
  SearchInputWrapper,
  Title,
  TitlesWrapper,
  Wrapper,
} from "./styles";

type HProps = {
  data: any;
  horizontalMenu: any;
  verticalMenu: any;
  banners: NInit.IBanner[];
  appSettings: NInit.IAppSettings;
};

const { parse, stringify } = queryString;

const Header: React.FC<HProps> = ({
  data,
  horizontalMenu,
  verticalMenu,
  banners,
  appSettings,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const history = useHistory();

  const { search } = useLocation();
  const { lang } = parse(search);

  const [css, theme] = useStyletron();

  const { fontSizeMultiplier } = useAccessibility();

  const handleRedirect = () => {
    history.push({
      pathname: "/",
      search: stringify({ lang: lang ?? "PL" }),
    });
  };

  const {
    colors: {
      backgroundSecondary,
      primary,
      backgroundPrimary,
      accent,
      backgroundLightAccent,
      contentInversePrimary,
    },
    name,
  } = theme;

  const isImageBackground = data?.type === 20;

  const getBipWrapperBackgroundColor = () => {
    if (isImageBackground) {
      if (!name.includes("contrast")) {
        return "transparent";
      }

      if (name === "contrast5" || name === "contrast4") {
        return accent;
      }

      return primary;
    } else {
      if (
        name === "contrast1" ||
        name === "contrast2" ||
        name === "contrast3" ||
        name === "contrast6"
      ) {
        return primary;
      }

      return "transparent";
    }
  };

  const handleShadowTitleColor = () => {
    if (!name.includes("contrast")) {
      return "#222";
    }

    if (name === "contrast4" || name === "contrast5" || name === "contrast6") {
      return primary;
    }

    return backgroundSecondary;
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Wrapper>
        <Inner>
          <BrandArea
            {...{
              isbackground: data?.type === 20,
              backgroundtype: data.type,
              backgroundImage: `url(${data?.background?.url})`,
              backgroundgradient: backgroundPrimary,
            }}
          >
            <Row {...{ isImageBackground }}>
              <Bip
                {...{
                  url: "https://www.gov.pl/web/bip",
                  bgColor: getBipWrapperBackgroundColor(),
                  title: "BIP",
                }}
              >
                <BipImage
                  src={require("../../assets/example/bip_bip_dlugie.png")}
                  alt="BIP"
                  width={171}
                  height={38}
                />
              </Bip>
              {isImageBackground ? (
                <TitlesWrapper>
                  <ImageTitle
                    {...{
                      color: accent,
                      shadowColor: handleShadowTitleColor(),
                      fontSize: fontSizeMultiplier! * 40,
                    }}
                  >
                    Biuletyn Informacji Publicznej
                  </ImageTitle>
                  <ImageSubTitle
                    {...{
                      color: accent,
                      shadowColor: handleShadowTitleColor(),
                    }}
                  >
                    {data && ReactHtmlParser(data?.text)}
                  </ImageSubTitle>
                </TitlesWrapper>
              ) : null}
              <RightHeaderWrapper>
                {data?.type === 10 ? (
                  <SearchInput {...{ data, id: "search-input", appSettings }} />
                ) : (
                  <RightHeaderlogo
                    {...{
                      slug: `${lang ? "?" + stringify({ lang }) : ""}`,
                      title: data?.logo.title,
                    }}
                  >
                    <img
                      src={data?.logo.url}
                      alt={data?.logo.title}
                      width={74}
                      height={74}
                    />
                  </RightHeaderlogo>
                )}
              </RightHeaderWrapper>
              <LogoWrapper>
                <LogoInner
                  {...{
                    slug: `${lang ? "?" + stringify({ lang }) : ""}`,
                    title: data?.logo.title,
                  }}
                >
                  <Logo
                    src={data?.logo.url}
                    alt={data?.logo.title}
                    width={74}
                    height={74}
                  />
                </LogoInner>
              </LogoWrapper>
              <Hamburger onClick={() => setIsMenuOpen(true)} id={"hamburger"}>
                <HamburgerImage
                  height="36px"
                  width="42px"
                  alt="Menu"
                  style={{ cursor: "pointer" }}
                  src={
                    (isServer() ? "/" : "") +
                    require(`../../assets/icons/hamburger-${name}.svg`)
                  }
                />
              </Hamburger>
            </Row>
            {windowWidth <= 1080 ? (
              <MobileSearchInputWrapper {...{ isImageBackground }}>
                <HomeButton
                  {...{
                    title: horizontalMenu?.translation?.mainPageButton?.title,
                    isContrast: name !== "standard",
                    bgColorHover: contentInversePrimary,
                    borderColor: primary,
                    isImageBackground,
                    onClick: () => handleRedirect(),
                  }}
                >
                  <img
                    height="20px"
                    width="20px"
                    alt={horizontalMenu?.translation?.mainPageButton?.title}
                    src={
                      (isServer() ? "/" : "") +
                      require(`../../assets/icons/home-${theme.name}.svg`)
                    }
                  />
                </HomeButton>
                <SearchInput
                  {...{ data, id: "search-input-mobile", appSettings }}
                />
              </MobileSearchInputWrapper>
            ) : null}
            {data?.type === 10 ? (
              <Banner bgColor={primary} isGradient={`${data.useGradient}`}>
                <Title
                  {...{
                    color: backgroundLightAccent,
                    fontSize: fontSizeMultiplier! * 28,
                  }}
                >
                  {data &&
                    ReactHtmlParser(
                      !name.includes("contrast")
                        ? data?.text
                        : data?.text.replace(
                            /color:.*?;/g,
                            `style=\"color: ${accent};\"`
                          )
                    )}
                </Title>
                <CustomLink slug={`${lang ? "?" + stringify({ lang }) : ""}`}>
                  <img src={data?.logo.url} alt={data?.logo.title} />
                </CustomLink>
              </Banner>
            ) : (
              <>
                <SearchInputWrapper>
                  <SearchInput {...{ data, id: "search-input", appSettings }} />
                </SearchInputWrapper>
              </>
            )}
          </BrandArea>

          <HorizontalMenu {...{ horizontalMenu, isMobile: false }} />
        </Inner>
      </Wrapper>
      <Drawer
        isOpen={isMenuOpen}
        autoFocus
        onClose={() => setIsMenuOpen(false)}
        overrides={{
          Backdrop: {
            style: () => ({
              backgroundColor: "rgba(255,255,255,.7)",
            }),
          },
          DrawerContainer: {
            style: () => ({
              paddingTop: "50px",
              paddingBottom: "50px",
            }),
          },
          DrawerBody: {
            style: () => {
              return {
                marginTop: "0px",
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "40px",
              };
            },
          },
        }}
      >
        <HorizontalMenu
          {...{ horizontalMenu, isMobile: true, setIsMenuOpen }}
        />
        <VerticalMenu
          {...{
            items: verticalMenu.items,
            level: 1,
            isOpen: true,
            translation: verticalMenu.translation,
            breadcrumbs: [],
            setIsMenuOpen,
          }}
        />
        {(banners || []).map((banner, index) => (
          <SideBanner key={index} {...{ banner }} />
        ))}
        <div className={css({ height: "50px", width: "100%" })}></div>
      </Drawer>
    </>
  );
};

export default Header;
