import React from "react";

import { Block } from "baseui/block";
import { ParagraphXSmall, H1, H2 } from "baseui/typography";
import { useStyletron } from "baseui";
import { useLocation, Link } from "react-router-dom";
import queryString from "query-string";

import SvgIcon from "./svgIcon";

type SProps = {
  data: any;
  errorTranslation: any;
};

const Page404: React.FC<SProps> = (props) => {
  const { errorTranslation } = props;
  const [, theme] = useStyletron();
  const { search } = useLocation();
  const { lang } = queryString.parse(search);

  const {
    colors: { backgroundSecondary, primary },
  } = theme;

  return (
    <Block
      overrides={{
        Block: {
          style: () => ({
            backgroundColor: backgroundSecondary,
            margin: "0 0 20px",
            borderRadius: "5px",
            boxShadow:
              "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
          }),
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: () => ({
              padding: "30px 50px 40px",
            }),
          },
        }}
      >
        <Block
          overrides={{
            Block: {
              style: () => ({
                display: "flex",
                alignItems: "flex-start",
                flexWrap: "wrap",
              }),
            },
          }}
        >
          <Block width={["100%", "100%", "40%", "40%"]}>
            <SvgIcon
              {...{
                primary,
                backgroundSecondary,
                tileBgColor: theme.name.includes("contrast")
                  ? primary
                  : "#E6E6E6",
              }}
            />
          </Block>
          <Block
            width={["100%", "100%", "calc(60% - 20px)", "calc(60% - 20px)"]}
            overrides={{
              Block: {
                style: () => ({
                  marginLeft: "20px",
                  paddingBottom: "20px",
                  borderBottom: `7px solid ${primary}`,
                }),
              },
            }}
          >
            <H1
              overrides={{
                Block: {
                  style: () => ({
                    fontSize: "60px",
                    color: primary,
                    margin: 0,
                    letterSpacing: "3px",
                  }),
                },
              }}
            >
              404
            </H1>
            <H2
              overrides={{
                Block: {
                  style: () => ({
                    fontSize: "40px",
                    color: theme.name.includes("contrast")
                      ? primary
                      : "#3f3d56",
                    letterSpacing: "5px",
                    fontWeight: 300,
                    marginTop: "30px",
                    marginBottom: "20px",
                  }),
                },
              }}
            >
              {errorTranslation?.errorMessage}
            </H2>
            <ParagraphXSmall
              overrides={{
                Block: {
                  style: () => ({
                    fontSize: "12px",
                    color: theme.name.includes("contrast")
                      ? primary
                      : "#888888",
                    textTransform: "uppercase",
                  }),
                },
              }}
            >
              {errorTranslation?.innerErrorMessage}
            </ParagraphXSmall>
            <ParagraphXSmall
              overrides={{
                Block: {
                  style: () => ({
                    fontSize: "12px",
                    color: theme.name.includes("contrast")
                      ? primary
                      : "#888888",
                    textTransform: "uppercase",
                  }),
                },
              }}
            >
              <Link
                {...{
                  to: `/${lang ? `?lang=${lang}` : ""}`,
                  title: errorTranslation?.backToMain?.title,
                  overrides: {
                    Link: {
                      style: () => ({
                        fontSize: "12px",
                        color: theme.name.includes("contrast")
                          ? primary
                          : "#888888",
                        textTransform: "uppercase",
                      }),
                    },
                  },
                }}
              >
                {errorTranslation?.backToMain?.displayText}
              </Link>
            </ParagraphXSmall>
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default Page404;
