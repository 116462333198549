import React, { ReactElement, useEffect, useRef, useState } from "react";

import { Text, StyledTooltip } from "./styles";
import { CustomLink } from "shared/CustomLink";

type TooltipProps = {
  children: HTMLElement;
  id: string;
  text: string;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
};

export const Tooltip = ({ children, ...props }: TooltipProps): ReactElement => {
  const { id, text, textColor, backgroundColor, borderColor } = props;
  const ref = useRef<HTMLElement>(null);

  const [isOverflown, setIsOverflown] = useState(false);

  const scrollWidth = ref.current?.scrollWidth;
  const clientWidth = ref.current?.clientWidth;

  useEffect(() => {
    const element = ref.current!;

    setIsOverflown(element?.scrollWidth > element?.clientWidth);
  }, [clientWidth, scrollWidth]);

  return (
    <>
      <Text
        ref={ref}
        {...props}
        data-tip
        data-for={`${id}-tooltip`}
        isOverflown={isOverflown}
      >
        {children}
      </Text>
      <StyledTooltip
        {...{
          id: `${id}-tooltip`,
          effect: "solid",
          multilne: true,
          disable: !isOverflown,
          textColor: `${textColor}` || "#fff",
          backgroundColor: `${backgroundColor}` || "#000",
          borderColor: `${borderColor}` || "#000",
          arrowColor: `${backgroundColor}` || "#000",
        }}
      >
        {text}
      </StyledTooltip>
    </>
  );
};
