import React from "react";

import { useLocation } from "react-router";

import queryString from "query-string";

import { NMenu } from "@namespace/Menu";

import { processSlug } from "@utils/process-slug";

import { CategoryItem, CategoryLink } from "./styles";

interface ICategoryProps {
  category: NMenu.IHorizontalMenuItem;
  primary: string;
  backgroundLightAccent: string;
  fontSizeMultiplier?: number | null;
  contentInversePrimary: string;
  isMobile: boolean;
  navigate: () => void;
}

export const Category = ({
  category,
  fontSizeMultiplier,
  primary,
  backgroundLightAccent,
  contentInversePrimary,
  isMobile,
  navigate,
}: ICategoryProps) => {
  const { displayText, slug, url, target, title } = category;

  const { search } = useLocation();

  const { lang } = queryString.parse(search);

  return (
    <CategoryItem
      {...{
        fontSizeMultiplier,
        category,
        primary,
        backgroundLightAccent,
        contentInversePrimary,
        title,
        isMobile,
        onClick: (event) => {
          event.stopPropagation();
          navigate();
        },
      }}
    >
      <CategoryLink
        {...{
          title,
          url,
          slug: processSlug(slug, lang),
          target: target === 20 ? "_blank" : "_self",
        }}
      >
        {displayText}
      </CategoryLink>
    </CategoryItem>
  );
};
