import React from "react";
import { useStyletron } from "baseui";

import { useAccessibility } from "@contexts/AccessibilityContext";

import { CustomLink } from "shared/CustomLink";

import { getPublishedDate } from "./helpers";

import { processSlug } from "@utils/process-slug";

import {
  NavList,
  NavItem,
  Date,
  LinkWrapper,
  Index,
  LinkInner,
} from "./styles";

interface NavItemsProps {
  items: any[]; //temporary
  lang: string | string[] | null;
  pageSize: number;
  articleLinkId: string;
  handleChangeArticleLinkId?: (id: string) => void;
}

export const NavItems = ({
  items,
  lang,
  pageSize,
  articleLinkId,
  handleChangeArticleLinkId,
}: NavItemsProps) => {
  const [, theme] = useStyletron();

  const {
    colors: { primary, contentInverseTertiary, buttonTertiaryHover },
  } = theme;

  const { fontSizeMultiplier } = useAccessibility();

  return (
    <NavList
      {...{
        fontSize: fontSizeMultiplier! * 12,
        color: contentInverseTertiary,
      }}
    >
      {items.map(
        ({ displayText, slug, url, target, title, publishedDate }, index) => {
          return (
            <NavItem
              key={index}
              {...{
                isLast: articleLinkId === slug || articleLinkId === url,
                lastlyColor: buttonTertiaryHover,
              }}
            >
              <Index>{pageSize + index + 1}.</Index>
              <LinkWrapper>
                <LinkInner
                  onClick={() => {
                    handleChangeArticleLinkId &&
                      handleChangeArticleLinkId(slug || url);
                  }}
                >
                  <CustomLink
                    {...{
                      color: primary,
                      slug: processSlug(slug, lang),
                      target: target === 20 ? "_blank" : "_self",
                      url,
                      title,
                    }}
                  >
                    {displayText}
                  </CustomLink>
                </LinkInner>
                {publishedDate ? (
                  <Date>{`(Opublikowano: ${getPublishedDate(
                    publishedDate
                  )})`}</Date>
                ) : null}
              </LinkWrapper>
            </NavItem>
          );
        }
      )}
    </NavList>
  );
};
