import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getQueryString } from "@utils/get-query-string";

import { EntriesList } from "@containers/EntriesList/EntriesList";
import { useAccessibility } from "@contexts/AccessibilityContext";
import { useStyletron } from "baseui";

import { StyledSpinnerNext } from "baseui/spinner";

import { useData } from "@contexts/DataContext";
import { rewriteUrl } from "@helpers/client-axios";
import { isServer } from "@helpers/ssr";

import { processSlug } from "@utils/process-slug";

import {
  BookmarkLink,
  Bookmarks,
  Inner,
  SpinnerWrapper,
  Title,
  Wrapper,
} from "./styles";

export const BookMarks = ({ newsData, bookmarks, loading, response }) => {
  const [, theme] = useStyletron();

  const { search } = useLocation();
  const { lang } = queryString.parse(search);
  const { size, page } = queryString.parse(search, {
    parseNumbers: true,
  });

  const queyrParams = getQueryString({
    page,
    size,
    lang,
  });

  const { fontSizeMultiplier } = useAccessibility();

  const {
    colors: { primary },
  } = theme;

  const [
    {
      data: newsDataFetched,
      loading: newsDataLoading,
      response: newsDataResponse,
    },
    fetchNews,
  ] = useData(
    {
      url: `${
        isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
      }/page-content/${bookmarks?.items[0].slug}${queyrParams}`,
      method: "GET",
    },
    {
      manual: true,
      useCache: false,
    }
  );

  useEffect(() => {
    if (!newsData) {
      fetchNews();
    }
  }, [fetchNews, newsData]);

  return (
    <Wrapper>
      <Inner>
        <Title
          {...{
            color: theme.colors.contentInverseTertiary,
            fontSize: fontSizeMultiplier! * 15,
          }}
        >
          {bookmarks?.title}
        </Title>
        <Bookmarks>
          {(bookmarks.onMainPage.length > 1 ? bookmarks.onMainPage : []).map(
            ({ slug, color, url, displayText, target, title }) => (
              <BookmarkLink
                key={slug}
                {...{
                  color: theme.name.includes("contrast") ? primary : color,
                  fontSize: fontSizeMultiplier! * 12,
                  slug: processSlug(slug, lang),
                  target: target === 20 ? "_blank" : "",
                  url,
                  title,
                }}
              >
                {displayText}
              </BookmarkLink>
            )
          )}
        </Bookmarks>
      </Inner>
      {(newsData || newsDataFetched) && !loading && !newsDataLoading && (
        <EntriesList
          data={newsData || newsDataFetched.contentData}
          response={newsData ? response : newsDataResponse}
          bookmarks={bookmarks}
        />
      )}
      {(loading || newsDataLoading) && (
        <SpinnerWrapper>
          <StyledSpinnerNext />
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
};
