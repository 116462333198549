import styled, { css } from "styled-components";

import { MEDIA_QUERIES } from "@utils/constants";
import { CustomLink } from "shared/CustomLink";

export const Wrapper = styled.div<{ bgColor: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ bgColor }) => bgColor};
  position: relative;
  height: 400px;
  box-shadow: rgb(136 136 136 / 20%) 1.414px 1.414px 11.28px 0.72px;
  width: calc(36% - 20px);

  @media (max-width: ${MEDIA_QUERIES.lg}) {
    width: calc(50% - 10px);
  }

  @media (max-width: ${MEDIA_QUERIES.md}) {
    width: 100%;
  }
`;

export const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px auto 0 auto;
  flex-direction: column;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  width: 230px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  border: ${({ isOfficeManager, borderColor }) =>
    isOfficeManager ? "none" : `1px solid ${borderColor}`};
`;

export const DescriptionStyle = css<{ color: string }>`
  color ${({ color }) => color};
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const JobPositionWrapper = styled.div`
  width: 80%;
`;

export const JobPosition = styled.span<{ color: string }>`
  ${DescriptionStyle};
  margin-top: 15px;
`;

export const FullNameWrapper = styled.div`
  margin-top: 30px;
  width: 80%;
`;

export const FullName = styled.span`
  ${DescriptionStyle};
  font-weight: 500;
`;

export const MainCardLink = styled.a<{ color: string; fontSize: number }>`
  display: block;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: ${({ fontSize }) => `${fontSize}px!important`};
  text-decoration: underline;
  text-transform: uppercase;
  color: ${({ color }) => color};
  letter-spacing: 0.75px;
  font-weight: 500;
`;

export const MoreInfoLink = styled(CustomLink)<{
  fontSize: number;
  color: string;
}>`
  display: block;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  text-decoration: underline;
  text-transform: uppercase;
  color: ${({ color }) => color};
  letter-spacing: 0.75px;
  font-weight: 500;
`;
