import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { parse, stringify } from "query-string";

import { Block } from "baseui/block";
import { H3, ParagraphXSmall, LabelXSmall, Label3 } from "baseui/typography";
import { Button } from "baseui/button";
import { Input, SIZE as SIZEI } from "baseui/input";
import { useStyletron } from "baseui";
import { Select } from "baseui/select";

import { getPublishedDate } from "@containers/Category/nav-items/helpers";

import { Pagination } from "@containers/Pagination/index";

import { useAccessibility } from "@contexts/AccessibilityContext";
import Datepicker from "@components/Datepicker/Datepicker";
import { ValidationError } from "./Validation-error";

import { NSearch } from "@namespace/Search";

import {
  SEARCH_INTO_OPTION_NAME_AND_CONTENT_ATTACHMENT_VALUE,
  SOURCE_OPTION_ARCHIVE_BIP_TEXT_VALUE,
  SOURCE_OPTION_CURRENT_BIP_TEXT_VALUE,
  minLengthValidator,
  sortByType,
} from "./utils";
import { getQueryString } from "@utils/get-query-string";

const Search: React.FC<NSearch.IProps> = ({
  data: { items, translation },
  layout: { formatDate },
  initData: { information },
  response: { headers },
  canSearchInArchive,
}) => {
  const { push } = useHistory();
  const [css, theme] = useStyletron();
  const { search } = useLocation();
  const { fontSizeMultiplier } = useAccessibility();

  const {
    size = translation?.pagination?.size?.[0] || 10,
    searchInto: searchIntoParam,
    bookmarks,
    page,
  } = parse(search, {
    parseNumbers: true,
  });

  const {
    lang = "PL",
    from,
    to,
    sortBy,
    searchPhrase,
    searchIntoArchive,
  } = parse(search);

  const defaultBookMarkValue = translation.filterBox.bookmarks?.items
    .filter(({ value }) => value === (bookmarks ? `${bookmarks}` : "1"))
    .map(({ key, value }) => ({ label: key, id: value }));

  const defaultSearchIntoValue = translation.filterBox?.searchInto?.items
    .filter(
      ({ value }) => value === (searchIntoParam ? `${searchIntoParam}` : "0")
    )
    .map(({ key, value }) => ({ label: key, id: value }));

  const sourceOptions = [
    {
      label: translation?.filterBox?.sourceOptionCurrentBIPText,
      id: SOURCE_OPTION_CURRENT_BIP_TEXT_VALUE,
    },
    {
      label: translation?.filterBox?.sourceOptionArchiveBIPText,
      id: SOURCE_OPTION_ARCHIVE_BIP_TEXT_VALUE,
    },
  ];

  const sortByOptions = translation.sortBox.sortBy.items.map((item) => ({
    label: item.key,
    id: item.value,
  }));

  const currentSourceOptionValue =
    searchIntoArchive === "true"
      ? SOURCE_OPTION_ARCHIVE_BIP_TEXT_VALUE
      : SOURCE_OPTION_CURRENT_BIP_TEXT_VALUE;

  const defaultSource = sourceOptions.filter(
    ({ id }) =>
      id ===
      (searchIntoArchive
        ? currentSourceOptionValue
        : SOURCE_OPTION_CURRENT_BIP_TEXT_VALUE)
  );

  const defaultSortTypeOption = sortByOptions.filter((el) => {
    return el.id === (sortBy || sortByType.descending);
  });

  const [startDate, setStartDate] = useState<Date | null>(
    from ? new Date(from as string) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    to ? new Date(to as string) : null
  );
  const [phrase, setPhrase] = useState<any>(searchPhrase);
  const [searchInto, setSearchInto] = useState<any>(defaultSearchIntoValue);
  const [bookmark, setBookmark] = useState<any>(defaultBookMarkValue);
  const [source, setSource] = useState<any>(defaultSource);
  const [currentSortBy, setCurrentSortBy] = useState<any>(
    defaultSortTypeOption
  );

  const validators = [minLengthValidator];

  const firstInvalidValidator = validators.find((validate) => {
    return !!validate(phrase, translation.filterBox?.errorText ?? "");
  });

  const validationError =
    firstInvalidValidator &&
    firstInvalidValidator(phrase, translation.filterBox?.errorText ?? "");

  const {
    colors: {
      primary,
      accent50,
      backgroundLightAccent,
      backgroundSecondary,
      backgroundInv,
      borderSelected,
      contentInverseTertiary,
      borderError,
      backgroundPrimary,
    },
    name,
    sizing: { scale600 },
  } = theme;

  const isEnabledSubmit =
    startDate &&
    endDate &&
    endDate >= startDate &&
    startDate &&
    startDate <= endDate &&
    phrase?.length >= 3 &&
    phrase &&
    (source?.length || !canSearchInArchive);

  const isDisableDate =
    +searchInto?.[0]?.id ===
      +SEARCH_INTO_OPTION_NAME_AND_CONTENT_ATTACHMENT_VALUE &&
    +source?.[0]?.id === +SOURCE_OPTION_ARCHIVE_BIP_TEXT_VALUE;

  const onSubmit = () => {
    push({
      search: getQueryString({
        lang,
        page: 0,
        size,
        from: startDate ? startDate.toISOString() : null,
        to: endDate ? endDate.toISOString() : null,
        searchPhrase: phrase,
        searchInto:
          searchInto && searchInto.length > 0
            ? searchInto[0].id
            : translation.filterBox?.searchInto?.items[0].value,
        bookmarks:
          bookmark && bookmark.length > 0
            ? bookmark[0].id
            : translation.filterBox.bookmarks?.items[0].value,
        searchIntoArchive:
          source && source[0]?.id === SOURCE_OPTION_ARCHIVE_BIP_TEXT_VALUE,
        sortBy:
          currentSortBy && currentSortBy.length > 0
            ? currentSortBy[0]?.id
            : sortByType.descending,
      }),
    });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && isEnabledSubmit) {
        onSubmit();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [phrase, startDate, endDate, bookmark, source, currentSortBy]);

  useEffect(() => {
    if (isDisableDate) {
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(from ? new Date(from as string) : null);
      setEndDate(to ? new Date(to as string) : null);
    }
  }, [isDisableDate]);

  return (
    <div>
      <Block
        width={["100%"]}
        marginBottom={["20px", "20px", "0", "0"]}
        overrides={{
          Block: {
            style: () => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "stretch",
            }),
          },
        }}
      >
        <Block
          overrides={{
            Block: {
              style: () => ({
                backgroundColor: backgroundLightAccent,
                padding: "30px 30px",
                width: "100%",
                borderRadius: "3px",
                boxShadow:
                  "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                textDecoration: "none",
                position: "relative",
                boxSizing: "border-box",
                height: "100%",
              }),
            },
          }}
        >
          <H3
            overrides={{
              Block: {
                style: () => ({
                  color: primary,
                  margin: "0px 0px 5px",
                  fontSize: fontSizeMultiplier! * 18 + "px",
                  lineHeight: "18px",
                  letterSpacing: "1px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }),
              },
            }}
          >
            {translation?.title}
          </H3>
          <Block
            overrides={{
              Block: {
                style: () => ({
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "stretch",
                  paddingTop: "40px",
                  flexWrap: "wrap",
                }),
              },
            }}
          >
            <Block width={["100%", "100%", "100%", "58%"]}>
              <div
                className={css({
                  margin: "0px 0px 0px 0px",
                  display: "flex",
                  alignItems: "center",
                })}
              >
                <LabelXSmall
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "0px 10px 0px 0px",
                        color: theme.colors.accent50,
                        fontWeight: 400,
                        fontSize: fontSizeMultiplier! * 10 + "px",
                        letterSpacing: ".25px",
                        textDecoration: "none",
                        minWidth: "100px",
                      }),
                    },
                  }}
                >
                  {translation?.filterBox?.searchPhrase}
                </LabelXSmall>
                <Input
                  value={phrase}
                  onChange={(e) => setPhrase(e.currentTarget.value)}
                  size={SIZEI.compact}
                  overrides={{
                    Input: {
                      style: () => ({
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "11px",
                        padding: "8px 8px",
                        backgroundColor: backgroundSecondary,
                        color: accent50,
                      }),
                    },
                    InputContainer: {
                      style: () => ({
                        borderRadius: "3px",
                        boxShadow:
                          "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                        border: `1px solid ${borderSelected}`,
                        minWidth: "unset",
                      }),
                    },
                  }}
                />
              </div>
              <ValidationError
                {...{ validationError, borderError, fontSizeMultiplier }}
              />
              <div
                className={css({
                  margin: "10px 0px 0px 0px",
                  display: "flex",
                  alignItems: "center",
                })}
              >
                <LabelXSmall
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "0px 10px 0px 0px",
                        color: accent50,
                        fontWeight: 400,
                        fontSize: fontSizeMultiplier! * 10 + "px",
                        letterSpacing: ".25px",
                        textDecoration: "none",
                        minWidth: "100px",
                      }),
                    },
                  }}
                >
                  {translation?.filterBox?.searchInto?.title}
                </LabelXSmall>
                {translation.filterBox.searchInto.items.length > 0 && (
                  <Select
                    options={translation.filterBox.searchInto?.items?.map(
                      (item: { key: any; value: any }) => {
                        return { label: item.key, id: item.value };
                      }
                    )}
                    value={searchInto}
                    clearable={false}
                    size={SIZEI.compact}
                    searchable={false}
                    onChange={(params) => setSearchInto(params.value)}
                    overrides={{
                      ValueContainer: {
                        style: () => ({
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          lineHeight: "11px",
                          padding: "8px 8px",
                          backgroundColor: backgroundSecondary,
                          color: accent50,
                          height: "auto",
                        }),
                      },
                      SingleValue: {
                        style: () => ({
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          lineHeight: "11px",
                          height: "auto",
                        }),
                      },
                      ControlContainer: {
                        style: () => ({
                          borderRadius: "3px",
                          boxShadow:
                            "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                          border: `1px solid ${borderSelected}`,
                          minWidth: "unset",
                          backgroundColor: `${backgroundSecondary}`,
                        }),
                      },
                      InputContainer: {
                        style: () => ({
                          height: "0px",
                        }),
                      },
                      Root: {
                        style: () => ({
                          marginTop: "5px",
                          backgroundColor: `${backgroundSecondary}`,
                        }),
                      },
                    }}
                  />
                )}
              </div>
              <div
                className={css({
                  margin: "15px 0px 0px 0px",
                  display: "flex",
                  alignItems: "center",
                })}
              >
                <LabelXSmall
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "0px 10px 0px 0px",
                        color: accent50,
                        fontWeight: 400,
                        fontSize: fontSizeMultiplier! * 10 + "px",
                        letterSpacing: ".25px",
                        textDecoration: "none",
                        minWidth: "100px",
                      }),
                    },
                  }}
                >
                  {translation?.filterBox?.date}
                </LabelXSmall>
                <div
                  className={css({
                    width: "125px",
                    marginRight: scale600,
                  })}
                >
                  <Datepicker
                    lang={lang}
                    value={startDate}
                    onChange={(a: { date: Date }) => {
                      setStartDate(
                        a.date ? new Date(a.date.setHours(0, 0, 0, 0)) : null
                      );
                    }}
                    maxDate={endDate}
                    disabled={isDisableDate}
                    clearable={true}
                    placeholder={"RRRR-MM-DD"}
                    formatString={formatDate}
                    aria-label="start date"
                    overrides={{
                      Input: {
                        props: {
                          size: SIZEI.compact,
                          overrides: {
                            Input: {
                              style: {
                                fontSize: fontSizeMultiplier! * 11 + "px",
                                lineHeight: "11px",
                                padding: "8px 8px",
                                backgroundColor: backgroundSecondary,
                                color: accent50,
                              },
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div
                  className={css({
                    width: "125px",
                  })}
                >
                  <Datepicker
                    lang={lang}
                    value={endDate}
                    onChange={(a: { date: Date }) => {
                      setEndDate(
                        a.date
                          ? new Date(a.date.setHours(23, 59, 59, 999))
                          : null
                      );
                    }}
                    placeholder={"RRRR-MM-DD"}
                    disabled={isDisableDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    clearable={true}
                    formatString={formatDate}
                    aria-label="end date"
                    overrides={{
                      Input: {
                        props: {
                          size: SIZEI.compact,
                          overrides: {
                            Input: {
                              style: {
                                fontSize: fontSizeMultiplier! * 11 + "px",
                                lineHeight: "11px",
                                padding: "8px 8px",
                                backgroundColor: backgroundSecondary,
                                color: accent50,
                              },
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div
                className={css({
                  margin: "10px 0px 0px 0px",
                  display: "flex",
                  alignItems: "center",
                })}
              >
                <LabelXSmall
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "0px 10px 0px 0px",
                        color: accent50,
                        fontWeight: 400,
                        fontSize: fontSizeMultiplier! * 10 + "px",
                        letterSpacing: ".25px",
                        textDecoration: "none",
                        minWidth: "100px",
                      }),
                    },
                  }}
                >
                  {translation?.filterBox?.bookmarks?.title}
                </LabelXSmall>
                {translation.filterBox.bookmarks.items.length > 0 && (
                  <Select
                    options={translation.filterBox.bookmarks?.items?.map(
                      (item: { key: any; value: any }) => {
                        return { label: item.key, id: item.value };
                      }
                    )}
                    value={bookmark}
                    disabled={
                      canSearchInArchive &&
                      source &&
                      source[0]?.id === SOURCE_OPTION_ARCHIVE_BIP_TEXT_VALUE
                    }
                    clearable={false}
                    size={SIZEI.compact}
                    searchable={false}
                    onChange={(params) => setBookmark(params.value)}
                    overrides={{
                      ValueContainer: {
                        style: () => ({
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          lineHeight: "11px",
                          padding: "8px 8px",
                          backgroundColor: backgroundSecondary,
                          color: accent50,
                          height: "auto",
                        }),
                      },
                      SingleValue: {
                        style: () => ({
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          lineHeight: "11px",
                          height: "auto",
                        }),
                      },
                      ControlContainer: {
                        style: () => ({
                          borderRadius: "3px",
                          boxShadow:
                            "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                          border: `1px solid ${borderSelected}`,
                          minWidth: "unset",
                          backgroundColor: `${backgroundSecondary}`,
                        }),
                      },
                      InputContainer: {
                        style: () => ({
                          height: "0px",
                        }),
                      },
                      Root: {
                        style: () => ({
                          marginTop: "5px",
                          backgroundColor: `${backgroundSecondary}`,
                        }),
                      },
                    }}
                  />
                )}
              </div>
              <div
                className={css({
                  margin: "10px 0px 0px 0px",
                  display: "flex",
                  alignItems: "center",
                })}
              >
                <LabelXSmall
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "0px 10px 0px 0px",
                        color: accent50,
                        fontWeight: 400,
                        fontSize: fontSizeMultiplier! * 10 + "px",
                        letterSpacing: ".25px",
                        textDecoration: "none",
                        minWidth: "100px",
                      }),
                    },
                  }}
                >
                  {translation?.sortBox.title}
                </LabelXSmall>
                <Select
                  options={sortByOptions}
                  value={currentSortBy} // change
                  size={SIZEI.compact}
                  searchable={false}
                  clearable={false}
                  // placeholder={translation?.filterBox?.searchInto?.title}
                  onChange={(params) => {
                    if (params.value && params.value[0].id) {
                      setCurrentSortBy(params.value);
                    }
                  }}
                  overrides={{
                    ValueContainer: {
                      style: () => ({
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "11px",
                        padding: "8px 8px",
                        backgroundColor: backgroundSecondary,
                        color: accent50,
                        height: "auto",
                      }),
                    },
                    SingleValue: {
                      style: () => ({
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "11px",
                        height: "auto",
                      }),
                    },
                    ControlContainer: {
                      style: () => ({
                        borderRadius: "3px",
                        boxShadow:
                          "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                        border: `1px solid ${borderSelected}`,
                        minWidth: "unset",
                        backgroundColor: `${backgroundSecondary}`,
                      }),
                    },
                    InputContainer: {
                      style: () => ({
                        height: "0px",
                      }),
                    },
                    Root: {
                      style: () => ({
                        marginTop: "5px",
                        backgroundColor: `${backgroundSecondary}`,
                      }),
                    },
                  }}
                />
              </div>
              {canSearchInArchive ? (
                <>
                  <div
                    className={css({
                      margin: "10px 0px 0px 0px",
                      display: "flex",
                      alignItems: "center",
                    })}
                  >
                    <LabelXSmall
                      overrides={{
                        Block: {
                          style: () => ({
                            margin: "0px 10px 0px 0px",
                            color: accent50,
                            fontWeight: 400,
                            fontSize: fontSizeMultiplier! * 10 + "px",
                            letterSpacing: ".25px",
                            textDecoration: "none",
                            minWidth: "100px",
                          }),
                        },
                      }}
                    >
                      {translation?.filterBox?.sourceText}
                    </LabelXSmall>
                    <Select
                      options={sourceOptions}
                      value={source}
                      size={SIZEI.compact}
                      searchable={false}
                      clearable={false}
                      onChange={(params) => {
                        if (
                          params.value &&
                          params.value[0]?.id ===
                            SOURCE_OPTION_ARCHIVE_BIP_TEXT_VALUE
                        ) {
                          setBookmark(
                            translation.filterBox.bookmarks?.items
                              .map((el) => ({
                                label: el.key,
                                id: el.value,
                              }))
                              .filter((item) => item.id === "1")
                          );
                        }

                        setSource(params.value);
                      }}
                      overrides={{
                        ValueContainer: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            padding: "8px 8px",
                            backgroundColor: backgroundSecondary,
                            color: accent50,
                            height: "auto",
                          }),
                        },
                        SingleValue: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            height: "auto",
                          }),
                        },
                        ControlContainer: {
                          style: () => ({
                            borderRadius: "3px",
                            boxShadow:
                              "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                            border: `1px solid ${borderSelected}`,
                            minWidth: "unset",
                            backgroundColor: `${backgroundSecondary}`,
                          }),
                        },
                        InputContainer: {
                          style: () => ({
                            height: "0px",
                          }),
                        },
                        Root: {
                          style: () => ({
                            marginTop: "5px",
                            backgroundColor: `${backgroundSecondary}`,
                          }),
                        },
                      }}
                    />
                  </div>
                  <ValidationError
                    {...{
                      validationError: !!source?.length
                        ? ""
                        : translation?.filterBox?.sourceErrorText || "",
                      borderError,
                      fontSizeMultiplier,
                    }}
                  />
                </>
              ) : null}
            </Block>
            <Block
              width={["100%", "100%", "100%", "38%"]}
              paddingTop={["20px", "20px", "20px", "0px"]}
              marginBottom={[
                !source?.length &&
                canSearchInArchive &&
                translation?.filterBox?.sourceErrorText
                  ? "16px"
                  : "0px",
              ]}
              overrides={{
                Block: {
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  },
                },
              }}
            >
              {translation?.filterBox?.userManual?.url ? (
                <ParagraphXSmall
                  overrides={{
                    Block: {
                      style: () => ({
                        fontWeight: 400,
                        fontSize: fontSizeMultiplier! * 14 + "px",
                        letterSpacing: ".25px",
                        textDecoration: "none",
                      }),
                    },
                  }}
                >
                  <a
                    href={translation?.filterBox?.userManual?.url}
                    title={translation?.filterBox?.userManual?.title}
                    target="_blank"
                  >
                    {translation?.filterBox?.userManual?.title}
                  </a>
                </ParagraphXSmall>
              ) : null}
              <Button
                overrides={{
                  BaseButton: {
                    props: {
                      title: translation?.filterBox?.searchButton?.title,
                    },
                    style: () => ({
                      border: "3px solid " + primary,
                      boxShadow:
                        "0.707px 0.707px 11.28px 0.72px rgba(0, 147, 221, 0.2)",
                      borderRadius: "4px",
                      fontSize: fontSizeMultiplier! * 10 + "px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      padding: "9px",
                      lineHeight: "10px",
                      width: "100%",
                      letterSpacing: "2px",
                      marginTop: "20px",
                      ":focus": {
                        borderColor: name.includes("contrast")
                          ? backgroundPrimary
                          : accent50,
                        borderWidth: "3px",
                      },
                    }),
                  },
                }}
                onClick={() => {
                  onSubmit();
                }}
                disabled={
                  (endDate && startDate && endDate < startDate) ||
                  (startDate && endDate && startDate > endDate) ||
                  phrase?.length < 3 ||
                  !phrase ||
                  (!source?.length && canSearchInArchive)
                }
              >
                {translation?.filterBox?.searchButton?.displayText}
              </Button>
            </Block>
          </Block>
        </Block>
      </Block>
      {items ? (
        <>
          <div
            className={css({
              marginTop: "30px",
              marginBottom: "10px",
              backgroundColor: backgroundSecondary,
              borderRadius: "4px",
              padding: "20px",
            })}
          >
            <Label3
              overrides={{
                Block: {
                  style: () => ({
                    fontSize: fontSizeMultiplier! * 12 + "px",
                    fontWeight: 400,
                    borderBottom: "1px solid " + backgroundInv,
                    paddingBottom: "15px",
                    color: contentInverseTertiary,
                  }),
                },
              }}
            >
              <span className={css({ color: primary, fontWeight: 500 })}>
                {translation?.summaryText}
              </span>{" "}
              <span
                className={css({
                  fontStyle: "italic",
                  fontWeight: 500,
                  color: accent50,
                })}
              >{`${translation?.filterBox.searchPhrase ?? ""}: ${
                searchPhrase ?? ""
              }`}</span>
            </Label3>
            <div
              className={css({
                margin: "10px 0 0 0",
              })}
            >
              {items.length === 0 && (
                <span className={css({ fontWeight: 600, color: primary })}>{`${
                  translation.noDataText ?? ""
                }`}</span>
              )}
              {items.map((item, index) => {
                console.log(item);
                return (
                  <div
                    key={index}
                    className={css({
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      margin: "20px 0 0 0",
                    })}
                  >
                    <div
                      className={css({
                        width: "auto",
                        marginRight: "15px",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "15px",
                        fontWeight: 500,
                        color: contentInverseTertiary,
                      })}
                    >
                      {typeof page === "number" &&
                        typeof size === "number" &&
                        page * size + index + 1}
                      .
                    </div>
                    <div>
                      <LabelXSmall>
                        {item.article?.slug ? (
                          <Link
                            to={item.article?.slug}
                            title={item.article?.title}
                          >
                            {item.article?.displayText}
                          </Link>
                        ) : (
                          <a href={item.article.url} title={item.article.title}>
                            {item.article.displayText}
                          </a>
                        )}
                      </LabelXSmall>
                      {item.publishedDate ? (
                        <div
                          style={{
                            lineHeight: "16px",
                            fontSize: fontSizeMultiplier! * 11,
                            textTransform: "none",
                            fontWeight: 700,
                            color: primary,
                          }}
                        >
                          {`(${translation.publishLabel}: ${getPublishedDate(
                            item.publishedDate
                          )})`}
                        </div>
                      ) : null}
                      <ParagraphXSmall
                        as="div"
                        overrides={{
                          Block: {
                            style: () => ({
                              margin: "5px 0 0 0",
                              fontSize: fontSizeMultiplier! * 11 + "px",
                              color: accent50,
                            }),
                          },
                        }}
                      >
                        {item.description && ReactHtmlParser(item.description)}
                        {item.attachment && (
                          <>
                            :{" "}
                            <div>
                              <a
                                href={item.attachment?.url}
                                title={item.attachment?.title}
                              >
                                {item.attachment?.displayText}
                              </a>
                            </div>
                          </>
                        )}
                      </ParagraphXSmall>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {items.length ? (
            <Pagination
              {...{
                startDate,
                endDate,
                phrase,
                bookmark,
                searchInto,
                sortBy: sortBy as string,
                translation,
                headers,
              }}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default Search;
