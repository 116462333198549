import styled from "styled-components";

import ReactTooltip from "react-tooltip";
import { CustomLink } from "shared/CustomLink";

export const Text = styled.span<{ isOverflown: boolean }>`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    cursor: ${({ isOverflown }) => (isOverflown ? "pointer" : "auto")};
  }
`;

export const StyledTooltip = styled(ReactTooltip)`
  z-index: 999;
  opacity: 1 !important;
`;

export const TooltipLink = styled(CustomLink)`
  color: ${({ color }) => color};
`;
