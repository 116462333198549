import { CustomLink } from "shared/CustomLink";

import styled from "styled-components";

export const CategoryItem = styled.li<{
  fontSizeMultiplier: number;
  primary: string;
  backgroundLightAccent: string;
  contentInversePrimary: string;
  isMobile: boolean;
}>`
  font-size: ${({ fontSizeMultiplier }) => `${fontSizeMultiplier * 14}px`};
  background: ${({ backgroundLightAccent }) => backgroundLightAccent};

  border-top: ${({ contentInversePrimary, isMobile }) =>
    isMobile ? `1px solid ${contentInversePrimary}` : "none"};

  border-left: ${({ contentInversePrimary, isMobile }) =>
    isMobile ? `1px solid ${contentInversePrimary}` : "none"};

  a {
    color: ${({ primary }) => primary};
  }

  &:hover {
    background: ${({ primary }) => primary};

    a {
      color: ${({ backgroundLightAccent }) => backgroundLightAccent};
    }
  }
`;

export const CategoryLink = styled(CustomLink)`
  text-decoration: none;
  display: block;
  padding: 8px;
`;
