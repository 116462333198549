import React from "react";

import { Block } from "baseui/block";
import { H3 } from "baseui/typography";
import { useStyletron } from "baseui";
import { StyledLink } from "baseui/link";
import { useHistory, useLocation } from "react-router-dom";
import { useAccessibility } from "../../contexts/AccessibilityContext";
import queryString from "query-string";
import { isServer } from "../../helpers/ssr";

type STProps = {
  item: any;
};

const SitemapTree: React.FC<STProps> = (props) => {
  const { item } = props;
  const [css, theme] = useStyletron();
  const history = useHistory();

  const { search } = useLocation();
  const { lang } = queryString.parse(search);

  return (
    <ul className={css({ listStyleType: "none", paddingLeft: "10px" })}>
      {item.categories.length > 0 &&
        item.categories.map(
          (item: { slug; displayText; categories; title }, index: number) => {
            return (
              <li
                className={css({
                  margin: "4px 0",
                  textDecoration: "none !important",
                })}
                key={JSON.stringify(item) + "" + index}
              >
                <StyledLink
                  title={item.title}
                  className={css({
                    textDecoration: "none",
                    color: theme.colors.primary + " !important",
                    fontWeight: 400,
                    ":visited": { color: theme.colors.primary + " !important" },
                    ":hover": { textDecoration: "underline" },
                  })}
                  href={
                    item &&
                    item.slug &&
                    item.slug.length > 0 &&
                    item.slug.includes("http")
                      ? item.slug
                      : `/${
                          item.slug +
                          (lang ? "?" + queryString.stringify({ lang }) : "")
                        }`
                  }
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    window.scrollTo(0, 0);
                    if (!item.slug) return null;
                    else if (!item.slug.includes("http")) {
                      history.push({
                        pathname: `/${item.slug}`,
                        search: queryString.stringify({ lang }),
                      });
                    } else {
                      window.location.href = item?.slug as string;
                      return null;
                    }
                  }}
                >
                  — {item.displayText}
                </StyledLink>

                <SitemapTree item={item} />
              </li>
            );
          }
        )}
    </ul>
  );
};

type SProps = {
  data: any;
};

const Sitemap: React.FC<SProps> = (props) => {
  const { data } = props;
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { search } = useLocation();
  const { lang } = queryString.parse(search);

  const { fontSizeMultiplier } = useAccessibility();

  return (
    <Block
      overrides={{
        Block: {
          style: () => ({
            backgroundColor: theme.colors.backgroundSecondary,
            margin: "0 0 20px",
            borderRadius: "5px",
            boxShadow:
              "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
          }),
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: () => ({
              padding: "30px 50px 40px",
              fontSize: fontSizeMultiplier! * 11 + "px",
              lineHeight: "15px",
            }),
          },
        }}
      >
        <H3
          overrides={{
            Block: {
              style: ({ $theme }) => ({
                color: $theme.colors.primary,
                margin: "0px 0px 25px",
                fontSize: fontSizeMultiplier! * 18 + "px",
                lineHeight: "18px",
                letterSpacing: "1px",
                fontWeight: 500,
                textTransform: "uppercase",
                borderBottom: "1px solid " + $theme.colors.backgroundInv,
                padding: "0 0 15px",
              }),
            },
          }}
        >
          {data?.translation?.title}
        </H3>

        <div
          className={css({
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          })}
        >
          <Block width={["100%", "100%", "48%", "48%"]}>
            {data?.left && (
              <ul
                className={css({ listStyleType: "none", paddingLeft: "0px" })}
              >
                {data?.left.length > 0 &&
                  data?.left.map(
                    (
                      item: {
                        slug;
                        displayText;
                        categories;
                        title;
                      },
                      index: number
                    ) => {
                      return (
                        <li
                          key={JSON.stringify(item) + "" + index}
                          className={css({
                            margin: "0px 0 20px",
                            backgroundImage: `url(${
                              (isServer() ? "/" : "") +
                              require(`../../assets/icons/page-${theme.name}.svg`)
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "12px",
                            backgroundPosition: "0px 0px",
                            paddingTop: "2px",
                            paddingLeft: "20px",
                          })}
                        >
                          <StyledLink
                            title={item.title}
                            className={css({
                              textDecoration: "none",
                              textTransform: "uppercase",
                              color: theme.colors.primaryB + " !important",
                              fontSize: fontSizeMultiplier! * 12 + "px",
                              fontWeight: 500,
                              ":visited": {
                                color: theme.colors.primaryB + " !important",
                              },
                            })}
                            href={
                              item &&
                              item.slug &&
                              item.slug.length > 0 &&
                              item.slug.includes("http")
                                ? item.slug
                                : `/${
                                    item.slug +
                                    (lang
                                      ? "?" + queryString.stringify({ lang })
                                      : "")
                                  }`
                            }
                            onClick={(e: { preventDefault: () => void }) => {
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              if (!item.slug) return null;
                              else if (!item.slug.includes("http")) {
                                history.push({
                                  pathname: `/${item.slug}`,
                                  search: queryString.stringify({ lang }),
                                });
                              } else {
                                window.location.href = item?.slug as string;
                                return null;
                              }
                            }}
                          >
                            {item.displayText}
                          </StyledLink>

                          {item.categories && (
                            <ul
                              className={css({
                                listStyleType: "none",
                                marginLeft: "-13px",
                                marginTop: "10px",
                                borderLeft:
                                  "1px solid " + theme.colors.backgroundInv,
                                paddingLeft: "10px",
                              })}
                            >
                              {item.categories.length > 0 &&
                                item.categories.map((item) => {
                                  return (
                                    <li
                                      className={css({
                                        margin: "4px 0",
                                        textDecoration: "none !important",
                                      })}
                                      key={JSON.stringify(item) + "" + index}
                                    >
                                      <StyledLink
                                        title={item.title}
                                        className={css({
                                          textDecoration: "none",
                                          color:
                                            theme.colors.primary +
                                            " !important",
                                          fontWeight: 400,
                                          ":visited": {
                                            color:
                                              theme.colors.primary +
                                              " !important",
                                          },
                                          ":hover": {
                                            textDecoration: "underline",
                                          },
                                        })}
                                        href={
                                          item &&
                                          item.slug &&
                                          item.slug.length > 0
                                            ? item.slug.includes("http")
                                              ? item.slug
                                              : `/${
                                                  item.slug +
                                                  (lang
                                                    ? "?" +
                                                      queryString.stringify({
                                                        lang,
                                                      })
                                                    : "")
                                                }`
                                            : item.url
                                        }
                                        onClick={(e: {
                                          preventDefault: () => void;
                                        }) => {
                                          e.preventDefault();
                                          window.scrollTo(0, 0);
                                          if (!item.slug && !item.url) {
                                            return null;
                                          } else if (
                                            item.slug &&
                                            !item.slug.includes("http")
                                          ) {
                                            history.push({
                                              pathname: `/${item.slug}`,
                                              search: queryString.stringify({
                                                lang,
                                              }),
                                            });
                                          } else {
                                            window.location.href =
                                              item?.url as string;
                                            return null;
                                          }
                                        }}
                                      >
                                        — {item.displayText}
                                      </StyledLink>

                                      <SitemapTree item={item} />
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </li>
                      );
                    }
                  )}
              </ul>
            )}
          </Block>

          <Block width={["100%", "100%", "48%", "48%"]}>
            {data?.right && (
              <ul
                className={css({ listStyleType: "none", paddingLeft: "0px" })}
              >
                {data?.right.length > 0 &&
                  data?.right.map(
                    (
                      item: {
                        slug;
                        displayText;
                        categories;
                        title;
                      },
                      index: number
                    ) => {
                      return (
                        <li
                          key={JSON.stringify(item) + "" + index}
                          className={css({
                            margin: "0px 0 20px",
                            backgroundImage: `url(${
                              (isServer() ? "/" : "") +
                              require(`../../assets/icons/page-${theme.name}.svg`)
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "12px",
                            backgroundPosition: "0px 0px",
                            paddingTop: "2px",
                            paddingLeft: "20px",
                          })}
                        >
                          <StyledLink
                            title={item.title}
                            className={css({
                              textDecoration: "none",
                              textTransform: "uppercase",
                              color: theme.colors.primaryB + " !important",
                              fontSize: fontSizeMultiplier! * 12 + "px",
                              fontWeight: 500,
                              ":visited": {
                                color: theme.colors.primaryB + " !important",
                              },
                            })}
                            href={
                              item &&
                              item.slug &&
                              item.slug.length > 0 &&
                              item.slug.includes("http")
                                ? item.slug
                                : `/${
                                    item.slug +
                                    (lang
                                      ? "?" + queryString.stringify({ lang })
                                      : "")
                                  }`
                            }
                            onClick={(e: { preventDefault: () => void }) => {
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              if (!item.slug) return null;
                              else if (!item.slug.includes("http")) {
                                history.push({
                                  pathname: `/${item.slug}`,
                                  search: queryString.stringify({ lang }),
                                });
                              } else {
                                window.location.href = item?.slug as string;
                                return null;
                              }
                            }}
                          >
                            {item.displayText}
                          </StyledLink>

                          {item.categories && (
                            <ul
                              className={css({
                                listStyleType: "none",
                                marginLeft: "-13px",
                                marginTop: "10px",
                                borderLeft:
                                  "1px solid " + theme.colors.backgroundInv,
                                paddingLeft: "10px",
                              })}
                            >
                              {item.categories.length > 0 &&
                                item.categories.map((item) => {
                                  return (
                                    <li
                                      className={css({
                                        margin: "4px 0",
                                        textDecoration: "none !important",
                                      })}
                                      key={JSON.stringify(item) + "" + index}
                                    >
                                      <StyledLink
                                        title={item.title}
                                        className={css({
                                          textDecoration: "none",
                                          color:
                                            theme.colors.primary +
                                            " !important",
                                          fontWeight: 400,
                                          ":visited": {
                                            color:
                                              theme.colors.primary +
                                              " !important",
                                          },
                                          ":hover": {
                                            textDecoration: "underline",
                                          },
                                        })}
                                        href={
                                          item &&
                                          item.slug &&
                                          item.slug.length > 0
                                            ? item.slug.includes("http")
                                              ? item.slug
                                              : `/${
                                                  item.slug +
                                                  (lang
                                                    ? "?" +
                                                      queryString.stringify({
                                                        lang,
                                                      })
                                                    : "")
                                                }`
                                            : item.url
                                        }
                                        onClick={(e: {
                                          preventDefault: () => void;
                                        }) => {
                                          e.preventDefault();
                                          window.scrollTo(0, 0);
                                          if (!item.slug && !item.url) {
                                            return null;
                                          } else if (
                                            item.slug &&
                                            !item.slug.includes("http")
                                          ) {
                                            history.push({
                                              pathname: `/${item.slug}`,
                                              search: queryString.stringify({
                                                lang,
                                              }),
                                            });
                                          } else {
                                            window.location.href =
                                              item?.url as string;
                                            return null;
                                          }
                                        }}
                                      >
                                        — {item.displayText}
                                      </StyledLink>

                                      <SitemapTree item={item} />
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </li>
                      );
                    }
                  )}
              </ul>
            )}
          </Block>
        </div>
      </Block>
    </Block>
  );
};

export default Sitemap;
