import React from "react";
import { Route, Switch } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { AccessibilityProvider } from "../../contexts/AccessibilityContext";
import AppLayout from "../../components/AppLayout/AppLayout";
import PdfLayout from "../../components/PdfLayout/PdfLayout";
import { ThemeProvider } from "../../contexts/ThemeContext";
import { isServer } from "../../helpers/ssr";
import { ScrollPositionProvider } from "@contexts/ScrollProvider";

const App: React.FC = () => {
  return (
    <CookiesProvider>
      <AccessibilityProvider>
        <ScrollPositionProvider>
          <ThemeProvider>
            <Switch>
              {isServer() && (
                <Route
                  path={[
                    "/dane-klienta/pdf",
                    "/dostepnosc-cyfrowa/pdf",
                    "/rss/pdf",
                    "/ochrona-danych-osobowych/pdf",
                    "/ponowne-wykorzystanie/pdf",
                    "/udostepnienie-informacji-publicznej/pdf",
                    "/kategorie/:category/artykuly/:article/pdf",
                  ]}
                  component={PdfLayout}
                />
              )}
              <Route path="/" component={AppLayout} />
            </Switch>
          </ThemeProvider>
        </ScrollPositionProvider>
      </AccessibilityProvider>
    </CookiesProvider>
  );
};

export default App;
