export const MODAL_COLORS = {
  text: {
    standard: "#191f51",
    contrast1: "#FFF000",
    contrast2: "#FFFFFF",
    contrast3: "#00FF00",
    contrast4: "#000000",
    contrast5: "#0000FF",
    contrast6: "#FFF000",
  },
  bgColor: {
    standard: "#F8F8F8",
    contrast1: "#333333",
    contrast2: "#333333",
    contrast3: "#333333",
    contrast4: "#00ff00",
    contrast5: "#FFF000",
    contrast6: "#0000ff",
  },
  buttonText: {
    standard: "#F8F8F8",
    contrast1: "#000000",
    contrast2: "#000000",
    contrast3: "#000000",
    contrast4: "#00ff00",
    contrast5: "#FFF000",
    contrast6: "#0000ff",
  },
  buttonBacground: {
    standard: "#0070a8",
    contrast1: "#FFF000",
    contrast2: "#FFFFFF",
    contrast3: "#00FF00",
    contrast4: "#000000",
    contrast5: "#0000FF",
    contrast6: "#FFF000",
  },
  border: {
    standard: "#cccccc",
    contrast1: "#FFF000",
    contrast2: "#FFFFFF",
    contrast3: "#00FF00",
    contrast4: "#000000",
    contrast5: "#0000FF",
    contrast6: "#FFF000",
  },
  content: {
    standard: "#141414",
    contrast1: "#FFF000",
    contrast2: "#FFFFFF",
    contrast3: "#00FF00",
    contrast4: "#000000",
    contrast5: "#0000FF",
    contrast6: "#FFF000",
  },
};
