import queryString from "query-string";
import { NMenu } from "../../core/typings/Menu";

const { stringify } = queryString;

export const historyPush = (
  push: Function,
  pathname: string,
  lang: NMenu.TLang
) =>
  push({
    pathname,
    search: stringify({ lang }),
  });

export const pushToSlug = (
  slug: string,
  push: Function,
  lang: NMenu.TLang,
  url?: string,
  target?: number
) => {
  return slug
    ? push({
        pathname: `/${slug}`,
        search: stringify({ lang: lang ?? "PL" }),
      })
    : url
    ? window.open(url as string, target === 20 ? "_blank" : "_self")
    : null;
};

export const setBorderBottom = (color: string) => `3px solid ${color}`;

export const setBackgroundColor = (color: string) => `${color}`;

export const pathnameBackgroundCondition = (
  pathname: string,
  path: string,
  homeUrl: string,
  firstColor: string,
  secondColor: string
) =>
  pathname === path || pathname === homeUrl
    ? setBackgroundColor(firstColor)
    : setBackgroundColor(secondColor);

export const setMenuColors = (
  background,
  pathname,
  path,
  homeUrl,
  firstColor,
  secondColor,
  thirdColor,
  fourthColor
) =>
  background
    ? pathnameBackgroundCondition(
        pathname,
        path,
        homeUrl,
        thirdColor,
        fourthColor
      )
    : pathnameBackgroundCondition(
        pathname,
        path,
        homeUrl,
        firstColor,
        secondColor
      );

export const pathnameBorderBottomCondition = (
  pathname: string,
  path: string,
  background: string,
  firstResult: string,
  secondResult: string,
  homeUrl: string
) => {
  return (!background && pathname === path) ||
    (!background && pathname === homeUrl)
    ? setBorderBottom(firstResult)
    : !background && pathname !== path
    ? setBorderBottom(secondResult)
    : "none";
};

export const renderCondition = (
  condition: any,
  firstResult: string,
  secondResult: string
) => (condition ? firstResult : secondResult);

export const getCustomIconName = (name: string) =>
  name === "contrast1" || name === "contrast2" || name === "contrast3"
    ? "contrast4"
    : name === "contrast4"
    ? "contrast3"
    : name === "contrast5"
    ? "contrast1"
    : name === "contrast6"
    ? "contrast5"
    : "white";

export const getDefaultIconName = (name: string) => name;

export const getHomeIcon = (background: string, theme: string, name: string) =>
  background ? getCustomIconName(theme) : getDefaultIconName(name);

export const getColor = (
  background: string,
  name: string,
  accent: string,
  primary: string,
  contentAccent: string,
  menuFill: string
) =>
  background && (name.includes("4") || name.includes("5") || name.includes("6"))
    ? accent
    : background && name.includes("contrast")
    ? contentAccent
    : background
    ? menuFill
    : primary;

export const getActionColor = (
  background: string,
  firstColor: string,
  secondColor: string
) => (background ? firstColor : secondColor);

export const choosedContrast = (
  contrasts: string[],
  name,
  firstColor,
  secondColor
) => (contrasts.some((c) => c === name) ? firstColor : secondColor);

export const getSideBackgroundColor = (name: string) =>
  name === "contrast1" || name === "contrast2" || name === "contrast3"
    ? "#000"
    : name === "contrast4"
    ? "#00ff00"
    : name === "contrast5"
    ? "#ffff00"
    : name === "contrast6"
    ? "#0000ff"
    : "#fff";
