export const getCookieBgColor = (theme?: string) => {
  const bgColors = {
    contrast1: "#FFF000",
    contrast2: "#FFFFFF",
    contrast3: "#00FF00",
    contrast4: "#000000",
    contrast5: "#0000FF",
    contrast6: "#FFF000",
  };

  return (theme && bgColors[theme]) ?? "#000";
};

export const getCookieTextColor = (theme?: string, defaultColor?: string) => {
  const bgColors = {
    contrast1: "#000000",
    contrast2: "#000000",
    contrast3: "#000000",
    contrast4: "#00ff00",
    contrast5: "#FFF000",
    contrast6: "#0000ff",
  };

  return (theme && bgColors[theme]) ?? defaultColor;
};
