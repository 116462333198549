import React from "react";

import { ManagerCard } from "./Manager";

import * as S from "./styles";

const Managers = (props) => {
  const { items, translation } = props.data;

  return (
    <S.ManagersWrapper>
      {items.map((item, index) => (
        <ManagerCard key={index} {...{ item, index, translation }} />
      ))}
    </S.ManagersWrapper>
  );
};

export default Managers;
