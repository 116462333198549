import React from "react";

import {
  LogoWrapper,
  MainWrapper,
  SubTitle,
  Title,
  TitlesWrapper,
} from "@components/Footer/styles";

export const Main = ({ data, fontSizeMultiplier, accent }) => {
  return (
    <MainWrapper
      style={{
        display: "flex",
        gap: 20,
        justifyContent: "space-around",
        flexWrap: "wrap",
      }}
    >
      <LogoWrapper
        style={{
          width: 150,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={data?.logo?.url}
          alt={data?.logo?.title}
          width={74}
          height={74}
        />
      </LogoWrapper>
      <TitlesWrapper>
        <SubTitle
          {...{
            fontSize: fontSizeMultiplier! * 11,
            color: accent,
          }}
        >
          {data?.bip}
        </SubTitle>
        <Title
          {...{
            fontSize: fontSizeMultiplier! * 15,
            color: accent,
          }}
        >
          {data?.office}
        </Title>
      </TitlesWrapper>
    </MainWrapper>
  );
};
