import { CustomLink } from "shared/CustomLink";

import styled from "styled-components";

import { MEDIA_QUERIES } from "@utils/constants";

export const Wrapper = styled.div`
  width: 28%;
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: hidden;
  box-shadow: rgb(136 136 136 / 20%) 1.414px 1.414px 11.28px 0.72px;
  font-family: Rubik, sans-serif;
  color: ${({ color }) => color};

  @media (max-width: ${MEDIA_QUERIES.lg}) {
    width: calc(50% - 10px);
  }

  @media (max-width: ${MEDIA_QUERIES.md}) {
    width: 100%;
  }
`;

export const Inner = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  padding: 20px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2);
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h3<{ fontSize: number }>`
  text-transform: uppercase;
  font-weight: 400;
  fontsize: ${({ fontSize }) => `${fontSize}px`};
  line-height: 18px;
  letter-spacing: 1.5px;
  text-decoration: none;
  display: block;
  margin: 12px 0 20px 0;
`;

export const Content = styled.div<{ type: number }>`
  display: flex;
  margin: ${({ type }) =>
    type === 80 ? "15px 0 15px 0" : type === 70 ? "5px 0 15px 0" : "10px 0"};
  min-width: 40px;
  flex-wrap: wrap;
`;

export const DisplayText = styled.span<{
  fontSize: number;
  isDisplayText: boolean;
}>`
  font-weight: 700;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  letter-spacing: 0.25px;
  text-decoration: none;
  margin-right: ${({ isDisplayText }) => (isDisplayText ? "5px" : "0")};
`;

export const Value = styled.span<{ fontSize: number }>`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  letter-spacing: 0.25px;
  text-decoration: none;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
`;

export const MoreInfoLink = styled(CustomLink)<{
  color: string;
  fontSize: number;
}>`
  color: ${({ color }) => color};
  text-decoration: underline;
  text-transform: uppercase;
  display: block;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  letter-spacing: 0.75px;
  white-space: nowrap;
  font-weight: 500;
`;

export const ValueLink = styled(CustomLink)`
  color: ${({ color }) => color};
`;
