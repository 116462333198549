import styled from "styled-components";

export const FormGroup = styled.div<{
  color: string;
  fontSize: number;
  bgColor: string;
}>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};

  select {
    font-size: ${({ fontSize }) => `${fontSize}px`};
    color: ${({ color }) => color};
    background-color: ${({ bgColor }) => bgColor};
  }

  @media screen and (max-width: 600px) {
    margin: 15px 0 30px 0;
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;

export const FormGroupLabel = styled.label`
  margin-right: 5px;
`;

export const SelectContainer = styled.div<{ borderColor: string }>`
  border: ${({ borderColor }) => `1.5px solid ${borderColor}`};
  border-radius: 2px;
  overflow: hidden;
  display: inline-block;
`;

export const Select = styled.select`
  padding: 5px;

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
`;
