import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from 'baseui/modal';

import { generateText } from './utils';

export default ({ isFailureModalOpen, setIsFailureModalOpen, actionType, setIsModalClicked }) => {
	const text = generateText(actionType);

	return (
		<Modal
			onClose={() => {
				setIsModalClicked(true);
				setIsFailureModalOpen(false);
			}}
			closeable
			isOpen={isFailureModalOpen}
			animate
			autoFocus
			size={SIZE.default}
			role={ROLE.dialog}
		>
			<ModalHeader>Błąd!</ModalHeader>
			<ModalBody>{text}</ModalBody>
			<ModalFooter>
				<ModalButton
					onClick={() => {
						setIsModalClicked(true);
						setIsFailureModalOpen(false);
					}}
				>
					Ok
				</ModalButton>
			</ModalFooter>
		</Modal>
	);
};
