import React from "react";
import { useLocation } from "react-router-dom";

import { useStyletron } from "baseui";
import queryString from "query-string";

import { Main } from "@components/Footer/Main/Main";
import { Office } from "@components/Footer/Office/Office";
import { Information } from "@components/Footer/Information/Information";
import { Statistics } from "@components/Footer/Statistics/Statistics";
import { Hosting } from "@components/Footer/Hosting/Hosting";
import { EuProject } from "@components/Footer/UEProject/UEProject";

import { useAccessibility } from "@contexts/AccessibilityContext";

import { NFooter } from "@namespace/Footer";
import { NInit } from "@namespace/Init";

import {
  CMSContainer,
  Content,
  ContentInner,
  Inner,
  Row,
  Wrapper,
} from "./styles";

type IFooterProps = {
  data: NFooter.TFooter;
  ueProject: NInit.IUEProject[];
};

const Footer = (props: IFooterProps) => {
  const { data, ueProject } = props;

  const [, theme] = useStyletron();

  const { search } = useLocation();

  const { lang } = queryString.parse(search);

  const { fontSizeMultiplier } = useAccessibility();

  const {
    colors: { backgroundAccent, accent },
  } = theme;

  const mainData = data?.find(({ type }) => type === 10) as NFooter.IMain;
  const informationData = data?.find(
    ({ type }) => type === 40
  ) as NFooter.IInformation;
  const officeData = data?.filter(
    ({ type }) => type > 10 && type < 40
  ) as NFooter.IOfficeData[];
  const statisticData = data?.find(
    ({ type }) => type === 50
  ) as NFooter.IStatistic;

  return (
    <Wrapper {...{ backgroundcolor: backgroundAccent }}>
      <Inner>
        <Row>
          {mainData ? (
            <Main
              {...{
                data: mainData,
                fontSizeMultiplier,
                accent,
              }}
            />
          ) : null}
          <Content>
            <ContentInner>
              {officeData?.map(({ title, content }) => (
                <Office
                  key={title}
                  {...{
                    title,
                    content,
                    fontSizeMultiplier,
                    accent,
                  }}
                />
              ))}
            </ContentInner>
            <ContentInner>
              {informationData ? (
                <Information
                  {...{
                    title: informationData.title,
                    links: informationData.links,
                    fontSizeMultiplier,
                    accent,
                    lang,
                  }}
                />
              ) : null}
              {statisticData ? (
                <Statistics
                  {...{
                    title: statisticData.title,
                    items: statisticData.items,
                    fontSizeMultiplier,
                    accent,
                  }}
                />
              ) : null}
            </ContentInner>
          </Content>
        </Row>
        <CMSContainer>
          <Hosting
            {...{
              fontSizeMultiplier,
              accent,
            }}
          />
          {ueProject ? <EuProject data={ueProject} /> : null}
        </CMSContainer>
      </Inner>
    </Wrapper>
  );
};

export default Footer;
