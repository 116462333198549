import React, { ReactElement, useState } from "react";
import { useCookies } from "react-cookie";
import CookieConsent from "react-cookie-consent";

import { CustomModal } from "@components/Modals/custom-modal";
import { useTheme } from "@contexts/ThemeContext";

import { getCookieBgColor, getCookieTextColor } from "./utils";

import * as S from "./styles";

interface ICookie {
  content: string;
}

export const Cookie = ({ content }: ICookie): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { theme } = useTheme();

  const [cookieValues] = useCookies(["CookieConsent"]);

  return (
    <>
      <CookieConsent
        {...{
          cookieValue: "hidden",
          visible: cookieValues.CookieConsent,
        }}
        debug={true}
        location={"bottom"}
        style={{
          background: getCookieBgColor(theme?.name),
          alignItems: "center",
          justifyContent: "center",
          color: getCookieTextColor(theme?.name, "#fff"),
        }}
        buttonStyle={{
          color: getCookieTextColor(theme?.name, "#fff"),
          background: getCookieBgColor(theme?.name),
        }}
        buttonText={"Zamknij"}
        contentStyle={{ textAlign: "center" }}
      >
        Strona korzysta z plików cookies w celu realizacji usług i zgodnie z
        &nbsp;
        <S.CookieLink
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
          color={getCookieTextColor(theme?.name, "#FFF000")}
          title="Polityka Cookies"
        >
          Polityką Plików Cookies
        </S.CookieLink>
        . Możesz określić warunki przechowywania lub dostępu do plików cookies w
        Twojej przeglądarce
      </CookieConsent>
      <CustomModal
        {...{
          isOpen,
          size: "md",
          setIsOpen: () => setIsOpen(false),
          content,
          title: "",
        }}
      />
    </>
  );
};
