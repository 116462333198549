import React from "react";

import { useStyletron } from "baseui";
import { useAccessibility } from "@contexts/AccessibilityContext";
import { isServer } from "@helpers/ssr";

import { NArticle } from "@namespace/Article";

import {
  AttachmentItem,
  AttachmentLink,
  List,
  Size,
  Image,
  Inner,
  Label,
  Wrapper,
} from "./styles";

interface IAttachmentsProps {
  attachments: NArticle.IAttachment[];
  translation: NArticle.ITranslationProps;
}

export const Attachments = ({
  attachments,
  translation,
}: IAttachmentsProps) => {
  const [, theme] = useStyletron();
  const { fontSizeMultiplier } = useAccessibility();

  return (
    <Wrapper>
      <Label
        {...{
          color: theme.colors.accent50,
          fontSize: fontSizeMultiplier! * 11,
          borderColor: theme.colors.backgroundInv,
        }}
      >
        {translation?.listNames?.attachments}
      </Label>
      <List>
        {attachments.map(({ displayName, displayText, url, size, id }) => (
          <AttachmentItem key={id} borderColor={theme.colors.backgroundInv}>
            <AttachmentLink
              {...{
                url,
                title: displayText,
                isDisableConfirm: true,
              }}
            >
              <Inner
                {...{
                  color: theme.colors.primary,
                  fontSize: fontSizeMultiplier! * 11,
                }}
              >
                <Image
                  {...{
                    alt: "Link",
                    height: 20,
                    width: 20,
                    src:
                      (isServer() ? "/" : "") +
                      require("../../assets/icons/attachment.svg"),
                  }}
                />
                {displayName}
              </Inner>
              <Size
                {...{
                  color: theme.colors.accent50,
                  fontSize: fontSizeMultiplier! * 11,
                }}
              >
                {size}
              </Size>
            </AttachmentLink>
          </AttachmentItem>
        ))}
      </List>
    </Wrapper>
  );
};
