import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";

import { Input, SIZE as SIZEI } from "baseui/input";
import { useStyletron } from "baseui";
import { StatefulPopover } from "baseui/popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAccessibility } from "@contexts/AccessibilityContext";

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

import { getPagesParams } from "./helpers";

import { NSearch } from "@namespace/Search";

import {
  getPageItemContrast1,
  getPageItemContrast2,
  numberPages,
} from "./utils";

import * as S from "./styles";

type TPagination = {
  startDate?: Date | null;
  endDate?: Date | null;
  phrase?: string;
  bookmark?: any;
  translation: NSearch.ITranslation;
  headers: any;
  params?: Record<string, any>;
  searchInto?: string;
  sortBy?: string | number;
};

export const Pagination = ({
  startDate,
  endDate,
  phrase,
  bookmark,
  translation,
  headers,
  params,
  searchInto,
  sortBy,
}: TPagination) => {
  const { push } = useHistory();

  const { search } = useLocation();
  const [, theme] = useStyletron();

  const {
    page = 0,
    size = translation?.pagination?.size?.[0] || 10,
    searchInto: searchIntoParam,
    displayBy,
  } = parse(search, { parseNumbers: true });

  const [pageValue, setPageValue] = useState<number>((page && +page + 1) || 1);
  const [pages, setPages] = useState<number[]>([0]);
  const { fontSizeMultiplier } = useAccessibility();
  const { lang = "PL", searchIntoArchive } = parse(search);

  const handleInputSearch = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const currentPageValue =
      pageValue > pages.length
        ? pages.length - 1
        : pageValue < 1
        ? 0
        : +pageValue - 1;

    if ((e.charCode || e.keyCode) === 13) {
      onSearch(currentPageValue, size);
    }
  };

  const onSearch = (
    page: string | number | (string | number)[] | null,
    size: string | number | (string | number)[] | null
  ) => {
    let queryParams = {
      lang,
      page,
      size,
      from: startDate ? startDate.toISOString() : undefined,
      to: endDate ? endDate.toISOString() : undefined,
      searchPhrase: phrase,
      bookmarks:
        bookmark && bookmark.length > 0
          ? bookmark[0].id
          : translation.filterBox?.bookmarks?.items[0].value,
      searchIntoArchive,
      searchInto: searchIntoParam || searchInto,
      displayBy,
      sortBy: sortBy || 0,
      ...params,
    };

    push({
      search: `?${stringify(queryParams, { arrayFormat: "index" })}`,
    });
  };

  const {
    colors: {
      primary,
      accent50,
      backgroundPrimary,
      backgroundInversePrimary,
      contentInverseTertiary,
    },
  } = theme;

  useEffect(() => {
    setPages(Array.from(Array(numberPages(headers, size)).keys()));
  }, [size, headers]);

  return (
    <S.Wrapper>
      <S.PageSizeWrapper>
        <S.PageSizeLabel
          {...{
            color: contentInverseTertiary,
            fontSize: fontSizeMultiplier! * 11,
          }}
        >
          {translation?.pagination?.show}:
        </S.PageSizeLabel>
        <S.PageSizeButtonWrapper>
          {(translation?.pagination?.size ?? []).map(
            (value: number, index: number) => (
              <S.PageSizeButton
                {...{
                  size: "compact",
                  key: index,
                  fontSize: fontSizeMultiplier! * 12,
                  color:
                    theme.name.indexOf("contrast") === -1 && size !== value
                      ? primary
                      : size !== value
                      ? getPageItemContrast2(theme.name)
                      : getPageItemContrast1(theme.name),
                  bgColor:
                    theme.name.indexOf("contrast") === -1 && size !== value
                      ? "#ffffff"
                      : size !== value
                      ? getPageItemContrast1(theme.name)
                      : theme.name.indexOf("contrast") === -1
                      ? primary
                      : getPageItemContrast2(theme.name),
                  onClick: () => {
                    onSearch(0, value);
                    window.scrollTo(0, 0);
                  },
                }}
              >
                {value}
              </S.PageSizeButton>
            )
          )}
        </S.PageSizeButtonWrapper>
      </S.PageSizeWrapper>
      <S.PageItemWrapper>
        <S.ArrowWrapperLeft
          {...{
            onClick: () =>
              onSearch(
                page && (page as number) >= 1 ? (page as number) - 1 : page,
                size
              ),
            overrides: {
              Block: {
                props: {
                  "aria-label": translation?.pagination?.first,
                },
                style: () => ({
                  marginRight: "4px",
                }),
              },
            },
          }}
        >
          <FontAwesomeIcon
            {...{
              [" aria-label"]: translation?.pagination?.first,
              color: primary,
              icon: faAngleDoubleLeft,
              size: "1x",
            }}
          />
        </S.ArrowWrapperLeft>
        {pages.length > 5
          ? getPagesParams(pages, page).map((pageParams, index) => {
              if (pageParams.isInput) {
                return (
                  <StatefulPopover
                    key={index}
                    content={() => (
                      <Input
                        value={`${pageValue}`}
                        onChange={(e) => setPageValue(+e.currentTarget.value)}
                        onKeyDown={(e) => handleInputSearch(e)}
                        clearable={false}
                        type="number"
                        min={1}
                        max={pages.length}
                        size={SIZEI.compact}
                        overrides={{
                          Root: {
                            style: () => ({
                              boxShadow:
                                "0.707px 0.707px 11.28px 0.72px rgba(37, 37, 37, 0.1)",
                              borderRadius: "5px",
                              overflow: "hidden",
                              width: "50px",
                            }),
                          },
                          Input: {
                            style: () => ({
                              fontSize: fontSizeMultiplier! * 11 + "px",
                              padding: "8px",
                              lineHeight: "11px",
                              outline: backgroundPrimary + " auto 1px",
                              color: accent50,
                            }),
                          },
                          InputContainer: {
                            style: () => ({
                              borderTopLeftRadius: "5px",
                              borderBottomLeftRadius: "5px",
                              backgroundColor: backgroundPrimary,
                              borderColor: backgroundPrimary,
                              ":focus-within": {
                                borderColor: backgroundInversePrimary,
                              },
                            }),
                          },
                          EndEnhancer: {
                            style: () => ({
                              background: backgroundPrimary,
                              padding: 0,
                            }),
                          },
                        }}
                      />
                    )}
                    returnFocus
                    autoFocus={false}
                  >
                    <S.PageItem
                      {...{
                        size: "compact",
                        isCurrent: page === pageParams.index,
                        color:
                          theme.name.indexOf("contrast") === -1 &&
                          page !== pageParams.index
                            ? primary
                            : page !== pageParams.index
                            ? getPageItemContrast2(theme.name)
                            : getPageItemContrast1(theme.name),
                        bgColor:
                          theme.name.indexOf("contrast") === -1 &&
                          page !== pageParams.index
                            ? "#ffffff"
                            : page !== pageParams.index
                            ? getPageItemContrast1(theme.name)
                            : theme.name.indexOf("contrast") === -1
                            ? primary
                            : getPageItemContrast2(theme.name),
                        fontSize: fontSizeMultiplier! * 11,
                      }}
                    >
                      ...
                    </S.PageItem>
                  </StatefulPopover>
                );
              }
              return (
                <S.PageItem
                  key={index}
                  {...{
                    size: "compact",
                    color:
                      theme.name.indexOf("contrast") === -1 &&
                      page !== pageParams.index
                        ? primary
                        : page !== pageParams.index
                        ? getPageItemContrast2(theme.name)
                        : getPageItemContrast1(theme.name),

                    bgColor:
                      theme.name.indexOf("contrast") === -1 &&
                      page !== pageParams.index
                        ? "#ffffff"
                        : page !== pageParams.index
                        ? getPageItemContrast1(theme.name)
                        : theme.name.indexOf("contrast") === -1
                        ? primary
                        : getPageItemContrast2(theme.name),
                    fontSize: fontSizeMultiplier! * 11,
                    onClick: () => onSearch(pageParams.index, size),
                  }}
                >
                  {typeof pageParams.index === "number"
                    ? pageParams.index + 1
                    : 1}
                </S.PageItem>
              );
            })
          : pages.map((pageValue, index) => {
              return (
                <S.PageItem
                  key={index}
                  {...{
                    size: "compact",
                    color:
                      theme.name.indexOf("contrast") === -1 && page !== index
                        ? primary
                        : page !== index
                        ? getPageItemContrast2(theme.name)
                        : getPageItemContrast1(theme.name),
                    bgColor:
                      theme.name.indexOf("contrast") === -1 && page !== index
                        ? "#ffffff"
                        : page !== index
                        ? getPageItemContrast1(theme.name)
                        : theme.name.indexOf("contrast") === -1
                        ? primary
                        : getPageItemContrast2(theme.name),
                    fontSize: fontSizeMultiplier! * 11,
                    onClick: () => onSearch(index, size),
                  }}
                >
                  {index + 1}
                </S.PageItem>
              );
            })}
        <S.ArrowWrapperRight
          {...{
            onClick: () =>
              onSearch(
                page === pages.length - 1 ? page : (page as number) + 1,
                size
              ),
            overrides: {
              Block: {
                props: {
                  "aria-label": translation?.pagination?.last,
                },
                style: () => ({
                  marginLeft: "4px",
                }),
              },
            },
          }}
        >
          <FontAwesomeIcon
            {...{
              color: primary,
              ["aria-label"]: translation?.pagination?.last,
              icon: faAngleDoubleRight,
              size: "1x",
              style: { height: "14px", width: "19px" },
            }}
          />
        </S.ArrowWrapperRight>
      </S.PageItemWrapper>
    </S.Wrapper>
  );
};
