import { NAppLayout } from "@namespace/AppLayout";

export const SKIP_LINKS_ARRAY = [
  {
    label: "Przejdź do treści",
    id: "main",
    isMobile: true,
    isDesktop: true,
  },

  {
    label: "Przejdź do menu pionowego" as "Przejdź do menu pionowego",
    id: "vertical-menu-first-item" as "vertical-menu-first-item",
    isMobile: false,
    isDesktop: true,
  },
  {
    label: "Przejdź do menu poziomego" as "Przejdź do menu poziomego",
    id: "horizontal-menu" as "horizontal-menu",
    isMobile: false,
    isDesktop: true,
  },
  {
    label: "Przejdź do menu",
    id: "hamburger",
    isMobile: true,
    isDesktop: false,
  },
  {
    label: "Przejdź do wyszukiwarki",
    id: "search-input",
    isMobile: false,
    isDesktop: true,
  },
  {
    label: "Przejdź do wyszukiwarki",
    id: "search-input-mobile",
    isMobile: true,
    isDesktop: false,
  },
];

export const focusedOnElement = (element: string) => {
  let ROOT_ID = document.getElementById(element);

  if (ROOT_ID) {
    const rootRect = ROOT_ID.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    ROOT_ID?.focus();

    window.scrollTo({
      top: rootRect.top + scrollTop - 20,
      behavior: "smooth",
    });
  }
};

export const getBorderAndColor = (theme: string | undefined) =>
  theme === "contrast1"
    ? "#FFF000"
    : theme === "contrast3" || theme === "contrast4"
    ? "#00ff00"
    : theme === "contrast5"
    ? "#0000ff"
    : theme === "contrast6"
    ? "#ffff00"
    : "#fff";

export const getBackground = (theme: string | undefined) =>
  theme === "contrast5"
    ? "#ffff00"
    : theme === "contrast6"
    ? "#0000ff"
    : "#000";

export const getTextColor = (theme: string | undefined) =>
  theme === "contrast1" || theme === "contrast6"
    ? "#ffff00"
    : theme === "contrast2"
    ? "#fff"
    : theme === "contrast3"
    ? "#00ff00"
    : theme === "contrast4"
    ? "#000"
    : theme === "contrast5"
    ? "#0000ff"
    : "#000";

export const getCookieBgColor = (theme?: string) => {
  const bgColors = {
    standard: "#000000",
    contrast1: "#FFF000",
    contrast2: "#FFFFFF",
    contrast3: "#00FF00",
    contrast4: "#000000",
    contrast5: "#0000FF",
    contrast6: "#FFF000",
  };

  return theme ? bgColors[theme] : "#000";
};

export const getCookieTextColor = (theme?: string) => {
  const bgColors = {
    standard: "#FFFFFF",
    contrast1: "#000000",
    contrast2: "#000000",
    contrast3: "#000000",
    contrast4: "#00ff00",
    contrast5: "#FFF000",
    contrast6: "#0000ff",
  };

  return theme ? bgColors[theme] : "#000";
};
