import React from "react";

import { useStyletron } from "baseui";
import { useLocation } from "react-router-dom";

import queryString from "query-string";

import { useAccessibility } from "@contexts/AccessibilityContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { processSlug } from "@utils/process-slug";

import { NNavigation } from "@namespace/Navigation";

import {
  BreadcrumbListItem,
  BreadcrumbIconWrapper,
  LastBreadcrumbListItem,
  BreadcrumbLink,
} from "./styles";

interface IBreadcrumbItemProps {
  item: NNavigation.TBreadCrumbs;
  isFirst: boolean;
  isLast: boolean;
}

export const BreadcrumbItem = ({
  item,
  isFirst,
  isLast,
}: IBreadcrumbItemProps) => {
  const { displayText, title, target, url, slug } = item;

  const [, theme] = useStyletron();
  const { search } = useLocation();

  const { fontSizeMultiplier } = useAccessibility();

  const {
    colors: { breadcrumbsText, primary, primaryB },
  } = theme;

  const { lang } = queryString.parse(search);

  return (
    <BreadcrumbListItem fontSize={fontSizeMultiplier! * 11}>
      {isFirst ? null : (
        <BreadcrumbIconWrapper>
          <FontAwesomeIcon
            {...{
              icon: faChevronRight,
              color: primary,
            }}
          />
        </BreadcrumbIconWrapper>
      )}
      {isLast ? (
        <LastBreadcrumbListItem color={breadcrumbsText}>
          {displayText}
        </LastBreadcrumbListItem>
      ) : (
        <BreadcrumbLink
          {...{
            slug: processSlug(slug, lang),
            target: target === 20 ? "_blank" : "_self",
            title,
            url,
            color: (isFirst ? primaryB : primary) + " !important",
          }}
        >
          {displayText}
        </BreadcrumbLink>
      )}
    </BreadcrumbListItem>
  );
};
