import { CustomLink } from "shared/CustomLink";

import styled from "styled-components";

export const MetricLink = styled(CustomLink)`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  word-break: break-word;
`;

export const Value = styled.span`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  word-break: break-word;
`;
