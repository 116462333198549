import React, { useEffect, useRef, useState } from "react";

import { Block } from "baseui/block";
import { Button, KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import {
  H3,
  LabelXSmall,
  LabelSmall,
  LabelMedium,
  Label4,
  Paragraph4,
  Label3,
} from "baseui/typography";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import queryString from "query-string";
import { Input, SIZE as SIZEI } from "baseui/input";
import { Modal, SIZE as SIZEM } from "baseui/modal";
import { useStyletron } from "baseui";
import Datepicker from "../../components/Datepicker/Datepicker";
import { TimePicker } from "baseui/timepicker";
import { Select } from "baseui/select";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { useAccessibility } from "../../contexts/AccessibilityContext";
import { format, startOfDay, endOfDay } from "date-fns";
import useAxios from "axios-hooks";
import { isServer } from "../../helpers/ssr";
import { rewriteUrl } from "../../helpers/client-axios";
import { useReactToPrint } from "react-to-print";
import { Pagination } from "@containers/Pagination";
import { getQueryString } from "@utils/get-query-string";
import { NInit } from "@namespace/Init";

type CProps = {
  data: any;
  initData: any;
  response: any;
  layout: any;
  appSettings: NInit.IAppSettings;
};

const Changelog: React.FC<CProps> = (props) => {
  const { legend, items, translation } = props?.data;
  const { formatDate, formatDateTime } = props?.layout;
  const { search } = useLocation();
  const { size } = queryString.parse(search, {
    parseNumbers: true,
  });
  const { lang = "PL", from, to, editor } = queryString.parse(search);
  const { actionTypes, changeTypes } = queryString.parse(search, {
    arrayFormat: "index",
  });
  const history = useHistory();

  const articleContentQuickLookModalRef = useRef(null);

  const handlePrintArticleContentQuickLookModal = useReactToPrint({
    content: () => articleContentQuickLookModalRef.current,
    pageStyle:
      "#articleContentQuickLookModal { padding: 30px, width: 100vw !important } h3 { font-size: 28px; font-weight: 700 } #articleContentQuickLookModal > div:first-child { border-bottom: 1px solid rgb(225, 225, 225); padding-bottom: 15px } #articleContentQuickLookModal > div:first-child > * { width: 100%; margin: 0px 30px 10px 0px } #articleContentQuickLookModal > div:last-child p { padding: 10px 0px 5px 0px !important; } ",
  });

  const [css, theme] = useStyletron();

  const [startDate, setStartDate] = useState(
    from ? new Date(from as string) : startOfDay(new Date())
  );
  const [endDate, setEndDate] = useState(
    to ? new Date(to as string) : endOfDay(new Date())
  );

  const [actionFilter, setActionFilter] = useState<any>(
    actionTypes ? Array.from(actionTypes, (x) => new Object({ id: x })) : []
  );

  const [changeFilter, setChangeFilter] = useState<any>(
    changeTypes ? Array.from(changeTypes, (x) => new Object({ id: x })) : []
  );

  const [editorFilter, setEditorFilter] = useState<any>(editor || "");

  const { headers } = props?.response;

  const [isQuickLookModalOpened, setIsQuickLookModalOpened] = useState(false);

  const { fontSizeMultiplier } = useAccessibility();

  const [{ data: articleVersionQueryData }, doArticleVersionQuery] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const makeArticleVersionQuery = (path: any) => {
    doArticleVersionQuery({
      url: `${
        isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
      }/page-content/${path}`,
    }).then(() => setIsQuickLookModalOpened(true));
  };

  useEffect(() => {
    if (actionFilter[0]?.id !== "20" && !!actionFilter.length) {
      setChangeFilter([{ label: "Dokument", id: "10" }]);
    }
  }, [actionFilter]);

  return (
    <div>
      <Block
        overrides={{
          Block: {
            style: () => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "stretch",
              flexWrap: "wrap",
            }),
          },
        }}
      >
        <Block
          width={["100%", "100%", "100%", "100"]}
          marginBottom={["20px", "20px", "0", "0"]}
          overrides={{
            Block: {
              style: () => ({
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "stretch",
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  backgroundColor: theme.colors.backgroundLightAccent,
                  padding: "30px 30px",
                  width: "100%",
                  borderRadius: "3px",
                  boxShadow:
                    "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                  textDecoration: "none",
                  position: "relative",
                  boxSizing: "border-box",
                }),
              },
            }}
          >
            <H3
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.primary,
                    margin: "0px 0px 5px",
                    fontSize: fontSizeMultiplier! * 18 + "px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }),
                },
              }}
            >
              {translation?.title}
            </H3>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    flexWrap: "wrap",
                  }),
                },
              }}
            >
              <Block width={["100%", "100%", "100%", "58%"]}>
                <LabelSmall
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "20px 0px 20px",
                        color: theme.colors.accent50,
                        fontWeight: 500,
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        letterSpacing: ".25px",
                        textDecoration: "none",
                      }),
                    },
                  }}
                >
                  {translation?.filterBox?.title}
                </LabelSmall>
                <div className={css({ display: "flex", alignItems: "center" })}>
                  <LabelXSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          color: theme.colors.accent50,
                          fontWeight: 400,
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          letterSpacing: ".25px",
                          textDecoration: "none",
                          width: "75px",
                        }),
                      },
                    }}
                  >
                    {translation?.filterBox?.dateFrom}
                  </LabelXSmall>
                  <div
                    className={css({
                      width: "120px",
                      marginRight: theme.sizing.scale300,
                    })}
                  >
                    <Datepicker
                      value={startDate}
                      onChange={(a: { date: Date }) => setStartDate(a.date)}
                      maxDate={endDate}
                      lang={lang}
                      formatString={formatDate}
                      aria-label="start date"
                      overrides={{
                        Input: {
                          props: {
                            size: SIZEI.compact,
                            overrides: {
                              Input: {
                                style: {
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  lineHeight: "11px",
                                  padding: "8px 8px",
                                  backgroundColor:
                                    theme.colors.backgroundSecondary,
                                  color: theme.colors.accent50,
                                },
                              },
                              InputContainer: {
                                style: {
                                  borderRadius: "3px",
                                  boxShadow:
                                    "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                                  border: `1px solid ${theme.colors.borderSelected}`,
                                  minWidth: "unset",
                                },
                              },
                              ClearIcon: {
                                style: () => ({
                                  color: theme.colors.primary,
                                }),
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                  <div
                    className={css({
                      width: "120px",
                      marginRight: theme.sizing.scale300,
                    })}
                  >
                    <TimePicker
                      error={endDate < startDate || startDate > endDate}
                      value={new Date(startDate.toISOString())}
                      onChange={(time) => setStartDate(time)}
                      format="24"
                      overrides={{
                        Select: {
                          props: {
                            searchable: false,
                            size: SIZEI.compact,
                            overrides: {
                              ValueContainer: {
                                style: {
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  lineHeight: "11px",
                                  padding: "8px 8px",
                                  backgroundColor:
                                    theme.colors.backgroundSecondary,
                                  color: theme.colors.accent50,
                                  height: "auto",
                                },
                              },
                              SingleValue: {
                                style: {
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  lineHeight: "11px",
                                  height: "auto",
                                },
                              },
                              ControlContainer: {
                                style: {
                                  borderRadius: "3px",
                                  boxShadow:
                                    "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                                  border: `1px solid ${theme.colors.borderSelected}`,
                                  minWidth: "unset",
                                  backgroundColor: `${theme.colors.backgroundSecondary}`,
                                },
                              },
                              InputContainer: {
                                style: {
                                  height: "0px",
                                },
                              },
                              SelectArrow: {
                                style: () => ({
                                  color: theme.colors.primary,
                                }),
                              },
                              Root: {
                                style: {
                                  backgroundColor: `${theme.colors.backgroundSecondary}`,
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>

                <div
                  className={css({
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <LabelXSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          color: theme.colors.accent50,
                          fontWeight: 400,
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          letterSpacing: ".25px",
                          textDecoration: "none",
                          width: "75px",
                        }),
                      },
                    }}
                  >
                    {translation?.filterBox?.dateTo}
                  </LabelXSmall>
                  <div
                    className={css({
                      width: "120px",
                      marginRight: theme.sizing.scale300,
                    })}
                  >
                    <Datepicker
                      lang={lang}
                      value={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      onChange={(a: { date: Date }) => setEndDate(a.date)}
                      formatString={formatDate}
                      aria-label="end date"
                      overrides={{
                        Input: {
                          props: {
                            size: SIZEI.compact,
                            overrides: {
                              Input: {
                                style: {
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  lineHeight: "11px",
                                  padding: "8px 8px",
                                  backgroundColor:
                                    theme.colors.backgroundSecondary,
                                  color: theme.colors.accent50,
                                },
                              },
                              InputContainer: {
                                style: {
                                  borderRadius: "3px",
                                  boxShadow:
                                    "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                                  border: `1px solid ${theme.colors.borderSelected}`,
                                  minWidth: "unset",
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                  <div
                    className={css({
                      width: "120px",
                    })}
                  >
                    <TimePicker
                      error={endDate < startDate || startDate > endDate}
                      value={endDate}
                      onChange={(time) => setEndDate(time)}
                      format="24"
                      overrides={{
                        Select: {
                          props: {
                            searchable: false,
                            size: SIZEI.compact,
                            overrides: {
                              ValueContainer: {
                                style: {
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  lineHeight: "11px",
                                  padding: "8px 8px",
                                  backgroundColor:
                                    theme.colors.backgroundSecondary,
                                  color: theme.colors.accent50,
                                  height: "auto",
                                },
                              },
                              SingleValue: {
                                style: {
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  lineHeight: "11px",
                                  height: "auto",
                                },
                              },
                              ControlContainer: {
                                style: {
                                  borderRadius: "3px",
                                  boxShadow:
                                    "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                                  border: `1px solid ${theme.colors.borderSelected}`,
                                  minWidth: "unset",
                                  backgroundColor: `${theme.colors.backgroundSecondary}`,
                                },
                              },
                              InputContainer: {
                                style: {
                                  height: "0px",
                                },
                              },
                              SelectArrow: {
                                style: () => ({
                                  color: theme.colors.primary,
                                }),
                              },
                              Root: {
                                style: {
                                  backgroundColor: `${theme.colors.backgroundSecondary}`,
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>

                <div
                  className={css({
                    margin: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <LabelXSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          margin: "0px 10px 0px 0px",
                          color: theme.colors.accent50,
                          fontWeight: 400,
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          letterSpacing: ".25px",
                          textDecoration: "none",
                          width: "75px",
                        }),
                      },
                    }}
                  >
                    {translation?.filterBox?.actionBox?.title}
                  </LabelXSmall>

                  {translation.filterBox?.actionBox?.items?.length > 0 && (
                    <Select
                      options={translation.filterBox?.actionBox?.items
                        ?.filter((aI: { isOption: any }) => aI.isOption)
                        .map((item) => ({ label: item.key, id: item.value }))}
                      value={actionFilter}
                      size={SIZEI.compact}
                      searchable={false}
                      placeholder={translation?.filterBox?.actionBox?.title}
                      onChange={(params) => setActionFilter(params.value)}
                      overrides={{
                        Placeholder: {
                          style: () => ({
                            color: theme.colors.accent50,
                          }),
                        },
                        SelectArrow: {
                          style: () => ({
                            color: theme.colors.primary,
                          }),
                        },
                        ClearIcon: {
                          style: () => ({
                            color: theme.colors.primary,
                          }),
                        },
                        ValueContainer: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            padding: "8px 8px",
                            backgroundColor: theme.colors.backgroundSecondary,
                            color: theme.colors.accent50,
                            height: "auto",
                          }),
                        },
                        SingleValue: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            height: "auto",
                          }),
                        },
                        ControlContainer: {
                          style: () => ({
                            borderRadius: "3px",
                            boxShadow:
                              "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                            border: `1px solid ${theme.colors.borderSelected}`,
                            minWidth: "unset",
                            backgroundColor: `${theme.colors.backgroundSecondary}`,
                          }),
                        },
                        InputContainer: {
                          style: () => ({
                            height: "0px",
                          }),
                        },
                        Root: {
                          style: () => ({
                            marginTop: "5px",
                            backgroundColor: `${theme.colors.backgroundSecondary}`,
                          }),
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  className={css({
                    margin: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <LabelXSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          margin: "0px 10px 0px 0px",
                          color: theme.colors.accent50,
                          fontWeight: 400,
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          letterSpacing: ".25px",
                          textDecoration: "none",
                          width: "75px",
                        }),
                      },
                    }}
                  >
                    {translation?.filterBox?.changeBox?.title}
                  </LabelXSmall>
                  {translation.filterBox?.changeBox?.items?.length > 0 && (
                    <Select
                      options={translation.filterBox?.changeBox?.items
                        ?.filter((aI: { isOption: any }) => aI.isOption)
                        .map((item: { key: any; value: any }) => {
                          return { label: item.key, id: item.value };
                        })}
                      value={changeFilter}
                      disabled={
                        !!actionFilter.length && actionFilter[0]?.id !== "20"
                      }
                      size={SIZEI.compact}
                      searchable={false}
                      placeholder={translation?.filterBox?.changeBox?.title}
                      onChange={(params) => setChangeFilter(params.value)}
                      overrides={{
                        Placeholder: {
                          style: () => ({
                            color: theme.colors.accent50,
                          }),
                        },
                        SelectArrow: {
                          style: () => ({
                            color: theme.colors.primary,
                          }),
                        },
                        ClearIcon: {
                          style: () => ({
                            color: theme.colors.primary,
                          }),
                        },
                        ValueContainer: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            padding: "8px 8px",
                            backgroundColor: theme.colors.backgroundSecondary,
                            color: theme.colors.accent50,
                            height: "auto",
                          }),
                        },
                        SingleValue: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            height: "auto",
                          }),
                        },
                        ControlContainer: {
                          style: () => ({
                            borderRadius: "3px",
                            boxShadow:
                              "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                            border: `1px solid ${theme.colors.borderSelected}`,
                            minWidth: "unset",
                            backgroundColor: `${theme.colors.backgroundSecondary}`,
                          }),
                        },
                        InputContainer: {
                          style: () => ({
                            height: "0px",
                          }),
                        },
                        Root: {
                          style: () => ({
                            marginTop: "5px",
                            backgroundColor: `${theme.colors.backgroundSecondary}`,
                          }),
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  className={css({
                    margin: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <LabelXSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          margin: "0px 10px 0px 0px",
                          color: theme.colors.accent50,
                          fontWeight: 400,
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          letterSpacing: ".25px",
                          textDecoration: "none",
                          width: "75px",
                        }),
                      },
                    }}
                  >
                    {translation?.filterBox?.editor}
                  </LabelXSmall>
                  <Input
                    value={editorFilter}
                    onChange={(e) => setEditorFilter(e.currentTarget.value)}
                    size={SIZEI.compact}
                    aria-label="name"
                    overrides={{
                      Input: {
                        style: () => ({
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          lineHeight: "11px",
                          padding: "8px 8px",
                          backgroundColor: theme.colors.backgroundSecondary,
                          color: theme.colors.accent50,
                        }),
                      },
                      InputContainer: {
                        style: () => ({
                          borderRadius: "3px",
                          boxShadow:
                            "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                          border: `1px solid ${theme.colors.borderSelected}`,
                          minWidth: "unset",
                        }),
                      },
                    }}
                  />
                </div>
              </Block>
              <Block
                width={["100%", "100%", "100%", "38%"]}
                paddingTop={["20px", "20px", "20px", "0px"]}
              >
                <Button
                  overrides={{
                    BaseButton: {
                      props: {
                        title: translation?.filterBox?.searchButton?.title,
                      },
                      style: ({ $theme }) => ({
                        border: "1px solid " + $theme.colors.primary,
                        boxShadow:
                          "0.707px 0.707px 11.28px 0.72px rgba(0, 200, 221, 0.2)",
                        borderRadius: "4px",
                        fontSize: fontSizeMultiplier! * 10 + "px",
                        fontWeight: 500,
                        textTransform: "uppercase",
                        padding: "9px",
                        lineHeight: "10px",
                        width: "100%",
                        letterSpacing: "2px",
                      }),
                    },
                  }}
                  onClick={() => {
                    history.push({
                      search: getQueryString({
                        lang,
                        page: 0,
                        size: props.appSettings?.pagination?.size?.[0] || 10,
                        from: startDate.toISOString(),
                        to: endDate.toISOString(),
                        editor: editorFilter,
                        actionTypes: actionFilter.map((e: { id: any }) => e.id),
                        changeTypes: changeFilter.map((e: { id: any }) => e.id),
                      }),
                    });
                  }}
                  disabled={endDate < startDate || startDate > endDate}
                >
                  {translation?.filterBox?.searchButton?.displayText}
                </Button>
              </Block>
            </Block>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    margin: "20px 0px 0px 0px",
                    borderTop: "1px solid " + theme.colors.backgroundInv,
                    padding: "20px 0px 0px 0px",
                  }),
                },
              }}
            >
              <LabelMedium
                overrides={{
                  Block: {
                    style: () => ({
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      lineHeight: "11px",
                      letterSpacing: ".25px",
                      color: theme.colors.accent50,
                      fontWeight: 500,
                    }),
                  },
                }}
              >
                <span className={css({ color: theme.colors.primary })}>
                  {translation?.filterText}
                </span>
                {from
                  ? ` ${translation?.filterBox?.dateFrom} ${
                      from && format(new Date(from as string), formatDateTime)
                    } ${translation?.filterBox?.dateTo} ${
                      to && format(new Date(to as string), formatDateTime)
                    }`
                  : ""}
              </LabelMedium>
              <div>
                {legend?.map(
                  (
                    lI: {
                      key: React.ReactNode;
                      value: React.ReactNode;
                      color: string;
                    },
                    index: number
                  ) => {
                    return (
                      <div
                        key={index}
                        className={css({
                          marginTop: "6px",
                        })}
                      >
                        <div
                          className={css({
                            width: "10px",
                            height: "10px",
                            backgroundColor:
                              theme.name.indexOf("contrast") > -1
                                ? theme.colors.primary
                                : lI.color || "#cccccc",
                            marginRight: "20px",
                            display: "inline-block",
                          })}
                        ></div>
                        <LabelXSmall
                          overrides={{
                            Block: {
                              style: () => ({
                                fontSize: fontSizeMultiplier! * 10 + "px",
                                lineHeight: "10px",
                                width: "100px",
                                display: "inline-block",
                                color:
                                  theme.name.indexOf("contrast") > -1
                                    ? theme.colors.primary
                                    : theme.colors.contentTertiary,
                              }),
                            },
                          }}
                        >
                          {lI.key}
                        </LabelXSmall>
                        <LabelXSmall
                          overrides={{
                            Block: {
                              style: () => ({
                                fontSize: fontSizeMultiplier! * 10 + "px",
                                lineHeight: "10px",
                                display: "inline-block",
                                color:
                                  theme.name.indexOf("contrast") > -1
                                    ? theme.colors.primary
                                    : lI.color || "#cccccc",
                              }),
                            },
                          }}
                        >
                          {lI.value}
                        </LabelXSmall>
                      </div>
                    );
                  }
                )}
              </div>
            </Block>
          </Block>
        </Block>
      </Block>

      {items && (
        <div
          className={css({
            marginTop: "30px",
            marginBottom: "10px",
          })}
        >
          {items.length ? (
            <>
              <TableBuilder
                data={items}
                overrides={{
                  Root: {
                    style: () => ({
                      borderRadius: "0px",
                      border: "none",
                      background: theme.colors.backgroundSecondary,
                    }),
                  },
                  Table: {
                    style: () => ({
                      overflow: "hidden",
                    }),
                  },
                  TableHeadCell: {
                    style: () => ({
                      fontSize: fontSizeMultiplier! * 10 + "px",
                      lineHeight: "10px",
                      border: "none",
                      backgroundColor: theme.colors.backgroundOverlayDark,
                      color: theme.colors.primary,
                    }),
                  },
                  TableBodyCell: {
                    style: () => ({
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      lineHeight: "14px",
                      color: theme.colors.borderInverseOpaque,
                      padding: "10px 5px",
                      borderBottom: "1px solid " + theme.colors.backgroundInv,
                    }),
                  },
                  TableBody: {
                    style: () => ({
                      border: "20px solid " + theme.colors.backgroundSecondary,
                    }),
                  },
                }}
              >
                <TableBuilderColumn header={translation?.tableHeaders[1]}>
                  {(row) => row.date}
                </TableBuilderColumn>
                <TableBuilderColumn header={translation?.tableHeaders[2]}>
                  {(row) => (
                    <span
                      className={css({
                        fontWeight: 500,
                        color:
                          theme.name !== "standard"
                            ? theme.colors.primary
                            : legend?.filter(
                                (e: { key: string }) => e.key === row.action
                              )[0].color || "inherit",
                      })}
                    >
                      {row.action}
                    </span>
                  )}
                </TableBuilderColumn>
                <TableBuilderColumn header={translation?.tableHeaders[3]}>
                  {(row) => row.editor}
                </TableBuilderColumn>
                <TableBuilderColumn header={translation?.tableHeaders[4]}>
                  {(row) =>
                    row.changeTypes.map(
                      (el: React.ReactNode, index: number) => {
                        return <div key={index}>{el}</div>;
                      }
                    )
                  }
                </TableBuilderColumn>
                <TableBuilderColumn header={translation?.tableHeaders[5]}>
                  {(row) => {
                    return (
                      <StyledLink
                        className={css({
                          textDecoration: "none",
                          color: theme.colors.primary + " !important",
                          fontWeight: 400,
                          ":visited": {
                            color: theme.colors.primary + " !important",
                          },
                          ":hover": { cursor: "pointer" },
                        })}
                        onClick={() =>
                          makeArticleVersionQuery(row.article.slug)
                        }
                        title={row.article.title}
                      >
                        {row.article.displayText}
                      </StyledLink>
                    );
                  }}
                </TableBuilderColumn>
              </TableBuilder>
              <Pagination
                {...{
                  startDate,
                  endDate,
                  translation,
                  headers,
                  params: {
                    editor: editorFilter,
                    actionTypes: actionFilter.map((e: { id: any }) => e.id),
                    changeTypes: changeFilter.map((e: { id: any }) => e.id),
                  },
                }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}
      <Modal
        size={
          !isServer() && window.innerWidth > 900 ? SIZEM.default : SIZEM.full
        }
        onClose={() => setIsQuickLookModalOpened(false)}
        isOpen={isQuickLookModalOpened}
        overrides={{
          Backdrop: {
            style: () => ({
              backgroundColor: "rgba(255,255,255,.7)",
            }),
          },
          Dialog: {
            style: () => ({
              backgroundColor: theme.colors.backgroundSecondary,
              minWidth: "55vw",
              margin: "0px",
              borderRadius: "5px",
              boxShadow:
                "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
            }),
          },
        }}
      >
        <Block
          overrides={{
            Block: {
              style: () => ({
                padding: "30px 50px 0px",
              }),
            },
          }}
        >
          <H3
            overrides={{
              Block: {
                style: ({ $theme }) => ({
                  color: $theme.colors.primary,
                  margin: "0px 0px 10px",
                  fontSize: fontSizeMultiplier! * 16 + "px",
                  lineHeight: "18px",
                  letterSpacing: "1px",
                  fontWeight: 500,
                  textTransform: "uppercase",
                }),
              },
            }}
          >
            Podgląd wersji
          </H3>
        </Block>

        <Block
          overrides={{
            Block: {
              style: () => ({
                padding: "20px 50px 30px",
              }),
            },
          }}
        >
          <div
            id="articleContentQuickLookModal"
            ref={articleContentQuickLookModalRef}
          >
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    borderBottom: "1px solid " + theme.colors.backgroundInv,
                  }),
                },
              }}
            >
              <H3
                overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.primary,
                      margin: "0px 0px 10px",
                      fontSize: fontSizeMultiplier! * 16 + "px",
                      lineHeight: "18px",
                      letterSpacing: "1px",
                      fontWeight: 500,
                    }),
                  },
                }}
              >
                {articleVersionQueryData?.contentData?.item?.title}
              </H3>
              <Label4
                overrides={{
                  Block: {
                    style: () => ({
                      color: theme.colors.accent50,
                      margin: "0px 20px 20px 0",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      lineHeight: "12px",
                      letterSpacing: "0.25px",
                      fontWeight: 400,
                      display: "inline-block",
                    }),
                  },
                }}
              >
                {articleVersionQueryData?.contentData?.item?.publishedDate}
              </Label4>
            </Block>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    padding: "0px",
                  }),
                },
              }}
            >
              <Paragraph4
                as="div"
                overrides={{
                  Block: {
                    style: () => ({
                      margin: "20px 0px 0px",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      lineHeight: "18px",
                      color: theme.colors.accent50,
                    }),
                  },
                }}
              >
                {articleVersionQueryData?.contentData?.item?.content &&
                  ReactHtmlParser(
                    articleVersionQueryData?.contentData?.item?.content
                  )}
              </Paragraph4>
            </Block>
          </div>
          <Block>
            {articleVersionQueryData?.contentData?.item?.attachments &&
              articleVersionQueryData?.contentData?.item?.attachments.length >
                0 && (
                <Block>
                  <Label3
                    overrides={{
                      Block: {
                        style: () => ({
                          marginTop: "30px",
                          color: theme.colors.accent50,
                          padding: "0px 0px 8px 0",
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          lineHeight: "20px",
                          letterSpacing: "1px",
                          fontWeight: 500,
                          textTransform: "uppercase",
                          borderBottom:
                            "1px solid " + theme.colors.backgroundInv,
                        }),
                      },
                    }}
                  >
                    {translation?.listNames?.attachments}
                  </Label3>

                  {articleVersionQueryData?.contentData?.item?.attachments.map(
                    (attachment: any, index: any) => {
                      return (
                        <Block
                          key={index}
                          as={(props) => (
                            <a href={attachment.url} {...props}>
                              {props.children}
                            </a>
                          )}
                          overrides={{
                            Block: {
                              props: {
                                title: attachment.title,
                              },
                              style: () => ({
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                textDecoration: "none",
                                alignItems: "center",
                                borderBottom:
                                  "1px solid " + theme.colors.backgroundInv,
                                padding: "10px 0",
                              }),
                            },
                          }}
                        >
                          <img
                            src={
                              (isServer() ? "/" : "") +
                              require("../../assets/icons/attachment.svg")
                            }
                            alt="Link"
                            height="20px"
                            width="20px"
                          />
                          <LabelMedium
                            overrides={{
                              Block: {
                                style: () => ({
                                  color: theme.colors.primary,
                                  margin: "0px 0px 0px 20px",
                                  textDecoration: "underline",
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  letterSpacing: ".1px",
                                  minWidth: "100px",
                                  fontWeight: 400,
                                }),
                              },
                            }}
                          >
                            {attachment.displayText}
                          </LabelMedium>
                          <LabelMedium
                            overrides={{
                              Block: {
                                style: () => ({
                                  color: theme.colors.accent50,
                                  margin: "0px 0px 0px 60px",
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  letterSpacing: ".1px",
                                  fontWeight: 400,
                                }),
                              },
                            }}
                          >
                            {attachment.size}
                          </LabelMedium>
                        </Block>
                      );
                    }
                  )}
                </Block>
              )}

            {articleVersionQueryData?.contentData?.item?.links &&
              articleVersionQueryData?.contentData?.item?.links.length > 0 && (
                <Block>
                  <Label3
                    overrides={{
                      Block: {
                        style: () => ({
                          marginTop: "30px",
                          color: theme.colors.accent50,
                          padding: "0px 0px 8px 0",
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          lineHeight: "20px",
                          letterSpacing: "1px",
                          fontWeight: 500,
                          textTransform: "uppercase",
                          borderBottom:
                            "1px solid " + theme.colors.backgroundInv,
                        }),
                      },
                    }}
                  >
                    {translation?.listNames?.links}
                  </Label3>

                  {articleVersionQueryData?.contentData?.item?.links.map(
                    (link: any, index: any) => {
                      return (
                        <Block
                          key={index}
                          as={(props) => (
                            <Link
                              to={`/${
                                link.slug + (lang ? `?lang=${lang}` : "")
                              }`}
                              {...props}
                            >
                              {props.children}
                            </Link>
                          )}
                          overrides={{
                            Block: {
                              props: {
                                title: link.title,
                              },
                              style: () => ({
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                textDecoration: "none",
                                alignItems: "center",
                                borderBottom:
                                  "1px solid " + theme.colors.backgroundInv,
                                padding: "10px 0",
                              }),
                            },
                          }}
                        >
                          <img
                            src={
                              (isServer() ? "/" : "") +
                              require("../../assets/icons/link.svg")
                            }
                            alt="Link"
                            height="20px"
                            width="20px"
                          />
                          <LabelMedium
                            overrides={{
                              Block: {
                                style: () => ({
                                  color: theme.colors.primary,
                                  margin: "0px 0px 0px 20px",
                                  textDecoration: "underline",
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  letterSpacing: ".1px",
                                  minWidth: "100px",
                                  fontWeight: 400,
                                }),
                              },
                            }}
                          >
                            {link.displayText}
                          </LabelMedium>
                        </Block>
                      );
                    }
                  )}
                </Block>
              )}
          </Block>
        </Block>
        <Block
          justifyContent={[
            "flex-start",
            "flex-start",
            "flex-start",
            "space-between",
          ]}
          overrides={{
            Block: {
              style: () => ({
                display: "flex",
                flexDirection: "row",
                padding: "0px 50px 0px",
                flexWrap: "wrap",
                backgroundColor: theme.colors.backgroundOverlayDark,
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexWrap: "wrap",
                }),
              },
            }}
          >
            <Button
              onClick={
                isServer()
                  ? () => {}
                  : () => handlePrintArticleContentQuickLookModal()
              }
              kind={KIND.tertiary}
              size={SIZE.compact}
              startEnhancer={() => (
                <img
                  height="16px"
                  alt={translation?.footer?.printButton?.displayText}
                  src={
                    (isServer() ? "/" : "") +
                    require("../../assets/icons/print.svg")
                  }
                />
              )}
              overrides={{
                BaseButton: {
                  props: {
                    title: translation?.footer?.printButton?.title,
                  },
                  style: () => ({
                    color: theme.colors.primary,
                    margin: "0px 35px 0px 0px",
                    padding: "15px 0px",
                    textTransform: "uppercase",
                    fontSize: fontSizeMultiplier! * 11 + "px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: 400,
                    lineHeight: "11px",
                    ":hover": {
                      backgroundColor: "transparent",
                      color:
                        theme.name !== "standard"
                          ? theme.colors.primary
                          : "#57aade",
                    },
                    ":focus": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                    ":active": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                    ":visited": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                  }),
                },
              }}
            >
              {translation?.footer?.printButton?.displayText}
            </Button>
            {false && (
              <Button
                onClick={() => alert("savetoPDF")}
                kind={KIND.tertiary}
                size={SIZE.compact}
                startEnhancer={() => (
                  <img
                    height="16px"
                    alt={translation?.footer?.saveToPdfButton?.displayText}
                    src={
                      (isServer() ? "/" : "") +
                      require("../../assets/icons/pdf-file.svg")
                    }
                  />
                )}
                overrides={{
                  BaseButton: {
                    props: {
                      title: translation?.footer?.saveToPdfButton?.title,
                    },
                    style: () => ({
                      color: theme.colors.primary,
                      margin: "0px 35px 0px 0px",
                      padding: "15px 0px",
                      textTransform: "uppercase",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: 400,
                      lineHeight: "11px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color:
                          theme.name !== "standard"
                            ? theme.colors.primary
                            : "#57aade",
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":active": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":visited": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                    }),
                  },
                }}
              >
                {translation?.footer?.saveToPdfButton?.displayText}
              </Button>
            )}
          </Block>
          <Block
            overrides={{
              Block: {
                style: () => ({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }),
              },
            }}
          >
            {articleVersionQueryData?.contentData?.item?.metrics && (
              <StatefulPopover
                content={() => (
                  <Block
                    minWidth={["0px", "0px", "350px", "350px"]}
                    overrides={{
                      Block: {
                        style: () => ({
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: theme.colors.backgroundSecondary,
                          padding: "10px 30px",
                          boxShadow:
                            "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                          color: theme.colors.accent50,
                        }),
                      },
                    }}
                  >
                    {articleVersionQueryData?.contentData?.item?.metrics.map(
                      (metric: any, index: any) => {
                        return (
                          <Block
                            key={index}
                            overrides={{
                              Block: {
                                style: () => ({
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  borderBottom:
                                    index !==
                                    articleVersionQueryData?.contentData?.item
                                      ?.metrics.length -
                                      1
                                      ? "1px solid " +
                                        theme.colors.backgroundInv
                                      : "none",
                                }),
                              },
                            }}
                          >
                            <Block
                              overrides={{
                                Block: {
                                  style: () => ({
                                    padding: "10px 0px",
                                    width: "33%",
                                    boxSizing: "border-box",
                                  }),
                                },
                              }}
                            >
                              <Label4
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      fontSize: fontSizeMultiplier! * 10 + "px",
                                      color: theme.colors.contentTertiary,
                                      lineHeight: "12px",
                                      fontWeight: 500,
                                      letterSpacing: ".25px",
                                    }),
                                  },
                                }}
                              >
                                {metric.key}
                              </Label4>
                            </Block>
                            <Block
                              overrides={{
                                Block: {
                                  style: () => ({
                                    padding: "10px 0px",
                                    width: "33%",
                                    boxSizing: "border-box",
                                  }),
                                },
                              }}
                            >
                              <Label4
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      fontSize: fontSizeMultiplier! * 10 + "px",
                                      color: theme.colors.contentTertiary,
                                      lineHeight: "12px",
                                      fontWeight: 400,
                                      letterSpacing: ".25px",
                                    }),
                                  },
                                }}
                              >
                                {metric.value}
                              </Label4>
                            </Block>
                            <Block
                              overrides={{
                                Block: {
                                  style: () => ({
                                    padding: "10px 0px",
                                    width: "33%",
                                    boxSizing: "border-box",
                                  }),
                                },
                              }}
                            >
                              <Label4
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      fontSize: fontSizeMultiplier! * 10 + "px",
                                      color: theme.colors.contentTertiary,
                                      lineHeight: "12px",
                                      fontWeight: 400,
                                      letterSpacing: ".25px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }),
                                  },
                                }}
                              >
                                {metric.date}
                              </Label4>
                            </Block>
                          </Block>
                        );
                      }
                    )}
                  </Block>
                )}
                placement={PLACEMENT.bottomRight}
                focusLock
                returnFocus
                autoFocus
                overrides={{
                  Body: {
                    style: () => ({
                      left:
                        !isServer() && window.innerWidth > 900 ? "50px" : "1%",
                      right:
                        !isServer() && window.innerWidth > 900 ? "unset" : "5%",
                      marginTop: "0px",
                      marginBottom: "0px",
                    }),
                  },
                }}
              >
                <Button
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                  startEnhancer={() => (
                    <img
                      height="16px"
                      alt={translation?.footer?.metricButton?.displayText}
                      src={
                        (isServer() ? "/" : "") +
                        require("../../assets/icons/info.svg")
                      }
                    />
                  )}
                  overrides={{
                    BaseButton: {
                      props: {
                        title: translation?.footer?.metricButton?.title,
                      },
                      style: () => ({
                        color: theme.colors.primary,
                        padding: "15px 0px",
                        margin: "0px 0px 0px 0px",
                        textTransform: "uppercase",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: 400,
                        lineHeight: "11px",
                        ":hover": {
                          backgroundColor: "transparent",
                          color:
                            theme.name !== "standard"
                              ? theme.colors.primary
                              : "#57aade",
                        },
                        ":focus": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                        ":active": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                        ":visited": {
                          backgroundColor: "transparent",
                          color: theme.colors.primary,
                        },
                      }),
                    },
                  }}
                >
                  {translation?.footer?.metricButton?.displayText}
                </Button>
              </StatefulPopover>
            )}
          </Block>
        </Block>
      </Modal>
    </div>
  );
};

export default Changelog;
