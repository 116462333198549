import React, { Fragment, useEffect, useState } from "react";

import { Block } from "baseui/block";
import { Grid, Cell } from "baseui/layout-grid";
import { Button, KIND, SIZE } from "baseui/button";
import { useHistory, useLocation } from "react-router";
import { StatefulPopover } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import queryString from "query-string";
import { useAccessibility } from "../../contexts/AccessibilityContext";
import { StyledLink } from "baseui/link";
import { ButtonGroup, MODE } from "baseui/button-group";
import { THEME_SET } from "../../themes";
import { useTheme } from "../../contexts/ThemeContext";
import { useStyletron } from "baseui";
// @ts-ignore
import Flag from "react-flagkit";
import { isServer } from "../../helpers/ssr";

import { serverRewriteHostname } from "@helpers/client-axios";

type TBBProps = {
  item: any;
  layoutThemeKey: string;
  init: any;
};
const FlagSize = 18;

const TopBarButton: React.FC<TBBProps> = (props) => {
  const history = useHistory();

  const { search } = useLocation();
  const [css, theme] = useStyletron();

  const { init, item, children, layoutThemeKey, ...rest } = props;
  const { icon, linkType, wcagButtonType, title, slug, items, target } = item;

  const { lang } = queryString.parse(search);

  const {
    fontSizeMultiplier,
    incrementFontSize,
    decrementFontSize,
    setStandardFontSize,
  } = useAccessibility();
  const { themeKey, setThemeKey } = useTheme();
  const [url, setUrl] = useState<string>("");

  const ThemeSet = Object.keys(THEME_SET).filter(
    (theme) => theme.includes("contrast") || theme === layoutThemeKey
  );

  useEffect(() => {
    if (init && !url) {
      setUrl(init.frontendWebAppAddress);
    }
    if (url && !url.includes("api")) {
      setUrl(serverRewriteHostname(url));
    }
  }, [init, url]);

  const {
    colors: {
      primary,
      backgroundPrimary,
      accent,
      primary100,
      primary200,
      primary300,
      primary400,
      accent100,
      accent200,
      accent300,
      accent400,
      accent500,
      accent600,
    },
  } = theme;

  if (linkType === 30) {
    return (
      <StatefulPopover
        content={({ close }) => (
          <StatefulMenu
            items={items.map((e: { displayText: string }) => ({
              label: e.displayText,
              ...e,
            }))}
            overrides={{
              Option: {
                component: (
                  e: { item: { label: React.ReactNode } },
                  index: number
                ) => {
                  return (
                    <li
                      tabIndex={0}
                      key={index}
                      className={css({
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        padding: "5px 10px",
                        cursor: "pointer",
                        ":hover": {
                          backgroundColor: backgroundPrimary,
                        },
                      })}
                      {...e}
                    >
                      <div
                        tabIndex={0}
                        role={"button"}
                        className={css({
                          width: `${FlagSize}px`,
                          height: `${FlagSize}px`,
                          borderRadius: "50%",
                          overflow: "hidden",
                          marginRight: "10px",
                          border: "1px solid gray",
                        })}
                      >
                        <div
                          tabIndex={0}
                          role={"button"}
                          className={css({
                            width: `${FlagSize}px`,
                            height: `${FlagSize}px`,
                            overflow: "hidden",
                            textAlign: "center",
                          })}
                        >
                          <Flag
                            country={e.item.label}
                            size={FlagSize}
                            alt={e.item.label}
                            style={{ width: "24px", margin: "0 0 0 -2px" }}
                          />
                        </div>
                      </div>
                      <div>{e.item.label}</div>
                    </li>
                  );
                },
              },
            }}
            onItemSelect={({ item }) => {
              history.push({
                search: `?${queryString.stringify({ lang: item.displayText })}`,
              });
              close();
            }}
          />
        )}
        returnFocus
        autoFocus
      >
        {/* PL/GB */}
        {items.filter((e: { displayText: string }) => e.displayText === lang)
          .length > 0 ? (
          <Button
            kind={KIND.tertiary}
            size={SIZE.compact}
            startEnhancer={() => (
              <div
                className={css({
                  width: `${FlagSize}px`,
                  height: `${FlagSize}px`,
                  borderRadius: "50%",
                  overflow: "hidden",
                })}
              >
                <div
                  className={css({
                    width: `${FlagSize}px`,
                    height: `${FlagSize}px`,
                    overflow: "hidden",
                    textAlign: "center",
                  })}
                >
                  <Flag
                    country={
                      items.filter(
                        (e: { displayText: string }) => e.displayText === lang
                      )[0].icon
                    }
                    size={FlagSize}
                    alt={
                      items.filter(
                        (e: { displayText: string }) => e.displayText === lang
                      )[0].displayText
                    }
                    style={{ width: "24px", margin: "0 0 0 -2px" }}
                  />
                </div>
              </div>
            )}
            overrides={{
              BaseButton: {
                style: () => {
                  return {
                    color: accent,
                    textTransform: "uppercase",
                    fontSize: fontSizeMultiplier! * 11 + "px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: 400,
                    lineHeight: "16px",
                    ":hover": {
                      backgroundColor: "transparent",
                      color: primary,
                    },
                    ":focus": {
                      backgroundColor: "transparent",
                      color: primary,
                    },
                    ":active": {
                      backgroundColor: "transparent",
                      color: primary,
                    },
                    ":visited": {
                      backgroundColor: "transparent",
                      color: primary,
                    },
                  };
                },
              },
              StartEnhancer: {
                style: () => ({
                  fontSize: fontSizeMultiplier! * 16 + "px",
                }),
              },
            }}
            {...rest}
          >
            {
              items.filter(
                (e: { displayText: string }) => e.displayText === lang
              )[0].displayText
            }
          </Button>
        ) : (
          <Button
            onClick={
              linkType === 10
                ? () =>
                    history.push({
                      pathname: `/${slug}`,
                      search: queryString.stringify({ lang }),
                    })
                : () => {}
            }
            kind={KIND.tertiary}
            size={SIZE.compact}
            startEnhancer={() => (
              <div
                className={css({
                  width: `${FlagSize}px`,
                  height: `${FlagSize}px`,
                  borderRadius: "50%",
                  overflow: "hidden",
                })}
              >
                <div
                  className={css({
                    width: "24px",
                    height: `${FlagSize}px`,
                    overflow: "hidden",
                    textAlign: "center",
                  })}
                >
                  <Flag
                    country={icon}
                    size={FlagSize}
                    alt={icon}
                    style={{ width: "24px", margin: "0 0 0 -2px" }}
                  />
                </div>
              </div>
            )}
            overrides={{
              BaseButton: {
                props: {
                  title: title,
                },
                style: () => {
                  return {
                    color: accent,
                    textTransform: "uppercase",
                    fontSize: fontSizeMultiplier! * 11 + "px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: 400,
                    lineHeight: "16px",
                    ":hover": { backgroundColor: "transparent", color: accent },
                    ":focus": { backgroundColor: "transparent", color: accent },
                    ":active": {
                      backgroundColor: "transparent",
                      color: accent,
                    },
                    ":visited": {
                      backgroundColor: "transparent",
                      color: accent,
                    },
                  };
                },
              },
              StartEnhancer: {
                style: () => ({
                  fontSize: fontSizeMultiplier! * 16 + "px",
                }),
              },
            }}
            {...rest}
          >
            {linkType === 50 ? (
              <img
                height="18px"
                width="18px"
                alt={title}
                src={
                  (isServer() ? "/" : "") +
                  require(`../../assets/icons/${icon}.svg`)
                }
              />
            ) : (
              children
            )}
          </Button>
        )}
      </StatefulPopover>
    );
  } else if (wcagButtonType === 10) {
    return (
      <StatefulPopover
        autoFocus={false}
        focusLock={false}
        returnFocus={true}
        content={({ close }) => (
          <ButtonGroup
            mode={MODE.radio}
            onClick={(event, index) => {
              setThemeKey!(ThemeSet[index]);
              close();
            }}
            selected={[ThemeSet.indexOf(themeKey!)]}
          >
            {ThemeSet.map((key) => (
              <Button
                key={key}
                overrides={{
                  BaseButton: {
                    style:
                      key === "standard"
                        ? {
                            color: primary100,
                            background: accent,
                          }
                        : key === "green"
                        ? {
                            color: primary200,
                            background: accent,
                          }
                        : key === "darkgreen"
                        ? {
                            color: primary300,
                            background: accent,
                          }
                        : key === "red"
                        ? {
                            color: primary400,
                            background: accent,
                          }
                        : key === "contrast"
                        ? {
                            color: accent100,
                            background: "black",
                          }
                        : key === "contrast2"
                        ? {
                            color: accent200,
                            background: "black",
                          }
                        : key === "contrast3"
                        ? {
                            color: accent300,
                            background: "black",
                          }
                        : key === "contrast4"
                        ? {
                            color: accent400,
                            background: "#00ff00",
                          }
                        : key === "contrast5"
                        ? {
                            color: accent500,
                            background: "#ffff00",
                          }
                        : key === "contrast6"
                        ? {
                            color: accent600,
                            background: "#0000ff",
                          }
                        : {
                            color: primary,
                            background: accent,
                          },
                  },
                }}
              >
                A
              </Button>
            ))}
          </ButtonGroup>
        )}
      >
        <Button
          onClick={
            linkType === 10
              ? () =>
                  history.push({
                    pathname: `/${slug}`,
                    search: queryString.stringify({ lang }),
                  })
              : () => {}
          }
          kind={KIND.tertiary}
          size={SIZE.compact}
          key={slug}
          startEnhancer={
            icon && !themeKey?.includes("contrast") ? (
              <img
                height="18px"
                width="18px"
                alt={title}
                src={
                  (isServer() ? "/" : "") +
                  require(`../../assets/icons/${icon}.svg`)
                }
              />
            ) : icon ? (
              <img
                height="18px"
                width="18px"
                alt={title}
                src={
                  (isServer() ? "/" : "") +
                  require(`../../assets/icons/${icon + "-" + themeKey}.svg`)
                }
              />
            ) : (
              false
            )
          }
          overrides={{
            BaseButton: {
              props: {
                title: title,
              },
              style: () => {
                return {
                  color: accent,
                  textTransform: "uppercase",
                  fontSize: fontSizeMultiplier! * 11 + "px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: 400,
                  lineHeight: "16px",
                  ":hover": { backgroundColor: "transparent", color: accent },
                  ":focus": { backgroundColor: "transparent", color: accent },
                  ":active": { backgroundColor: "transparent", color: accent },
                  ":visited": { backgroundColor: "transparent", color: accent },
                };
              },
            },
            StartEnhancer: {
              style: () => ({
                fontSize: fontSizeMultiplier! * 16 + "px",
              }),
            },
          }}
          {...rest}
        >
          {linkType === 50 ? (
            <img
              height="18px"
              alt={title}
              src={
                (isServer() ? "/" : "") +
                require(`../../assets/icons/${icon}.svg`)
              }
            />
          ) : (
            children
          )}
        </Button>
      </StatefulPopover>
    );
  } else {
    return (
      <Fragment>
        {linkType === 10 && slug === "" && item.url && url ? (
          <StyledLink
            href={`${url + "/" + item.url}`}
            target={target === 20 ? "_blank" : "_self"}
            style={{ textDecoration: "none" }}
          >
            <Button
              key={slug}
              kind={KIND.tertiary}
              size={SIZE.compact}
              startEnhancer={
                icon &&
                !(linkType === 20 && wcagButtonType > 10) &&
                !themeKey?.includes("contrast")
                  ? () => (
                      <img
                        height="18px"
                        width="18px"
                        alt={title}
                        src={
                          (isServer() ? "/" : "") +
                          require(`../../assets/icons/${icon}.svg`)
                        }
                      />
                    )
                  : icon && !(linkType === 20 && wcagButtonType > 10)
                  ? () => (
                      <img
                        height="18px"
                        width="18px"
                        alt={title}
                        src={
                          (isServer() ? "/" : "") +
                          require(`../../assets/icons/${
                            icon + "-" + themeKey
                          }.svg`)
                        }
                      />
                    )
                  : false
              }
              overrides={{
                BaseButton: {
                  props: {
                    title: title,
                  },
                  style: () => {
                    return {
                      paddingLeft:
                        linkType === 20 && wcagButtonType > 10 ? "3px" : "12px",
                      paddingRight:
                        linkType === 20 && wcagButtonType > 10 ? "3px" : "12px",
                      marginLeft:
                        linkType === 20 && wcagButtonType === 20
                          ? "12px"
                          : "0px",
                      marginRight:
                        linkType === 20 && wcagButtonType === 40
                          ? "12px"
                          : "0px",
                      color: accent,
                      textTransform: "uppercase",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: 400,
                      // height: '100%',
                      lineHeight: "16px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color: accent,
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                        color: accent,
                      },
                      ":active": {
                        backgroundColor: "transparent",
                        color: accent,
                      },
                      ":visited": {
                        backgroundColor: "transparent",
                        color: accent,
                      },
                    };
                  },
                },
                StartEnhancer: {
                  style: () => ({
                    fontSize: fontSizeMultiplier! * 16 + "px",
                  }),
                },
              }}
              {...rest}
            >
              {linkType === 20 &&
              wcagButtonType > 10 &&
              !themeKey?.includes("contrast") ? (
                <img
                  height="18px"
                  width="18px"
                  alt={title}
                  src={
                    (isServer() ? "/" : "") +
                    require(`../../assets/icons/${icon}.svg`)
                  }
                />
              ) : linkType === 20 && wcagButtonType > 10 ? (
                <img
                  height="18px"
                  width="18px"
                  alt={title}
                  src={
                    (isServer() ? "/" : "") +
                    require(`../../assets/icons/${icon + "-" + themeKey}.svg`)
                  }
                />
              ) : (
                children
              )}
            </Button>
          </StyledLink>
        ) : (
          <Button
            key={slug}
            onClick={
              linkType === 10
                ? () =>
                    history.push({
                      pathname: `/${slug}`,
                      search: queryString.stringify({ lang }),
                    })
                : () => {
                    if (wcagButtonType === 20) {
                      decrementFontSize!();
                    }
                    if (wcagButtonType === 30) {
                      setStandardFontSize!();
                    }
                    if (wcagButtonType === 40) {
                      incrementFontSize!();
                    }
                  }
            }
            kind={KIND.tertiary}
            size={SIZE.compact}
            startEnhancer={
              icon &&
              !(linkType === 20 && wcagButtonType > 10) &&
              !themeKey?.includes("contrast")
                ? () => (
                    <img
                      height="18px"
                      width="18px"
                      alt={title}
                      src={
                        (isServer() ? "/" : "") +
                        require(`../../assets/icons/${icon}.svg`)
                      }
                    />
                  )
                : icon && !(linkType === 20 && wcagButtonType > 10)
                ? () => (
                    <img
                      height="18px"
                      width="18px"
                      alt={title}
                      src={
                        (isServer() ? "/" : "") +
                        require(`../../assets/icons/${
                          icon + "-" + themeKey
                        }.svg`)
                      }
                    />
                  )
                : false
            }
            overrides={{
              BaseButton: {
                props: {
                  title: title,
                },
                style: () => {
                  return {
                    paddingLeft:
                      linkType === 20 && wcagButtonType > 10 ? "3px" : "12px",
                    paddingRight:
                      linkType === 20 && wcagButtonType > 10 ? "3px" : "12px",
                    marginLeft:
                      linkType === 20 && wcagButtonType === 20 ? "12px" : "0px",
                    marginRight:
                      linkType === 20 && wcagButtonType === 40 ? "12px" : "0px",
                    color: accent,
                    textTransform: "uppercase",
                    fontSize: fontSizeMultiplier! * 11 + "px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: 400,
                    // height: '100%',
                    lineHeight: "16px",
                    ":hover": { backgroundColor: "transparent", color: accent },
                    ":focus": { backgroundColor: "transparent", color: accent },
                    ":active": {
                      backgroundColor: "transparent",
                      color: accent,
                    },
                    ":visited": {
                      backgroundColor: "transparent",
                      color: accent,
                    },
                  };
                },
              },
              StartEnhancer: {
                style: () => ({
                  fontSize: fontSizeMultiplier! * 16 + "px",
                }),
              },
            }}
            {...rest}
          >
            {linkType === 20 &&
            wcagButtonType > 10 &&
            !themeKey?.includes("contrast") ? (
              <img
                height="18px"
                width="18px"
                alt={title}
                src={
                  (isServer() ? "/" : "") +
                  require(`../../assets/icons/${icon}.svg`)
                }
              />
            ) : linkType === 20 && wcagButtonType > 10 ? (
              <img
                height="18px"
                width="18px"
                alt={title}
                src={
                  (isServer() ? "/" : "") +
                  require(`../../assets/icons/${icon + "-" + themeKey}.svg`)
                }
              />
            ) : (
              children
            )}
          </Button>
        )}
      </Fragment>
    );
  }
};

type TBProps = {
  data: any;
  isDemo?: any;
  layoutThemeKey: string;
  init: any;
};

const TopBar: React.FC<TBProps> = (props) => {
  const { data, isDemo, layoutThemeKey, init } = props;
  const [, theme] = useStyletron();

  const {
    colors: { backgroundAccent },
  } = theme;

  return (
    <Fragment>
      {isDemo ? (
        <div
          style={{
            width: "100%",
            height: "38px",
            backgroundColor: "#BB2124",
            color: "#FFF",
            display: "flex",
          }}
        >
          <div style={{ margin: "auto auto" }}>STRONA DEMONSTRACYJNA</div>
        </div>
      ) : null}
      <Block
        overrides={{
          Block: {
            style: () => ({
              backgroundColor: backgroundAccent,
            }),
          },
        }}
      >
        <Grid>
          <Cell span={[4, 3, 4]}>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                    flexWrap: "wrap",
                  }),
                },
              }}
            >
              {data?.filter((o: { align: number }) => o.align === 10) &&
                data
                  ?.filter((o: { align: number }) => o.align === 10)[0]
                  .items.map(
                    (
                      item: {
                        displayText: React.ReactNode;
                        icon: string;
                        linkType: number;
                      },
                      index: any
                    ) => {
                      return (
                        <TopBarButton
                          key={index}
                          item={item}
                          layoutThemeKey={layoutThemeKey}
                          {...{ init }}
                        >
                          {item.displayText}
                        </TopBarButton>
                      );
                    }
                  )}
              {data?.filter((o: { align: number }) => o.align === 20) &&
                data
                  ?.filter((o: { align: number }) => o.align === 20)[0]
                  .items.map(
                    (
                      item: { displayText: React.ReactNode; icon: string },
                      index: any
                    ) => {
                      return (
                        <Block
                          key={index}
                          display={["flex", "flex", "none", "none"]}
                        >
                          <TopBarButton
                            key={index}
                            item={item}
                            layoutThemeKey={layoutThemeKey}
                            {...{ init }}
                          >
                            {item.displayText}
                          </TopBarButton>
                        </Block>
                      );
                    }
                  )}
            </Block>
          </Cell>

          <Cell span={[0, 5, 8]}>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                  }),
                },
              }}
            >
              {data?.filter((o: { align: number }) => o.align === 20) &&
                data
                  ?.filter((o: { align: number }) => o.align === 20)[0]
                  .items.map(
                    (
                      item: { displayText: React.ReactNode; icon: string },
                      index: any
                    ) => {
                      return (
                        <TopBarButton
                          key={index}
                          item={item}
                          layoutThemeKey={layoutThemeKey}
                          {...{ init }}
                        >
                          {item.displayText}
                        </TopBarButton>
                      );
                    }
                  )}
            </Block>
          </Cell>
        </Grid>
      </Block>
    </Fragment>
  );
};

export default TopBar;
