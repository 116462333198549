import { createTheme, darkThemePrimitives } from 'baseui';

export const primitives = {
	...darkThemePrimitives,
	primary: '#00ff00',
	primaryB: '#00ff00',
	primaryFontFamily: "'Rubik', sans-serif",
	accent: '#00ff00'
};

const overrides = {
	name: 'contrast3',
	colors: {
		accent50: '#00ff00',
		primary50: '#00ff00',
		primary100: '#0070A8',
		primary200: '#628033',
		primary300: '#0C5944',
		primary400: '#CD1040',
		accent100: '#FFF000',
		accent200: '#FFF',
		accent300: '#00ff00',
		accent400: '#000000',
		accent500: '#0000ff',
		accent600: '#ffff00',
		breadcrumbsText: '#00ff00',
		contentAccent: '#000000',
		buttonPrimaryFill: '#000000',
		buttonPrimaryText: '#00ff00',
		buttonPrimaryHover: '#00b000',
		buttonTertiaryHover: '#141414',
		buttonTertiaryFill: '#000000',
		backgroundInversePrimary: '#000000',
		backgroundPrimary: '#333',
		backgroundSecondary: '#000000',
		backgroundAccent: '#000000',
		inputFill: '#00ff00',
		borderSelected: '#00ff00',
		contentInversePrimary: '#000000',
		backgroundInverseSecondary: '#000000',
		backgroundInv: '#00ff00',
		contentInverseSecondary: '#000000',
		backgroundOverlayDark: '#000000',
		contentTertiary: '#00ff00',
		contentInverseTertiary: '#00ff00',
		backgroundLightAccent: '#000000',
		borderInverseOpaque: '#00ff00',
		backgroundOverlayLight: '#333',
		menuFill: '#FFFFFF',
		menuFillHover: '#F6F6F6',
		calendarBackground: '#000000',
		calendarForeground: '#00ff00',
		calendarForegroundDisabled: '#004200',
		calendarHeaderBackground: '#000000',
		calendarHeaderForeground: '#00ff00',
		buttonDisabledFill: 'transparent',
		calendarDayForegroundSelectedHighlighted: '#000000',
		calendarDayBackgroundSelected: '#00ff00',
		calendarDayForegroundSelected: '#000000'
	}
};

export default createTheme(primitives, overrides);
