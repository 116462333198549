import React, { useState } from "react";

import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { useTheme } from "@contexts/ThemeContext";
import { useAccessibility } from "@contexts/AccessibilityContext";

import * as S from "./styles";

interface IScrollButtonProps {
  text: string;
}

export const ScrollButton = ({ text }: IScrollButtonProps) => {
  const { theme } = useTheme();

  const { fontSizeMultiplier } = useAccessibility();

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      {visible ? (
        <S.TopButton
          {...{
            onClick: () => goToTop(),
            role: "button",
            tabIndex: 0,
            bgColor: theme?.colors.backgroundPrimary,
            textColor: theme?.colors.primary,
            fontSize: fontSizeMultiplier! * 15 + "px",
          }}
        >
          <S.Icon
            {...{
              color: theme?.colors.primary,
              icon: faChevronUp,
            }}
          />
          {text}
        </S.TopButton>
      ) : null}
    </>
  );
};
