import { CustomLink } from "shared/CustomLink";

import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const Label = styled.label`
  display: block;
  padding: 0 0 8px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
`;

export const AttachmentItem = styled.li<{ borderColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  position: relative;

  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
`;

export const AttachmentLink = styled(CustomLink)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 10px;
`;

export const Inner = styled.div`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: 400;
  letter-spacing: 0.1px;
  text-decoration: underline;
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  margin-right: 20px;
`;

export const Size = styled.span<{ color: string; fontSize: number }>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-left: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
`;
