import React from "react";

import { useStyletron } from "baseui";

import { useAccessibility } from "@contexts/AccessibilityContext";

import { isServer } from "@helpers/ssr";

import { Wrapper, Label, Link, TextWrapper, List, ListItem } from "./styles";

export const References = ({ links, title }) => {
  const { fontSizeMultiplier } = useAccessibility();

  const [, theme] = useStyletron();

  const {
    colors: { backgroundInv, accent50, primary },
  } = theme;

  return (
    <Wrapper>
      <Label
        {...{
          color: accent50,
          borderColor: backgroundInv,
          fontSize: fontSizeMultiplier! * 11,
        }}
      >
        {title}
      </Label>
      <List>
        {links.map(({ displayText, target, slug, title, url, id }) => (
          <ListItem key={id} borderColor={theme.colors.backgroundInv}>
            <Link
              {...{
                slug,
                target: target === 20 ? "_blank" : "_self",
                title,
                url,
              }}
            >
              <img
                src={
                  (isServer() ? "/" : "") +
                  require("../../assets/icons/link.svg")
                }
                alt="Link"
                height="20px"
                width="20px"
              />
              <TextWrapper
                {...{
                  fontSize: fontSizeMultiplier! * 11,
                  color: primary,
                }}
              >
                {displayText}
              </TextWrapper>
            </Link>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};
