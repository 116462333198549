// @ts-ignore
import transformData from "axios/lib/core/transformData";
// @ts-ignore
import utils from "axios/lib/utils";
// @ts-ignore
import mergeConfig from "axios/lib/core/mergeConfig";
// @ts-ignore
import defaults from "axios/lib/defaults";
import Axios, { AxiosRequestConfig } from "axios";
import { isServer } from "./ssr";
import LRUCache from "lru-cache";

export function getCacheKey(config: any) {
  config = mergeConfig(defaults, config);

  if (config.method) {
    config.method = config.method.toLowerCase();
  } else if (defaults.method) {
    config.method = defaults.method.toLowerCase();
  } else {
    config.method = "get";
  }

  // Ensure headers exist
  config.headers = config.headers || {};

  // Transform request data
  config.data = transformData(
    config.data,
    config.headers,
    config.transformRequest
  );

  // Flatten headers
  config.headers = utils.merge(
    config.headers.common || {},
    config.headers[config.method] || {},
    config.headers
  );

  utils.forEach(
    ["delete", "get", "head", "post", "put", "patch", "common"],
    function cleanHeaderConfig(method: string) {
      delete config.headers[method];
    }
  );

  return JSON.stringify(config);
}

export function cacheAdapter(cache: LRUCache<string, string>) {
  return async function (config: AxiosRequestConfig) {
    const cacheKey = JSON.stringify(config);

    const hit = cache.get(cacheKey);

    if (hit) {
      if (isServer()) {
        console.log(`Value from cache`);
      }
      return hit;
    }

    if (isServer()) {
      console.log(`Execute request`);
    }

    delete config.adapter;

    let response;

    try {
      response = await Axios(config);
    } catch (error) {
      response = { error };
    }

    const responseForCache = { ...response };

    // @ts-ignore
    delete responseForCache.config;
    // @ts-ignore
    delete responseForCache.request;

    cache.set(cacheKey, responseForCache);
    // console.log('No cache');
    return response;
  };
}
