export const hexToRgb = (hex: string, alpha?: number): string => {
  hex = hex.replace("#", "");
  var r = parseInt(
    hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
    16
  );
  var g = parseInt(
    hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
    16
  );
  var b = parseInt(
    hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
    16
  );
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};
// parse svg string into DOM
export const parseSVG = (svgString: string): Document => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, "image/svg+xml");
  return doc;
};

export const getElementColor = (el) => {
  return el.getAttribute("fill");
};

export function replaceColors(svgString: string, color: string): string {
  const doc = parseSVG(svgString);
  const elements = doc.getElementsByTagName("*");

  for (const element of elements) {
    if (element.tagName === "path") {
      element.setAttribute("fill", color);
      element.removeAttribute("class");
    }
  }

  var xmlSerializer = new XMLSerializer();
  const str = xmlSerializer.serializeToString(doc);
  return str;
}
