import React from "react";

import { NInit } from "@namespace/Init";

import { Image, ImageWrapper, UEWrapper } from "@components/Footer/styles";

interface IEUProjectProps {
  data: NInit.IUEProject[];
}

export const EuProject = ({ data }: IEUProjectProps) => (
  <UEWrapper>
    {data.map(({ url, title }, index) => (
      <ImageWrapper key={`${title}-${index}`}>
        <Image src={url} alt={title} key={title} />
      </ImageWrapper>
    ))}
  </UEWrapper>
);
