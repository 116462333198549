import styled from "styled-components";

export const NavList = styled.ul<{ color: string; fontSize: number }>`
  padding: 0;
  margin: 0;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};

  a {
    color: ${({ color }) => color};
  }
`;

export const NavItem = styled.li`
  align-items: flex-start;
  padding-left: 20px;
  margin-top: 20px;
  list-style: none;
  display: flex;
  background-color: ${({ isLast, lastlyColor }) =>
    isLast ? lastlyColor : "transparent"};
`;

export const NavItemInner = styled.div<{
  isLast: boolean;
  lastlyColor: string;
}>``;

export const Date = styled.div`
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
`;

export const LinkWrapper = styled.div`
  margin-left: 10px;
  font-weight: 500;
  line-height: 16px;
`;

export const LinkInner = styled.div<{ isLast: boolean; lastlyColor: string }>`
  display: inline-flex;
`;

export const Index = styled.div<{ fontSize: number; color: string }>`
  width: 10px;
  text-align: right;
  margin-right: 10px;
  line-height: 15px;
  font-weight: 500;
`;
