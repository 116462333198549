import React from "react";

import { Information } from "@containers/Information/Information";
import { UserInfo } from "@containers/UserInfo/UserInfo";
import { Tiles } from "@containers/Tiles";
import { BookMarks } from "@containers/Bookmarks/Bookmarks";

import { Details, Wrapper } from "./styles";

type HProps = {
  data: any;
  newsData?: any;
  response?: any;
  loading: any;
};

const Home: React.FC<HProps> = (props) => {
  const { data, newsData, loading, response } = props;

  return (
    <Wrapper>
      <Details>
        <UserInfo officeManager={data?.officeManager} />
        <Information information={data?.information} />
        <Tiles tiles={data?.tiles || []} />
        <BookMarks
          {...{
            newsData,
            bookmarks: data?.bookmarks || [],
            loading,
            response,
          }}
        />
      </Details>
    </Wrapper>
  );
};

export default Home;
