import styled from "styled-components";

import { MEDIA_QUERIES } from "@utils/constants";
import { CustomLink } from "../../../shared/CustomLink";

export const Text = styled.h4<{
  color: string;
  isTwoElements: boolean;
  fontSizeMultiplier: number;
}>`
  margin: 0px;
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-weight: 500;
  font-size: ${({ fontSizeMultiplier, isTwoElements }) =>
    `${fontSizeMultiplier! * (isTwoElements ? 18 : 14)}px`};
  line-height: ${({ isTwoElements }) => (isTwoElements ? "34px" : "17px")};
  letter-spacing: 0.6px;
  text-decoration: none;
  max-width: ${({ isTwoElements }) => (isTwoElements ? "100%" : "150px")};
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${MEDIA_QUERIES.md} and (max-width: ${MEDIA_QUERIES.lg})) {
    font-size: ${({ fontSizeMultiplier, isTwoElements }) =>
      `${fontSizeMultiplier! * (isTwoElements ? 16 : 14)}px`};
  }
`;

export const TileLink = styled(CustomLink)<{
  isTwoElements: boolean;
  backgroundSecondary: string;
}>`
  background-color: ${({ backgroundSecondary }) => backgroundSecondary};
  display: flex;
  justify-content: space-between;
  padding: ${({ isTwoElements }) => (isTwoElements ? "35px 30px" : "0 30px")};
  align-items: center;
  border-radius: 3px;
  box-shadow: 1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2);
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  height: ${({ isTwoElements }) => (isTwoElements ? "190px" : "92.5px")};
  gap: 10px;
  width: 100%;

  @media (max-width: ${MEDIA_QUERIES.lg}) and (min-width: ${MEDIA_QUERIES.md}) {
    width: calc(50% - 10px);
  }
`;

export const SvgWrapper = styled.div<{ isTwoElements: boolean }>`
  fill: transparent !important;

  svg {
    height: ${({ isTwoElements }) => (isTwoElements ? "78px" : "41px")};
    width: ${({ isTwoElements }) => (isTwoElements ? "104px" : "55px")};
  }
`;

export const ImageWrapper = styled.div<{ bgColor: string }>`
  background: ${({ bgColor }) => bgColor};
`;

export const Image = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
