import { createTheme, darkThemePrimitives } from 'baseui';

export const primitives = {
	...darkThemePrimitives,
	primary: '#ffff00',
	primaryB: '#ffff00',
	primaryFontFamily: "'Rubik', sans-serif",
	accent: '#0000ff'
};

const overrides = {
	name: 'contrast6',
	colors: {
		accent50: '#ffff00',
		primary50: '#0000ff',
		primary100: '#0070A8',
		primary200: '#628033',
		primary300: '#0C5944',
		primary400: '#CD1040',
		accent100: '#FFF000',
		accent200: '#FFF',
		accent300: '#00ff00',
		accent400: '#000000',
		accent500: '#0000ff',
		accent600: '#ffff00',
		breadcrumbsText: '#ffff00',
		contentAccent: '#0000ff',
		buttonPrimaryFill: '#0000ff',
		buttonPrimaryText: '#ffff00',
		buttonPrimaryHover: '#a3a300',
		buttonTertiaryHover: '#2626ed',
		backgroundInversePrimary: '#0000ff',
		backgroundPrimary: '#0000f0',
		backgroundSecondary: '#0000ff',
		backgroundAccent: '#ffff00',
		inputFill: '#ffff00',
		borderSelected: '#ffff00',
		contentInversePrimary: '#6f80ed',
		backgroundInverseSecondary: '#0000ff',
		backgroundInv: '#ffff00',
		contentInverseSecondary: '#0000ff',
		backgroundOverlayDark: '#0000ff',
		contentTertiary: '#ffff00',
		contentInverseTertiary: '#ffff00',
		backgroundLightAccent: '#0000ff',
		borderInverseOpaque: '#ffff00',
		backgroundOverlayLight: '#0000f0',
		menuFill: '#FFFFFF',
		menuFillHover: '#F6F6F6',
		calendarBackground: '#0000ff',
		calendarForeground: '#ffff00',
		calendarForegroundDisabled: '#a29e00',
		calendarHeaderBackground: '#0000ff',
		calendarHeaderForeground: '#ffff00',
		buttonDisabledFill: 'transparent',
		calendarDayForegroundSelectedHighlighted: '#000000',
		calendarDayBackgroundSelected: '#FFF000',
		calendarDayForegroundSelected: '#000000'
	}
};

export default createTheme(primitives, overrides);
