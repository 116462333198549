import { CustomLink } from "shared/CustomLink";

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Article = styled.div<{
  backgroundColor: string;
  borderColor: string;
  articleId: number;
  isLastly: boolean;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-left: ${({ borderColor }) => `3px solid ${borderColor}`};
  padding: 15px 20px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2);
  text-decoration: none;
  position: relative;
  box-sizing: border-box;
  margin: 10px 0;
`;

export const PublishedDate = styled.span`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  border-radius: 3px;
  padding: 4px 8px;
  line-height: 12px;
  font-weight: 400;
  margin: 5px 5px 5px 0px;
  margin-bottom: 5px;
  box-sizing: border-box;
  display: inline-flex;
`;

export const Bookmark = styled.span`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: inline-flex;
  border-radius: 3px;
  padding: 4px 8px;
  line-height: 12px;
  text-transfrom: uppercase;
  letter-spacing: 0.75px;
  margin: 5px;
`;

export const Label = styled.span`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  letter-spacing: 0.5px;
  margin: 5px 0 10px;
  font-weight: 500;
  display: block;
`;

export const ContentWrapper = styled.div`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height 16px;
  letter-spacing: .1px;
  margin: 5px 0 10px;


`;

export const Link = styled(CustomLink)`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  margin-top: 5px;
  display: inline-flex;
  text-decoration: underline;
  text-transform: uppercase;
  display: block;
  letter-spacing: 0.75px;
  font-weight: 500;
`;
