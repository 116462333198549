import React, { ChangeEvent, useState } from "react";
import { useHistory, useLocation } from "react-router";

import queryString from "query-string";
import { useStyletron } from "baseui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { CustomLink } from "shared/CustomLink";

import { useAccessibility } from "@contexts/AccessibilityContext";
import { isServer } from "../../../helpers/ssr";

import {
  ClearIconWrapper,
  SearchIconWrapper,
  Input,
  InputWrapper,
  SearchButton,
} from "./styles";
import { sortByType } from "@containers/Search/utils";

export const SearchInput = ({ data, id, appSettings }) => {
  const history = useHistory();

  const [, theme] = useStyletron();

  const {
    colors: {
      primary,
      accent,
      accent50,
      buttonPrimaryHover,
      backgroundLightAccent,
      backgroundPrimary,
    },
    name,
  } = theme;

  const { parse, stringify } = queryString;

  const [value, setValue] = useState("");

  const { search } = useLocation();

  const { lang } = parse(search);

  const { fontSizeMultiplier } = useAccessibility();

  const handleRedirect = (): void => {
    history.push({
      pathname: "/wyszukiwanie",
      search: `?${stringify({
        lang: lang ?? "PL",
        searchPhrase: value,
        size: appSettings?.pagination?.size?.[0] || 10,
        page: 0,
        sortBy: sortByType.descending,
      })}`,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleRedirect();
    }
  };

  const handleClearValue = () => {
    setValue("");
  };

  return (
    <InputWrapper
      {...{
        bgColor: accent,
        borderColor: accent50,
        isBorder: name !== "standard",
      }}
    >
      <Input
        {...{
          id,
          color: name !== "standard" ? primary : "#000",
          bgColor: name !== "standard" ? backgroundPrimary : accent,
          value,
          title: data?.searchText?.displayText,
          onChange: handleChange,
          onKeyPress: handleKeyPress,
        }}
      />
      {value ? (
        <ClearIconWrapper
          {...{
            color: primary,
            bgColor: name !== "standard" ? backgroundPrimary : accent,
            title: data?.clearIcon?.title ? data?.clearIcon?.title : "Wyczyść",
            onClick: () => handleClearValue(),
          }}
        >
          <FontAwesomeIcon
            {...{
              icon: faTimesCircle,
              size: "1x",
            }}
          />
        </ClearIconWrapper>
      ) : null}
      <SearchIconWrapper
        {...{
          color: accent,
          bgColor: primary,
          bgColorHover: buttonPrimaryHover,
          title: data?.searchButton?.title,
          onClick: handleRedirect,
        }}
      >
        <img
          {...{
            height: 20,
            width: 20,
            alt: data?.searchButton?.title,
            src:
              (isServer() ? "/" : "") +
              require(`../../../assets/icons/search-${name}.svg`),
          }}
        />
      </SearchIconWrapper>
      <SearchButton
        {...{
          color: backgroundLightAccent,
          bgColor: primary,
          bgColorHover: buttonPrimaryHover,
          fontSize: fontSizeMultiplier! * 10,
        }}
      >
        <CustomLink
          {...{
            title: data?.searchText?.title,
            slug: `wyszukiwanie${lang ? "?" + stringify({ lang }) : ""}`,
          }}
        >
          {data?.searchText?.displayText}
        </CustomLink>
      </SearchButton>
    </InputWrapper>
  );
};
