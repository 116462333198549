import { createTheme, darkThemePrimitives } from 'baseui';

export const primitives = {
	...darkThemePrimitives,
	primary: '#000000',
	primaryB: '#000000',
	primaryFontFamily: "'Rubik', sans-serif",
	accent: '#00ff00'
};

const overrides = {
	name: 'contrast4',
	colors: {
		accent50: '#000000',
		primary50: '#00ff00',
		primary100: '#0070A8',
		primary200: '#628033',
		primary300: '#0C5944',
		primary400: '#CD1040',
		accent100: '#FFF000',
		accent200: '#FFF',
		accent300: '#00ff00',
		accent400: '#000000',
		accent500: '#0000ff',
		accent600: '#ffff00',
		breadcrumbsText: '#000000',
		contentAccent: '#00ff00',
		buttonPrimaryFill: '#00ff00',
		buttonPrimaryText: '#000000',
		buttonPrimaryHover: '#5e5e5e',
		buttonTertiaryHover: '#41f041',
		backgroundInversePrimary: '#00ff00',
		backgroundPrimary: '#6cff47',
		backgroundSecondary: '#00ff00',
		backgroundAccent: '#000000',
		inputFill: '#000000',
		borderSelected: '#000000',
		contentInversePrimary: '#5cbf43',
		backgroundInverseSecondary: '#00ff00',
		backgroundInv: '#000000',
		contentInverseSecondary: '#00ff00',
		backgroundOverlayDark: '#00ff00',
		contentTertiary: '#000000',
		contentInverseTertiary: '#000000',
		backgroundLightAccent: '#00ff00',
		borderInverseOpaque: '#000000',
		backgroundOverlayLight: '#00f000',
		menuFill: '#FFFFFF',
		menuFillHover: '#F6F6F6',
		calendarBackground: '#00ff00',
		calendarForeground: '#000000',
		calendarForegroundDisabled: '#00a300',
		calendarHeaderBackground: '#00ff00',
		calendarHeaderForeground: '#000000',
		buttonDisabledFill: 'transparent',
		calendarDayForegroundSelectedHighlighted: '#00ff00',
		calendarDayBackgroundSelected: '#000000',
		calendarDayForegroundSelected: '#00ff00'
	}
};

export default createTheme(primitives, overrides);
