import React, { LegacyRef, ReactNode } from "react";

import { Link } from "react-router-dom";

type TCustomLinkProps = {
  slug?: string;
  url?: string;
  target?: "_blank" | "_self";
  children: ReactNode;
  className?: string;
  rel?: string;
  title?: string;
  isDisableConfirm?: boolean;
};

export const CustomLink = ({
  slug,
  url,
  target = "_self",
  children,
  className,
  rel,
  title = "",
  isDisableConfirm = false,
}: TCustomLinkProps) => {
  const confirmText = `Strona ${slug || url} zostanie otwarta ${
    target === "_self" ? "w tym samym oknie" : "w nowym oknie"
  }. Czy chcesz kontynuować?`;

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (target === "_self" && !!slug) {
      return;
    }

    if (isDisableConfirm || window.confirm(confirmText)) {
      window.open(url, target, "noopener,noreferrer");
    }
  };

  const handleLink = (event) => {
    if (target === "_blank" && !window.confirm(confirmText)) {
      event.preventDefault();
    }
  };

  if (slug) {
    return (
      <Link
        {...{
          to: `/${slug}`,
          target,
          className,
          rel,
          onClick: handleLink,
          title,
        }}
      >
        {children}
      </Link>
    );
  }

  return (
    <a
      {...{
        href: url,
        target,
        className,
        onClick: handleClick,
        rel: rel || "nofollow",
        title,
      }}
    >
      {children}
    </a>
  );
};
