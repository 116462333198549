import React from "react";

import { Datepicker as BaseDatepicker } from "baseui/datepicker";
import * as locales from "date-fns/locale";

const Datapicker: React.FC<any> = (props) => {
  return (
    <BaseDatepicker
      //@ts-ignore
      locale={locales[String(props?.lang).toLowerCase() || "pl"]}
      {...props}
    />
  );
};

export default Datapicker;
