import React, { useRef } from "react";

import { NavItem } from "./NavItem";

import { NMenu } from "@namespace/Menu";

import { MenuItems } from "./styles";

type TVerticalMenuProps = {
  firstItemId?: "vertical-menu-first-item" | "vertical-menu-mobile-first-item";
  items: NMenu.IVerticalMenuItem[];
  level: number;
  isOpen: boolean;
  translation: NMenu.ITranslation;
  breadcrumbs?: NMenu.IBreadcrumb[];
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const VerticalMenu = ({
  firstItemId,
  items,
  level,
  isOpen,
  breadcrumbs = [],
  translation,
  setIsMenuOpen,
}: TVerticalMenuProps): JSX.Element => (
  <MenuItems
    {...{
      isOpen,
      level,
    }}
  >
    {items.map((item, index) => (
      <NavItem
        key={`${item.displayText}-${index}`}
        {...{
          firstItemId,
          item,
          index,
          currentLevel: level,
          breadcrumbs,
          translation,
          setIsMenuOpen,
        }}
      />
    ))}
  </MenuItems>
);
