// @ts-ignore;
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  Fragment,
  useRef,
} from "react";
import { Link, useLocation } from "react-router-dom";
import useAxios from "axios-hooks";
import ReactHtmlParser from "react-html-parser";
import queryString from "query-string";
import download from "downloadjs";

import FailureWindow from "@components/Modals/FailureWindow";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button, KIND, SIZE } from "baseui/button";
import { Modal, SIZE as SIZEM } from "baseui/modal";
import {
  H3,
  Label4,
  Paragraph4,
  Label3,
  LabelMedium,
  LabelXSmall,
} from "baseui/typography";
import { Tag } from "baseui/tag";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { StyledSpinnerNext } from "baseui/spinner";
import { format } from "date-fns";

import { useAccessibility } from "@contexts/AccessibilityContext";
import { isServer } from "@helpers/ssr";
import { rewriteUrl } from "@helpers/client-axios";

import { NOfficeData } from "@namespace/OfficeData";

import { COLORS } from "@utils/constants";

import { useReactToPrint } from "react-to-print";

import { failWindowHandler, startDownloadPDF } from "./utils";

const OfficeData: React.FC<NOfficeData.OfficeDataProps> = ({
  data: dataProps,
}) => {
  const data = dataProps.item;

  const translation = dataProps?.translation;

  const { search, pathname } = useLocation();
  const { lang } = queryString.parse(search);

  const articleContentRef = useRef(null);
  const articleContentDiffModalRef = useRef(null);
  const articleContentQuickLookModalRef = useRef(null);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isModalClicked, setIsModalClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [actionType, setActionType] = useState(0);
  const [isQuickLookModalOpened, setIsQuickLookModalOpened] = useState(false);

  const [articleVersions, setArticleVersions] = useState<any[]>([]);
  const [articleVersionsFetched, setArticleVersionsFetched] = useState(false);

  const { fontSizeMultiplier } = useAccessibility();
  const [, theme] = useStyletron();

  const handlePrintArticle = useReactToPrint({
    content: () => articleContentRef.current,
    pageStyle:
      "@page { size:  auto; margin: 30mm 25mm 20mm 25mm;} #articleContent { padding: 30mm, width: 100vw!important; font-family: 'Rubik', sans-serif; word-break: break-word;} h3 { font-size: 28px; font-weight: 700; } #articleContent > div:first-child { border-bottom: 1px solid rgb(225, 225, 225); padding-bottom: 15px } #articleContent > div:first-child > * { width: 100%; margin: 0px 30px 10px 0px } #articleContent > div:last-child p { padding: 10px 0px 5px 0px !important; }#articleContent .metrics-print { display: flex !important; }",
  });

  const handlePrintArticleContentDiffModal = useReactToPrint({
    content: () => articleContentDiffModalRef.current,
    pageStyle:
      "#articleContentDiffModal { padding: 30px, width: 100vw !important } h3 { font-size: 28px; font-weight: 700; } #articleContentDiffModal > div:first-child { border-bottom: 1px solid rgb(225, 225, 225); padding-bottom: 15px } #articleContentDiffModal > div:first-child > * { width: 100%; margin: 0px 30px 10px 0px } #articleContentDiffModal > div:last-child p { padding: 10px 0px 5px 0px !important; } ",
  });

  const handlePrintArticleContentQuickLookModal = useReactToPrint({
    content: () => articleContentQuickLookModalRef.current,
    pageStyle:
      "#articleContentQuickLookModal { padding: 130px, width: 100vw !important   } h3 { font-size: 28px; font-weight: 700 } #articleContentQuickLookModal > div:first-child { border-bottom: 1px solid rgb(225, 225, 225); padding-bottom: 15px } #articleContentQuickLookModal > div:first-child > * { width: 100%; margin: 0px 30px 10px 0px } #articleContentQuickLookModal > div:last-child p { padding: 10px 0px 5px 0px !important; } ",
  });

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, [true]);

  useEffect(() => {
    if (articleVersions.length && !articleVersionsFetched) {
      setArticleVersions(
        articleVersions.map((aV: any) => ({ ...aV, selected: false }))
      );
      setArticleVersionsFetched(true);
    }
  }, [articleVersions, articleVersionsFetched]);

  const deepEqual = (x: any, y: any): boolean => {
    const ok = Object.keys;
    const tx = typeof x;
    const ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x).length === ok(y).length &&
          ok(x).every((key) => deepEqual(x[key], y[key]))
      : x === y;
  };

  const [{ data: articleVersionQueryData }] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const [{ data: pdfData, error: error }, doGeneratePDFArticleQuery] = useAxios(
    {
      url: `${
        isServer() ? rewriteUrl(null) : rewriteUrl(window.location)
      }/page-content${pathname}/pdf${search}`,
      method: "POST",
      responseType: "blob",
    },
    {
      manual: true,
    }
  );

  const saveToPDF = async () => {
    setIsLoading(true);
    const { data: pdfData, headers: pdfHeaders } =
      await doGeneratePDFArticleQuery();
    if (pdfData && pdfHeaders) {
      const result = download(
        pdfData,
        "download.pdf",
        pdfHeaders["content-type"]
      );
      if (!result) {
        failWindowHandler(setActionType, setIsFailureModalOpen, 10);
      }
    }
    setIsLoading(false);
  };

  if (!isModalClicked && error && isFailureModalOpen === false) {
    failWindowHandler(setActionType, setIsFailureModalOpen, 10);
    setIsLoading(false);
  }

  const loader = isLoading ? (
    <StyledSpinnerNext
      style={{
        margin: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: "1",
      }}
    />
  ) : null;

  return (
    <Fragment>
      {loader}
      <FailureWindow
        {...{
          isFailureModalOpen,
          setIsFailureModalOpen,
          actionType,
          setIsModalClicked,
        }}
      />
      <Block
        overrides={{
          Block: {
            style: () => ({
              backgroundColor: theme.colors.backgroundSecondary,
              margin: "0 0 20px",
              borderRadius: "5px",
              boxShadow:
                "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
              opacity: isLoading ? "0.3" : "none",
            }),
          },
        }}
      >
        <Block
          overrides={{
            Block: {
              style: () => ({
                padding: "30px 50px 40px",
              }),
            },
          }}
        >
          <div id="articleContent" ref={articleContentRef}>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    borderBottom: "1px solid " + theme.colors.backgroundInv,
                  }),
                },
              }}
            >
              <H3
                overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.primary,
                      margin: "0px 0px 10px",
                      fontSize: fontSizeMultiplier! * 16 + "px",
                      lineHeight: "18px",
                      letterSpacing: "1px",
                      fontWeight: 500,
                    }),
                  },
                }}
              >
                {data.title}
              </H3>
            </Block>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    padding: "20px 0 0",
                  }),
                },
              }}
            >
              <Paragraph4
                as="div"
                overrides={{
                  Block: {
                    style: () => ({
                      margin: "0px",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      lineHeight: "18px",
                      gap: "5px",
                      color: theme.colors.accent50,
                    }),
                  },
                }}
              >
                <div className="article-content">
                  <Block
                    overrides={{
                      Block: {
                        style: () => ({
                          display: "flex",
                          gap: "5px",
                          flexDirection: "column",
                        }),
                      },
                    }}
                  >
                    {data.content.map((item, index) => {
                      if (!item.value) {
                        return null;
                      }

                      if (item.type !== 130) {
                        return (
                          <Block
                            key={index}
                            overrides={{
                              Block: {
                                style: () => ({
                                  display: "flex",
                                  minWidth: "40px",
                                  gap: "5px",
                                }),
                              },
                            }}
                          >
                            {item.displayText && (
                              <LabelXSmall
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      color: theme.colors.primaryB,
                                      fontWeight: 700,
                                      fontSize: fontSizeMultiplier! * 12 + "px",
                                      letterSpacing: ".25px",
                                      textDecoration: "none",
                                    }),
                                  },
                                }}
                              >
                                {item.displayText}:
                              </LabelXSmall>
                            )}
                            <LabelXSmall
                              overrides={{
                                Block: {
                                  style: () => ({
                                    color: theme.colors.primaryB,
                                    fontSize: fontSizeMultiplier! * 11 + "px",
                                    letterSpacing: ".25px",
                                    textDecoration: "none",
                                  }),
                                },
                              }}
                            >
                              {item.value}
                            </LabelXSmall>
                          </Block>
                        );
                      }

                      return (
                        <Block
                          key={index}
                          overrides={{
                            Block: {
                              style: () => ({
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "15px",
                              }),
                            },
                          }}
                        >
                          {item.displayText && (
                            <LabelXSmall
                              overrides={{
                                Block: {
                                  style: () => ({
                                    color: theme.colors.primaryB,
                                    fontWeight: 700,
                                    fontSize: fontSizeMultiplier! * 12 + "px",
                                    letterSpacing: ".25px",
                                    textDecoration: "none",
                                    margin: "20px 0 10px 0",
                                    display: "block",
                                    width: "200px",
                                  }),
                                },
                              }}
                            >
                              {item.displayText}:
                            </LabelXSmall>
                          )}
                          <Block
                            overrides={{
                              Block: {
                                style: () => ({
                                  color: theme.colors.primaryB,
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  letterSpacing: ".25px",
                                  textDecoration: "none",
                                }),
                              },
                            }}
                          >
                            {(
                              item.value as Array<NOfficeData.TOfficeHours>
                            ).map((day) => (
                              <Block
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      color: theme.colors.primaryB,
                                      fontWeight: 700,
                                      fontSize: fontSizeMultiplier! * 12 + "px",
                                      letterSpacing: ".25px",
                                      textDecoration: "none",
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "5px",
                                      flexWrap: "wrap",
                                    }),
                                  },
                                }}
                              >
                                <LabelXSmall
                                  overrides={{
                                    Block: {
                                      style: () => ({
                                        minWidth: "125px",
                                        fontSize:
                                          fontSizeMultiplier! * 11 + "px",
                                        color: theme.colors.primaryB,
                                      }),
                                    },
                                  }}
                                >
                                  {day.dayName ?? ""}
                                </LabelXSmall>
                                <Block
                                  overrides={{
                                    Block: {
                                      style: () => ({
                                        display: "flex",
                                        gap: "5px",
                                      }),
                                    },
                                  }}
                                >
                                  <LabelXSmall
                                    overrides={{
                                      Block: {
                                        style: () => ({
                                          fontWeight: 400,
                                          fontSize:
                                            fontSizeMultiplier! * 11 + "px",
                                          color: theme.colors.primaryB,
                                        }),
                                      },
                                    }}
                                  >
                                    {format(new Date(day.timeFrom), "HH:mm")}
                                  </LabelXSmall>
                                  -
                                  <LabelXSmall
                                    overrides={{
                                      Block: {
                                        style: () => ({
                                          fontWeight: 400,
                                          fontSize:
                                            fontSizeMultiplier! * 11 + "px",
                                          color: theme.colors.primaryB,
                                        }),
                                      },
                                    }}
                                  >
                                    {format(new Date(day.timeTo), "HH:mm")}
                                  </LabelXSmall>
                                </Block>
                              </Block>
                            ))}
                          </Block>
                        </Block>
                      );
                    })}
                  </Block>
                </div>
              </Paragraph4>
            </Block>
          </div>
        </Block>

        <Block
          justifyContent={[
            "flex-start",
            "flex-start",
            "flex-start",
            "space-between",
          ]}
          overrides={{
            Block: {
              style: () => ({
                display: "flex",
                flexDirection: "row",
                padding: "0px 50px 0px",
                backgroundColor: theme.colors.backgroundOverlayDark,
                flexWrap: "wrap",
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexWrap: "wrap",
                }),
              },
            }}
          >
            <Button
              onClick={
                isServer()
                  ? () => {
                      failWindowHandler(
                        setActionType,
                        setIsFailureModalOpen,
                        20
                      );
                    }
                  : () => handlePrintArticle()
              }
              kind={KIND.tertiary}
              size={SIZE.compact}
              startEnhancer={() => (
                <img
                  height="16px"
                  alt={translation?.footer?.printButton?.displayText}
                  src={
                    (isServer() ? "/" : "") +
                    require(`../../assets/icons/print-${theme.name}.svg`)
                  }
                />
              )}
              overrides={{
                BaseButton: {
                  props: {
                    title: translation?.footer?.printButton?.title,
                  },
                  style: () => ({
                    color: theme.colors.breadcrumbsText,
                    margin: "0px 35px 0px 0px",
                    padding: "15px 0px",
                    textTransform: "uppercase",
                    fontSize: fontSizeMultiplier! * 11 + "px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: 400,
                    lineHeight: "11px",
                    ":hover": {
                      backgroundColor: "transparent",
                      color:
                        theme.name !== "standard"
                          ? theme.colors.primary
                          : COLORS.ARTICLE_STANDARD,
                    },
                    ":focus": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                    ":active": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                    ":visited": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                  }),
                },
              }}
            >
              {translation?.footer?.printButton?.displayText}
            </Button>
            <Button
              onClick={() => startDownloadPDF(setIsModalClicked, saveToPDF)}
              kind={KIND.tertiary}
              size={SIZE.compact}
              startEnhancer={() => (
                <img
                  height="16px"
                  alt={translation?.footer?.saveToPdfButton?.displayText}
                  src={
                    (isServer() ? "/" : "") +
                    require(`../../assets/icons/pdf-file-${theme.name}.svg`)
                  }
                />
              )}
              overrides={{
                BaseButton: {
                  props: {
                    title: translation?.footer?.saveToPdfButton?.title,
                  },
                  style: () => ({
                    color: theme.colors.breadcrumbsText,
                    margin: "0px 35px 0px 0px",
                    padding: "15px 0px",
                    textTransform: "uppercase",
                    fontSize: fontSizeMultiplier! * 11 + "px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: 400,
                    lineHeight: "11px",
                    ":hover": {
                      backgroundColor: "transparent",
                      color:
                        theme.name !== "standard"
                          ? theme.colors.primary
                          : COLORS.ARTICLE_STANDARD,
                    },
                    ":focus": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                    ":active": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                    ":visited": {
                      backgroundColor: "transparent",
                      color: theme.colors.primary,
                    },
                  }),
                },
              }}
            >
              {translation?.footer?.saveToPdfButton?.displayText}
            </Button>
          </Block>
        </Block>

        <Modal
          size={
            !isServer() && window.innerWidth > 900 ? SIZEM.default : SIZEM.full
          }
          onClose={() => setIsModalOpened(false)}
          isOpen={isModalOpened}
          overrides={{
            Backdrop: {
              style: () => ({
                backgroundColor: "rgba(255,255,255,.7)",
              }),
            },
            Dialog: {
              style: () => ({
                backgroundColor: theme.colors.backgroundSecondary,
                minWidth: "55vw",
                margin: "0px",
                borderRadius: "5px",
                boxShadow:
                  "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  padding: "30px 50px 0px",
                }),
              },
            }}
          >
            <H3
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.primary,
                    margin: "0px 0px 10px",
                    fontSize: fontSizeMultiplier! * 16 + "px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }),
                },
              }}
            >
              {translation?.comparision?.title}
            </H3>

            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    color: theme.colors.accent50,
                    borderBottom: "1px solid " + theme.colors.backgroundInv,
                    paddingBottom: "20px",
                  }),
                },
              }}
            >
              <Block
                width={["100%", "100%", "50%", "50%"]}
                overrides={{
                  Block: {
                    style: () => ({
                      boxSizing: "border-box",
                    }),
                  },
                }}
              >
                {articleVersions
                  .filter((aV: { selected: any }) => aV.selected)
                  .map((version: any) => {
                    return (
                      <Block
                        overrides={{
                          Block: {
                            style: () => ({
                              display: "block",
                              margin: "10px 0",
                            }),
                          },
                        }}
                      >
                        <Checkbox
                          disabled
                          checked={
                            articleVersions?.filter(
                              (aV) => aV.id === version.id
                            )[0]?.selected
                          }
                          labelPlacement={LABEL_PLACEMENT.right}
                          overrides={{
                            Label: {
                              style: () => ({
                                lineHeight: "12px",
                                height: "12px",
                              }),
                            },
                            Checkmark: {
                              style: ({ $checked }) => ({
                                borderColor: "#005BFF",
                                borderWidth: "2px",
                                borderRadius: "3px",
                                width: "12px",
                                height: "12px",
                                marginTop: "0px",
                                marginBottom: "0px",
                                backgroundColor:
                                  theme.colors.backgroundSecondary,
                                backgroundImage: $checked
                                  ? "url(data:image/svg+xml,%0A%20%20%20%20%3Csvg%20width%3D%2217%22%20height%3D%2213%22%20viewBox%3D%220%200%2017%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M6.50002%2012.6L0.400024%206.60002L2.60002%204.40002L6.50002%208.40002L13.9%200.900024L16.1%203.10002L6.50002%2012.6Z%22%20fill%3D%22%23005BFF%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E%0A%20%20)"
                                  : "",
                              }),
                            },
                          }}
                        >
                          <Label4
                            overrides={{
                              Block: {
                                style: () => ({
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px",
                                  fontSize: fontSizeMultiplier! * 10 + "px",
                                  color: theme.colors.contentTertiary,
                                  lineHeight: "12px",
                                  fontWeight: 500,
                                  letterSpacing: ".25px",
                                  width: "110px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }),
                              },
                            }}
                          >
                            {version.publishDate}
                          </Label4>
                          <Label4
                            overrides={{
                              Block: {
                                style: () => ({
                                  display: "inline-block",
                                  margin: "0px 15px 0px 0px",
                                  fontSize: fontSizeMultiplier! * 10 + "px",
                                  color: theme.colors.contentTertiary,
                                  lineHeight: "12px",
                                  fontWeight: 400,
                                  letterSpacing: ".25px",
                                  verticalAlign: "top",
                                }),
                              },
                            }}
                          >
                            {version.publishBy}
                          </Label4>
                        </Checkbox>
                      </Block>
                    );
                  })}
              </Block>
              <Block
                width={["100%", "100%", "50%", "50%"]}
                overrides={{
                  Block: {
                    style: () => ({
                      boxSizing: "border-box",
                    }),
                  },
                }}
              >
                <Block
                  overrides={{
                    Block: {
                      style: () => ({
                        display: "block",
                        margin: "10px 0",
                      }),
                    },
                  }}
                >
                  <Label4
                    display={["block", "block", "inline-block", "inline-block"]}
                    overrides={{
                      Block: {
                        style: () => ({
                          margin: "0px 45px 10px 0px",
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          color: theme.colors.contentTertiary,
                          lineHeight: "12px",
                          fontWeight: 500,
                          letterSpacing: ".25px",
                        }),
                      },
                    }}
                  >
                    {translation?.comparision?.legend}:
                  </Label4>
                  <Tag
                    closeable={false}
                    overrides={{
                      Root: {
                        style: () => ({
                          backgroundColor: "#d1f1d6",
                          borderRadius: "0px",
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          lineHeight: "10px",
                          fontWeight: 400,
                          height: "auto",
                          margin: "0px 30px 0px 0px",
                          textAlign: "left",
                          padding: "5px",
                          minWidth: "70px",
                          color: "#707070",
                        }),
                      },
                    }}
                  >
                    {translation?.comparision?.add}
                  </Tag>
                  <Tag
                    closeable={false}
                    overrides={{
                      Root: {
                        style: () => ({
                          backgroundColor: "#fad2ce",
                          borderRadius: "0px",
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          lineHeight: "10px",
                          fontWeight: 400,
                          height: "auto",
                          margin: "0px 30px 0px 0px",
                          textAlign: "left",
                          padding: "5px",
                          minWidth: "70px",
                          color: theme.colors.borderInverseOpaque,
                          textDecoration: "line-through",
                        }),
                      },
                    }}
                  >
                    {translation?.comparision?.remove}
                  </Tag>
                </Block>
              </Block>
            </Block>
          </Block>
          <Block
            overrides={{
              Block: {
                style: () => ({
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "0px 50px 0px",
                  backgroundColor: theme.colors.backgroundOverlayDark,
                }),
              },
            }}
          >
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }),
                },
              }}
            >
              <Button
                onClick={
                  isServer()
                    ? () => {
                        failWindowHandler(
                          setActionType,
                          setIsFailureModalOpen,
                          20
                        );
                      }
                    : () => handlePrintArticleContentDiffModal()
                }
                kind={KIND.tertiary}
                size={SIZE.compact}
                startEnhancer={() => (
                  <img
                    height="16px"
                    alt={translation?.footer?.printButton?.displayText}
                    src={
                      (isServer() ? "/" : "") +
                      require("../../assets/icons/print.svg")
                    }
                  />
                )}
                overrides={{
                  BaseButton: {
                    props: {
                      title: translation?.footer?.printButton?.title,
                    },
                    style: () => ({
                      color: theme.colors.primary,
                      margin: "0px 35px 0px 0px",
                      padding: "15px 0px",
                      textTransform: "uppercase",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: 400,
                      lineHeight: "11px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color:
                          theme.name !== "standard"
                            ? theme.colors.primary
                            : COLORS.ARTICLE_STANDARD,
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":active": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":visited": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                    }),
                  },
                }}
              >
                {translation?.footer?.printButton?.displayText}
              </Button>
              <Button
                onClick={() => startDownloadPDF(setIsModalClicked, saveToPDF)}
                kind={KIND.tertiary}
                size={SIZE.compact}
                startEnhancer={() => (
                  <img
                    height="16px"
                    alt={translation?.footer?.saveToPdfButton?.displayText}
                    src={
                      (isServer() ? "/" : "") +
                      require(`../../assets/icons/pdf-file-${theme.name}.svg`)
                    }
                  />
                )}
                overrides={{
                  BaseButton: {
                    props: {
                      title: translation?.footer?.saveToPdfButton?.title,
                    },
                    style: () => ({
                      color: theme.colors.primary,
                      margin: "0px 35px 0px 0px",
                      padding: "15px 0px",
                      textTransform: "uppercase",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: 400,
                      lineHeight: "11px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color:
                          theme.name !== "standard"
                            ? theme.colors.primary
                            : COLORS.ARTICLE_STANDARD,
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":active": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":visited": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                    }),
                  },
                }}
              >
                {translation?.footer?.saveToPdfButton?.displayText}
              </Button>
            </Block>
          </Block>
        </Modal>

        <Modal
          size={
            !isServer() && window.innerWidth > 900 ? SIZEM.default : SIZEM.full
          }
          onClose={() => setIsQuickLookModalOpened(false)}
          isOpen={isQuickLookModalOpened}
          overrides={{
            Backdrop: {
              style: () => ({
                backgroundColor: "rgba(255,255,255,.7)",
              }),
            },
            Dialog: {
              style: () => ({
                backgroundColor: theme.colors.backgroundSecondary,
                minWidth: "55vw",
                margin: "0px",
                borderRadius: "5px",
                boxShadow:
                  "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  padding: "30px 50px 0px",
                }),
              },
            }}
          >
            <H3
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.primary,
                    margin: "0px 0px 10px",
                    fontSize: fontSizeMultiplier! * 16 + "px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }),
                },
              }}
            >
              Podgląd wersji
            </H3>
          </Block>

          <Block
            overrides={{
              Block: {
                style: () => ({
                  padding: "20px 50px 30px",
                }),
              },
            }}
          >
            <div id="articleContentQuickLookModal">
              <Block
                overrides={{
                  Block: {
                    style: () => ({
                      borderBottom: "1px solid " + theme.colors.backgroundInv,
                    }),
                  },
                }}
              >
                <H3
                  overrides={{
                    Block: {
                      style: ({ $theme }) => ({
                        color: $theme.colors.primary,
                        margin: "0px 0px 10px",
                        fontSize: fontSizeMultiplier! * 16 + "px",
                        lineHeight: "18px",
                        letterSpacing: "1px",
                        fontWeight: 500,
                      }),
                    },
                  }}
                >
                  {articleVersionQueryData?.contentData?.item?.title}
                </H3>
                <Label4
                  overrides={{
                    Block: {
                      style: () => ({
                        color: theme.colors.accent50,
                        margin: "0px 20px 20px 0",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "12px",
                        letterSpacing: "0.25px",
                        fontWeight: 400,
                        display: "inline-block",
                      }),
                    },
                  }}
                >
                  {articleVersionQueryData?.contentData?.item?.publishedDate}
                </Label4>
              </Block>
              <Block
                overrides={{
                  Block: {
                    style: () => ({
                      padding: "0px",
                    }),
                  },
                }}
              >
                <Paragraph4
                  as="div"
                  overrides={{
                    Block: {
                      style: () => ({
                        margin: "20px 0px 0px",
                        fontSize: fontSizeMultiplier! * 11 + "px",
                        lineHeight: "18px",
                        color: theme.colors.accent50,
                      }),
                    },
                  }}
                >
                  {articleVersionQueryData?.contentData?.item?.content &&
                    ReactHtmlParser(
                      articleVersionQueryData?.contentData?.item?.content
                    )}
                </Paragraph4>
              </Block>
            </div>
            <Block>
              {articleVersionQueryData?.contentData?.item?.attachments &&
                articleVersionQueryData?.contentData?.item?.attachments.length >
                  0 && (
                  <Block>
                    <Label3
                      overrides={{
                        Block: {
                          style: () => ({
                            marginTop: "30px",
                            color: theme.colors.accent50,
                            padding: "0px 0px 8px 0",
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "20px",
                            letterSpacing: "1px",
                            fontWeight: 500,
                            textTransform: "uppercase",
                            borderBottom:
                              "1px solid " + theme.colors.backgroundInv,
                          }),
                        },
                      }}
                    >
                      {translation?.listNames?.attachments}
                    </Label3>

                    {articleVersionQueryData?.contentData?.item?.attachments.map(
                      (attachment: any, index: any) => {
                        return (
                          <Block
                            key={index}
                            as={(props) => (
                              <a href={attachment.url} {...props}>
                                {props.children}
                              </a>
                            )}
                            overrides={{
                              Block: {
                                props: {
                                  title: attachment.title,
                                },
                                style: () => ({
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  textDecoration: "none",
                                  alignItems: "center",
                                  borderBottom:
                                    "1px solid " + theme.colors.backgroundInv,
                                  padding: "10px 0",
                                }),
                              },
                            }}
                          >
                            <img
                              src={
                                (isServer() ? "/" : "") +
                                require("../../assets/icons/attachment.svg")
                              }
                              alt="Link"
                              height="20px"
                              width="20px"
                            />
                            <LabelMedium
                              overrides={{
                                Block: {
                                  style: () => ({
                                    color: theme.colors.primary,
                                    margin: "0px 0px 0px 20px",
                                    textDecoration: "underline",
                                    fontSize: fontSizeMultiplier! * 11 + "px",
                                    letterSpacing: ".1px",
                                    minWidth: "100px",
                                    fontWeight: 400,
                                  }),
                                },
                              }}
                            >
                              {attachment.displayText}
                            </LabelMedium>
                            <LabelMedium
                              overrides={{
                                Block: {
                                  style: () => ({
                                    color: theme.colors.accent50,
                                    margin: "0px 0px 0px 60px",
                                    fontSize: fontSizeMultiplier! * 11 + "px",
                                    letterSpacing: ".1px",
                                    fontWeight: 400,
                                  }),
                                },
                              }}
                            >
                              {attachment.size}
                            </LabelMedium>
                          </Block>
                        );
                      }
                    )}
                  </Block>
                )}

              {articleVersionQueryData?.contentData?.item?.links &&
                articleVersionQueryData?.contentData?.item?.links.length >
                  0 && (
                  <Block>
                    <Label3
                      overrides={{
                        Block: {
                          style: () => ({
                            marginTop: "30px",
                            color: theme.colors.accent50,
                            padding: "0px 0px 8px 0",
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "20px",
                            letterSpacing: "1px",
                            fontWeight: 500,
                            textTransform: "uppercase",
                            borderBottom:
                              "1px solid " + theme.colors.backgroundInv,
                          }),
                        },
                      }}
                    >
                      {translation?.listNames?.links}
                    </Label3>

                    {articleVersionQueryData?.contentData?.item?.links.map(
                      (link: any, index: any) => {
                        return (
                          <Block
                            key={index}
                            as={(props) => (
                              <Link
                                to={`/${
                                  link.slug + (lang ? `?lang=${lang}` : "")
                                }`}
                                {...props}
                              >
                                {props.children}
                              </Link>
                            )}
                            overrides={{
                              Block: {
                                props: {
                                  title: link.title,
                                },
                                style: () => ({
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  textDecoration: "none",
                                  alignItems: "center",
                                  borderBottom:
                                    "1px solid " + theme.colors.backgroundInv,
                                  padding: "10px 0",
                                }),
                              },
                            }}
                          >
                            <img
                              src={
                                (isServer() ? "/" : "") +
                                require("../../assets/icons/link.svg")
                              }
                              alt="Link"
                              height="20px"
                              width="20px"
                            />
                            <LabelMedium
                              overrides={{
                                Block: {
                                  style: () => ({
                                    color: theme.colors.primary,
                                    margin: "0px 0px 0px 20px",
                                    textDecoration: "underline",
                                    fontSize: fontSizeMultiplier! * 11 + "px",
                                    letterSpacing: ".1px",
                                    minWidth: "100px",
                                    fontWeight: 400,
                                  }),
                                },
                              }}
                            >
                              {link.displayText}
                            </LabelMedium>
                          </Block>
                        );
                      }
                    )}
                  </Block>
                )}
            </Block>
          </Block>
          <Block
            justifyContent={[
              "flex-start",
              "flex-start",
              "flex-start",
              "space-between",
            ]}
            overrides={{
              Block: {
                style: () => ({
                  display: "flex",
                  flexDirection: "row",
                  padding: "0px 50px 0px",
                  flexWrap: "wrap",
                  backgroundColor: theme.colors.backgroundOverlayDark,
                }),
              },
            }}
          >
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }),
                },
              }}
            >
              <Button
                onClick={
                  isServer()
                    ? () => {
                        failWindowHandler(
                          setActionType,
                          setIsFailureModalOpen,
                          20
                        );
                      }
                    : () => handlePrintArticleContentQuickLookModal()
                }
                kind={KIND.tertiary}
                size={SIZE.compact}
                startEnhancer={() => (
                  <img
                    height="16px"
                    alt={translation?.footer?.printButton?.displayText}
                    src={
                      (isServer() ? "/" : "") +
                      require("../../assets/icons/print.svg")
                    }
                  />
                )}
                overrides={{
                  BaseButton: {
                    props: {
                      title: translation?.footer?.printButton?.title,
                    },
                    style: () => ({
                      color: theme.colors.primary,
                      margin: "0px 35px 0px 0px",
                      padding: "15px 0px",
                      textTransform: "uppercase",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: 400,
                      lineHeight: "11px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color:
                          theme.name !== "standard"
                            ? theme.colors.primary
                            : COLORS.ARTICLE_STANDARD,
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":active": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":visited": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                    }),
                  },
                }}
              >
                {translation?.footer?.printButton?.displayText}
              </Button>
              <Button
                onClick={() => startDownloadPDF(setIsModalClicked, saveToPDF)}
                kind={KIND.tertiary}
                size={SIZE.compact}
                startEnhancer={() => (
                  <img
                    height="16px"
                    alt={translation?.footer?.saveToPdfButton?.displayText}
                    src={
                      (isServer() ? "/" : "") +
                      require(`../../assets/icons/pdf-file-${theme.name}.svg`)
                    }
                  />
                )}
                overrides={{
                  BaseButton: {
                    props: {
                      title: translation?.footer?.saveToPdfButton?.title,
                    },
                    style: () => ({
                      color: theme.colors.primary,
                      margin: "0px 35px 0px 0px",
                      padding: "15px 0px",
                      textTransform: "uppercase",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: 400,
                      lineHeight: "11px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color:
                          theme.name !== "standard"
                            ? theme.colors.primary
                            : COLORS.ARTICLE_STANDARD,
                      },
                      ":focus": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":active": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                      ":visited": {
                        backgroundColor: "transparent",
                        color: theme.colors.primary,
                      },
                    }),
                  },
                }}
              >
                {translation?.footer?.saveToPdfButton?.displayText}
              </Button>
            </Block>
            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }),
                },
              }}
            >
              {articleVersionQueryData?.contentData?.item?.metrics && (
                <StatefulPopover
                  content={() => (
                    <Block
                      minWidth={["0px", "0px", "350px", "350px"]}
                      overrides={{
                        Block: {
                          style: () => ({
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: theme.colors.backgroundSecondary,
                            padding: "10px 30px",
                            boxShadow:
                              "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                            color: theme.colors.accent50,
                          }),
                        },
                      }}
                    >
                      {articleVersionQueryData?.contentData?.item?.metrics.map(
                        (metric: any, index: any) => {
                          return (
                            <Block
                              key={index}
                              overrides={{
                                Block: {
                                  style: () => ({
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "nowrap",
                                    borderBottom:
                                      index !==
                                      articleVersionQueryData?.contentData?.item
                                        ?.metrics.length -
                                        1
                                        ? "1px solid " +
                                          theme.colors.backgroundInv
                                        : "none",
                                  }),
                                },
                              }}
                            >
                              <Block
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      padding: "10px 0px",
                                      width: "33%",
                                      boxSizing: "border-box",
                                    }),
                                  },
                                }}
                              >
                                <Label4
                                  overrides={{
                                    Block: {
                                      style: () => ({
                                        fontSize:
                                          fontSizeMultiplier! * 10 + "px",
                                        color: theme.colors.contentTertiary,
                                        lineHeight: "12px",
                                        fontWeight: 500,
                                        letterSpacing: ".25px",
                                      }),
                                    },
                                  }}
                                >
                                  {metric.key}
                                </Label4>
                              </Block>
                              <Block
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      padding: "10px 0px",
                                      width: "33%",
                                      boxSizing: "border-box",
                                    }),
                                  },
                                }}
                              >
                                <Label4
                                  overrides={{
                                    Block: {
                                      style: () => ({
                                        fontSize:
                                          fontSizeMultiplier! * 10 + "px",
                                        color: theme.colors.contentTertiary,
                                        lineHeight: "12px",
                                        fontWeight: 400,
                                        letterSpacing: ".25px",
                                      }),
                                    },
                                  }}
                                >
                                  {metric.value}
                                </Label4>
                              </Block>
                              <Block
                                overrides={{
                                  Block: {
                                    style: () => ({
                                      padding: "10px 0px",
                                      width: "33%",
                                      boxSizing: "border-box",
                                    }),
                                  },
                                }}
                              >
                                <Label4
                                  overrides={{
                                    Block: {
                                      style: () => ({
                                        fontSize:
                                          fontSizeMultiplier! * 10 + "px",
                                        color: theme.colors.contentTertiary,
                                        lineHeight: "12px",
                                        fontWeight: 400,
                                        letterSpacing: ".25px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }),
                                    },
                                  }}
                                >
                                  {metric.date}
                                </Label4>
                              </Block>
                            </Block>
                          );
                        }
                      )}
                    </Block>
                  )}
                  placement={PLACEMENT.bottomRight}
                  focusLock
                  returnFocus
                  autoFocus
                  overrides={{
                    Body: {
                      style: () => ({
                        left:
                          !isServer() && window.innerWidth > 900
                            ? "50px"
                            : "1%",
                        right:
                          !isServer() && window.innerWidth > 900
                            ? "unset"
                            : "5%",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }),
                    },
                  }}
                >
                  <Button
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                    startEnhancer={() => (
                      <img
                        height="16px"
                        alt={translation?.footer?.metricButton?.displayText}
                        src={
                          (isServer() ? "/" : "") +
                          require("../../assets/icons/info.svg")
                        }
                      />
                    )}
                    overrides={{
                      BaseButton: {
                        props: {
                          title: translation?.footer?.metricButton?.title,
                        },
                        style: () => ({
                          color: theme.colors.primary,
                          padding: "15px 0px",
                          margin: "0px 0px 0px 0px",
                          textTransform: "uppercase",
                          fontSize: fontSizeMultiplier! * 11 + "px",
                          fontFamily: "Rubik, sans-serif",
                          fontWeight: 400,
                          lineHeight: "11px",
                          ":hover": {
                            backgroundColor: "transparent",
                            color:
                              theme.name !== "standard"
                                ? theme.colors.primary
                                : COLORS.ARTICLE_STANDARD,
                          },
                          ":focus": {
                            backgroundColor: "transparent",
                            color: theme.colors.primary,
                          },
                          ":active": {
                            backgroundColor: "transparent",
                            color: theme.colors.primary,
                          },
                          ":visited": {
                            backgroundColor: "transparent",
                            color: theme.colors.primary,
                          },
                        }),
                      },
                    }}
                  >
                    {translation?.footer?.metricButton?.displayText}
                  </Button>
                </StatefulPopover>
              )}
            </Block>
          </Block>
        </Modal>
      </Block>
    </Fragment>
  );
};

export default OfficeData;
