import { CustomLink } from "shared/CustomLink";

import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const Label = styled.label<{
  color: string;
  fontSize: number;
  borderColor: string;
}>`
  display: block;
  padding: 0 0 8px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li<{ borderColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  position: relative;

  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
`;

export const Link = styled(CustomLink)<{ borderColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  align-items: center;
  padding: 10px 0;
  border-bottom: ${({ borderColor }) => `1px solid ${borderColor}`};
  width: 100%;
`;

export const TextWrapper = styled.span<{ color: string; fontSize: number }>`
  margin: 0 0 0 20px;
  text-decoration: underline;
  letter-spacing: 0.1px;
  min-width: 100px;
  font-weight: 400;
  display: block;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
`;
