export const getPagesParams = (
  pages: number[],
  page: string | number | (string | number)[] | null
) => {
  const firstIndex = 0;
  const secondIndex = 1;
  const thirdIndex = 2;
  const lastPageIndex = pages.length - 1;
  const penultimateIndex = pages.length - 2;
  const thirdFromLastIndex = pages.length - 3;

  const edgeParams = [
    {
      index: 0,
      isInput: false,
    },
    {
      index: 1,
      isInput: false,
    },
    {
      index: 2,
      isInput: false,
    },
    {
      index: 3,
      isInput: true,
    },
    {
      index: penultimateIndex,
      isInput: false,
    },
    {
      index: lastPageIndex,
      isInput: false,
    },
  ];

  const thirdParams = [
    {
      index: 0,
      isInput: false,
    },
    {
      index: 1,
      isInput: false,
    },
    {
      index: 2,
      isInput: false,
    },
    {
      index: 3,
      isInput: true,
    },
    {
      index: penultimateIndex,
      isInput: false,
    },
    {
      index: lastPageIndex,
      isInput: false,
    },
  ];

  const thirdFromLast = [
    {
      index: 0,
      isInput: false,
    },
    {
      index: 1,
      isInput: false,
    },
    {
      index: 2,
      isInput: true,
    },
    {
      index: pages.length - 3,
      isInput: false,
    },
    {
      index: penultimateIndex,
      isInput: false,
    },
    {
      index: lastPageIndex,
      isInput: false,
    },
  ];

  const middlePage = [
    {
      index: 0,
      isInput: false,
    },
    {
      index: 1,
      isInput: false,
    },
    {
      index: 2,
      isInput: true,
    },

    {
      index: page,
      isInput: false,
    },

    {
      index: thirdFromLastIndex,
      isInput: true,
    },
    {
      index: penultimateIndex,
      isInput: false,
    },
    {
      index: lastPageIndex,
      isInput: false,
    },
  ];

  const pageParams = {
    [firstIndex]: edgeParams,
    [secondIndex]: edgeParams,
    [thirdIndex]: thirdParams,
    [thirdFromLastIndex]: thirdFromLast,
    [penultimateIndex]: edgeParams,
    [lastPageIndex]: edgeParams,
  };

  const pageObj = pageParams[page as number] ?? middlePage;

  return pageObj;
};
