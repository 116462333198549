import React from 'react'

import { LabelXSmall } from 'baseui/typography'

import { NSearch } from '@namespace/Search'
 
export const ValidationError: React.SFC<NSearch.IPropsValidationError> = ({ validationError, borderError, fontSizeMultiplier }) => (
    <>
        {validationError &&
            <LabelXSmall
                overrides={{
                    Block: {
                    style: () => ({
                        margin: '0px 10px 0px 0px',
                        color: borderError,
                        fontWeight: 400,
                        fontSize: fontSizeMultiplier! * 10 + 'px',
                        letterSpacing: '.25px',
                        textDecoration: 'none',
                        width: '200px'
                    })
                    }
                }}
            >
                { validationError }
            </LabelXSmall>
        }
    </>
);
