import React, { useState, useContext, useEffect } from "react";

import { useLocation } from "react-router";

import { Category } from "../Category";

import { ScrollPositionContext } from "@contexts/ScrollProvider";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NMenu } from "@namespace/Menu";

import { processSlug } from "@utils/process-slug";

import {
  Categories,
  DisplayText,
  IconWrapper,
  MenuItem,
  MenuLinkItem,
} from "./styles";

interface THorizontalMenuItemProps {
  item: NMenu.IHorizontalMenuItem;
  primary: string;
  backgroundLightAccent: string;
  buttonTertiaryHover: string;
  contentInversePrimary: string;
  fontSizeMultiplier?: number | null;
  lang?: string | string[] | null;
  translation: NMenu.ITranslation;
  isMobile: boolean;
  count: number;
  index: number;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useOutsideClick = (
  callback: () => void
): React.MutableRefObject<HTMLElement | null> => {
  const ref = React.useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref]);

  return ref;
};

export const HorizontalMenuItem = ({
  item,
  primary,
  backgroundLightAccent,
  buttonTertiaryHover,
  contentInversePrimary,
  fontSizeMultiplier,
  lang,
  translation,
  isMobile,
  count,
  setIsMenuOpen,
}: THorizontalMenuItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();

  const { changeScrollPosition, changeArticleId } = useContext(
    ScrollPositionContext
  );

  const {
    displayText,
    slug,
    url,
    categories,
    target,
    title,
    hasChildren,
    linkType,
  } = item;

  const handleClickOutside = (): void => {
    setIsOpen(false);
  };

  const navigate = (): void => {
    changeScrollPosition(0, "articleLink");
    changeArticleId("", "articleLink");

    setIsMenuOpen && setIsMenuOpen(false);
    setIsOpen(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleKey = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const ref = useOutsideClick(handleClickOutside);

  const isActiveMenuItem =
    pathname === `/${slug}` ||
    item.categories.some(({ slug }) => pathname === `/${slug}`);

  return (
    <MenuItem
      {...{
        ref,
        active: isActiveMenuItem,
        primary,
        backgroundLightAccent,
        buttonTertiaryHover,
        contentInversePrimary,
        isMobile,
        linkType,
        count,
        tabIndex: 0,
        role: "tab",
        onClick: () => navigate(),
        onKeyDown: (e) => handleKey(e),
      }}
    >
      <MenuLinkItem
        {...{
          slug: processSlug(slug, lang),
          item,
          target: target === 20 ? "_blank" : "_self",
          url,
          active: isActiveMenuItem,
          isMobile,
          primary,
          fontSizeMultiplier,
          backgroundLightAccent,
          buttonTertiaryHover,
          contentInversePrimary,
          title,
        }}
      >
        <DisplayText>{displayText}</DisplayText>
      </MenuLinkItem>
      {hasChildren ? (
        <IconWrapper
          {...{
            contentInversePrimary,
            title: translation.expand,
            onClick: handleClick,
          }}
        >
          <FontAwesomeIcon
            {...{
              icon: faChevronDown,
              color: primary,
            }}
          />
        </IconWrapper>
      ) : null}
      {hasChildren && isOpen ? (
        <Categories
          {...{
            primary,
            backgroundLightAccent,
            buttonTertiaryHover,
            isOpen,
            isMobile,
          }}
        >
          {categories.map((category) => (
            <Category
              key={`${category.title}-${category.parentElementId}`}
              {...{
                category,
                fontSizeMultiplier,
                primary,
                backgroundLightAccent,
                contentInversePrimary,
                isMobile,
                navigate,
              }}
            />
          ))}
        </Categories>
      ) : null}
    </MenuItem>
  );
};
