import * as React from "react";
import { SIZE, ROLE } from "baseui/modal";
import { useStyletron } from "baseui";

import { useAccessibility } from "@contexts/AccessibilityContext";

import * as S from "./styles";

const InfoModal = ({
  isOpen,
  setIsOpen,
  text,
  title,
  closeButtonText,
  closeButtonTitle,
}) => {
  const [, theme] = useStyletron();

  const { fontSizeMultiplier } = useAccessibility();

  const {
    colors: { primary, backgroundSecondary },
  } = theme;

  return (
    <S.MyModal
      onClose={() => {
        setIsOpen(false);
      }}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.auto}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: () => ({
            maxWidth: "60vw",
            margin: "0 auto",
          }),
        },
        Close: {
          style: () => ({
            color: backgroundSecondary,
            fontSize: "14px",
          }),
        },
      }}
    >
      <S.Header
        {...{
          bgColor: primary,
          color: backgroundSecondary,
          fontSize: fontSizeMultiplier! * 16,
        }}
      >
        {title}
      </S.Header>
      <S.Body
        {...{
          bgColor: backgroundSecondary,
          color: primary,
          fontSize: fontSizeMultiplier! * 16,
        }}
      >
        {text}
      </S.Body>
      <S.Footer
        {...{
          bgColor: backgroundSecondary,
          color: primary,
        }}
      >
        <S.Button
          {...{
            bgColor: primary,
            color: backgroundSecondary,
            size: "compact",
            title: closeButtonTitle ?? "Zamknij",
            fontSize: fontSizeMultiplier! * 16,
          }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {closeButtonText ?? "Zamknij"}
        </S.Button>
      </S.Footer>
    </S.MyModal>
  );
};

export default InfoModal;
