import React, { createContext, useContext, useState, useEffect } from "react";
import { Theme } from "baseui/theme";
import { THEME_SET } from "../themes";
import { useCookies } from "react-cookie";
import { isServer } from "../helpers/ssr";

type ThemeContextProps = {
  theme: Theme;
  themeKey: string;
  setThemeKey(value: string): void;
};

const ThemeContext = createContext<Partial<ThemeContextProps>>({});

export function ThemeProvider(props: any) {
  const [cookies, setCookie] = useCookies(["themeKey"]);
  const [themeKey, setThemeKey] = useState(cookies.themeKey || "standard");
  const [theme, setTheme] = useState(THEME_SET[themeKey]);

  useEffect(() => {
    if (!isServer()) {
      setCookie("themeKey", themeKey, { path: "/" });
    }

    setTheme(THEME_SET[themeKey]);
  }, [themeKey]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeKey,
        setThemeKey,
      }}
      {...props}
    />
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}
